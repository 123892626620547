@use "sass:math"
@import src/styles/helpers
@import src/styles

.checkbox
    display: block
    position: relative
    user-select: none
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    &.reverse
        .inner
            flex-direction: row-reverse
        .text
            padding: 0 12px 0 0

    &.disabled
        opacity: 0.5
        filter: grayscale(1)
        cursor: not-allowed

    &.isLoading
        opacity: 0.7
        cursor: not-allowed

.input
    position: absolute
    top: 0
    left: 0
    opacity: 0

    &:checked + .inner
        .tick
            background: $blue-color
            border-color: $blue-color
            &:before
                opacity: 1

    &:focus+ .inner
        .tick
            border: 1px solid #4849FF
            box-shadow: 0 0 0 1px #4849FF

.inner
    display: flex
    &:hover
        .tick
            border-color: $input-hover-color

    &:focus-within
        .tick
            border: 1px solid #4849FF
            box-shadow: 0 0 0 1px #4849FF

.tick
    position: relative
    flex-shrink: 0
    width: 24px
    height: 24px
    border-radius: $radius-s
    border: 1px solid rgba(0, 0, 0, 0.23)
    transition: all .2s

    &:focus-within
        border: 1px solid #4849FF
        box-shadow: 0 0 0 1px #4849FF

    &.error
        border: 1px solid $danger-color !important

    &:focus.error
        border: 1px solid $danger-color !important
        box-shadow: 0 0 0 1px $danger-color !important

    &:before
        content: ""
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        width: 17px
        height: 12px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12'%3E%3Cpath d='M16.707.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0L.293 6.707A1 1 0 0 1 .735 5.02a1 1 0 0 1 .973.273l3.586 3.586a1 1 0 0 0 1.414 0L15.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfc' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        opacity: 0
        transition: opacity .2s

.text
    flex-grow: 1
    padding-left: 12px
    font-size: $font-size-s
    line-height: math.div(24, 14)
    font-weight: 600
    color: $n7
    word-break: break-word

    &.reverse
        text-align: right

    +dark
        color: $n1
