@import 'src/styles';

.tableCategory {
  margin: 0;
}

.tableHeader {
  grid-template-columns: 150px 2fr 100px 70px 70px 70px;
  gap: 18px;
  background: #fff;
  border-radius: $radius-s $radius-s 0 0;
  position: sticky;
  top: 0;
  padding: 12px 6px;
  margin-bottom: 12px;
  z-index: 2;

  @include smDesktopWidth {
    display: none;
  }
}

.table {
  max-height: 350px;
  overflow: auto;
  padding-top: 0;

  @include lgDesktopWidth {
    max-height: 350px;
  }

  @include smDesktopWidth {
    max-height: unset;
  }
}
