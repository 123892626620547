@import '../../../styles';

.container {
  @include element-flex(row, flex-start, center);
  gap: 6px;

  font-size: $font-size-m;
  color: $font-color-light;
}

.dot {
  width: 10px;
  height: 10px;
  min-height: 10px;
  min-width: 10px;
  border-radius: 50%;
  box-shadow: $shadow-main;
  background-color: $font-color;

  &.yellow {
    background-color: $yellow-color;
  }

  &.red {
    background-color: $danger-color;
  }

  &.green {
    background-color: $green-color;
  }
}
