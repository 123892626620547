@import '../../../styles';

.container {
  width: 700px;
  height: 600px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background: #ffffff;
  border-radius: $radius-s;
  overflow: hidden;
}

.wrapper {
  width: 300px;
  height: 300px;
}

.control {
  width: 100%;
  @include element-flex(row, center, center);
  gap: 24px;
  position: fixed;
  top: calc(50% + 265px);
  left: 50%;
  transform: translate(-50%, -50%);
}
