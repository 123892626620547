@import '../../../styles';

.page {
  padding: 0 0 0 260px;
  background: #f2f5f9;

  @include printView {
    padding: 0;
  }

  &.closedSidebar {
    padding: 0 0 0 100px;
  }

  &.isMobile {
    padding: 0 0 72px 0;
    box-shadow: 0 0 20px rgba(33, 49, 73, 0.23);
    transition: transform 0.2s;
  }

  &.isMobileOpen {
    transform: translateX(260px);
  }

  &.isMobileClose {
    transform: none;
  }

  &.isAdminView {
    padding-top: 40px;
  }
}

.sidebar {
  &.visible {
    transform: translateX(0);
  }
}

.inner {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 96px 40px 20px 40px;

  @include desktopWidth {
  }

  @include smDesktopWidth {
    padding: 68px 0 0;
  }

  @include printView {
    padding: 0;
    margin: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 68px;

  &.wide {
    max-width: 100%;
  }
}

.title {
  margin-bottom: 24px;
}
