@import 'src/styles';


.switch {
  margin-top: 6px;
}

.box{
  @include element-flex(column);
  gap: 12px;
  width: 100%;
}
