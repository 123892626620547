@import 'src/styles';

.wrapper{
  @include element-flex(row, stretch, flex-start);
  width: 100%;
  gap: 24px;

  @include desktopWidth {
    @include element-flex(column, flex-start, stretch);
  }
}

.box{
  @include element-flex(column, center);
  padding: 0 24px;
  min-height: 97px;
  background-color: $gray-lighter-color;
  border-radius: $radius-m;
  box-sizing: border-box;
}