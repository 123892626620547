@import 'src/styles';

.container {
  @include element-flex(column);
  width: 100%;
  gap: 12px;
}

.header {
  @include element-flex(row, space-between, center);
  width: 100%;
}

.title {
  font-size: $font-size-s;
  font-weight: $font-bold;
  line-height: 24px;

  @include smDesktopWidth {
    font-size: $font-size-m;
  }
}

.link {
  font-size: $font-size-s;
  font-weight: $font-semiBold;
  color: $blue-color;
  text-decoration: none;

  @include smDesktopWidth {
    font-size: $font-size-m;
  }

  &:hover {
    text-decoration: underline;
    color: lighten($blue-color, 10%);
  }
}

.menu{
  position: static;
}