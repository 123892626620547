@import 'src/styles';

$grid-template-columns: 40px 1fr 250px 150px;

.container{
  width: 100%;
  display: grid;
  grid-template-columns: $grid-template-columns;
  gap: 12px;
  padding: 6px;
  border-radius: $radius-s;
  background: #fff;

  &:nth-of-type(even) {
    background: $odd-row-background;
  }
}

.wrapper{
  @include element-flex(row, flex-start, center);
  gap: 1ch;
  position: relative;
}