@import 'src/styles';

.container{
  @include element-flex(column);
  gap: 12px;
  width: 900px;

  @include smDesktopWidth{
    width: 100%;
  }
}

.header{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  gap: 12px;

  @include tabletWidth{
    grid-template-columns: 1fr;
  }
}

.box{
  @include element-flex(column, flex-start, center);
  gap: 12px;
  background: $blue-lightest-color;
  padding: 12px;
  border-radius: $radius-m;
  width: 100%;
}

.product{
  width: 100%;
  padding: 12px 6px;
  background: $gray-lightest-color;
  border-radius: $radius-m;

  &.tiny{
    padding: 6px;
  }
}

.suppliersWrapper{
  @include element-flex(column, flex-start, stretch);
  width: 100%;
  gap: 12px;
  max-height: 400px;
  overflow: auto;
}

.tableTitle{
  @include element-flex(row, space-between, center);
  height: auto;
  padding: 0 12px;
}

.scrollWrapper{
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(250px, 1fr));
  background: $gray-lightest-color;
  padding: 12px;
  border-radius: $radius-m;
  gap: 12px;
}

.description{
  padding: 0 12px;
}

.buttonWrapper{
  @include element-flex(row, center, center);
  margin-top: 12px;
  gap: 12px;
  width: 100%;
}