@import '../../styles';

.container {
  @include element-flex(column);
  width: 100%;
  max-width: 1500px;
  flex-wrap: nowrap;
  margin: 0 auto;

  @include smDesktopWidth {
    padding: 0 10px;
  }
}

.header {
  max-width: 1500px;
  margin: 0 auto 24px;
}
