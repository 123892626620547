@use './components/SingleValuation/SingleValuation.module' as item;
@import 'src/styles';

.box {
  width: 100%;
  @include element-flex(column);
}

.header {
  @include element-flex(column);
  gap: 6px;
  padding: 6px;
  position: relative;
  width: 100%;
}

.arrow {
  position: absolute;
  right: 0;
  top: 50%;
  width: 48px;
  height: 60px;
  padding: 12px;
  transform: translateY(-50%);

  &.down {
    transform: translateY(-50%) rotateX(180deg);
    fill: $blue-color;
  }
}

.valuationsHeader {
  display: grid;
  grid-template-columns: item.$grid-template-columns;
  width: 100%;
  padding: 6px;
  background: #fff;
  gap: 12px;
  border-radius: $radius-s;
  position: sticky;
  top: 0;

  & > p {
    font-weight: 700;
  }
}

.elementsWrapper {
  @include element-flex(column);
  background: #fff;
  padding: 0 6px;
  width: 100%;
  border-radius: $radius-s;
  max-height: 500px;
  overflow-y: auto;
}

.description {
  padding-bottom: 6px;
}

.title {
  padding-top: 6px;
}
