@import 'src/styles';

.section {
  @include element-flex(column, flex-start, stretch);
  width: 100%;
  padding: 0 0 24px 0;
  gap: 12px;
}

.buttons{
  @include element-flex(row, flex-end, center);
  gap: 24px;
}

.sectionHeader {
  @include element-flex(row, space-between, center);
  gap: 12px;
  border-bottom: 1px solid $gray-light-color;
  padding-bottom: 6px;
  height: 51px;
  width: 100%;
  margin-bottom: 12px;
}

.sectionName {
  @include element-flex(row, flex-start, center);
  gap: 24px;
  font-size: $font-size-l;
  color: $font-color-light;
}
