@import 'src/styles';

.container{
  width: 100%;
  @include element-flex(column, flex-start, stretch);
  gap: 12px
}

.chartsWrapper{
  @extend .container;
  gap: 48px;
}

.header{
  width: 100%;
  @include element-flex(row, space-between, flex-start);
  gap: 12px;
}

.options{
  @include element-flex(row, flex-end, center);
  gap: 12px;
}

.select{
  min-width: 200px;
  max-width: 300px;
}