@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.container {
  background-color: $background-gray;
  border-radius: $radius-s;
  padding: 47px 45px 47px 60px;

  @include element-flex(row, space-between, center);

  @include smDesktopWidth {
    flex-direction: column;
    gap: 20px;
  }

  @include phoneWidth {
    padding: 27px 25px 27px 40px;
  }
}

.left {
  max-width: 49%;
  margin-right: 60px;

  @include smDesktopWidth {
    max-width: 65%;
    margin-right: unset;
  }

  @include phoneWidth {
    max-width: 80%;
  }

  & h2 {
    margin-bottom: 32px;

    @include customBreakpoint(600px) {
      font-size: 20px;
      line-height: 26px;
    }

    @include phoneWidth {
      margin-bottom: 14px;
    }
  }
}

.right {
  @include element-flex(row, center, center);
  position: relative;
  width: 100%;
  max-width: 503px;

  & img {
    border-radius: 8px;
    box-shadow: $shadow-img;
  }
}

.img {
  &-main {
    max-width: 253px;
  }

  &-left {
    position: absolute;
    left: 0;
    bottom: 39px;

    @include customBreakpoint(600px) {
      display: none;
    }
  }

  &-right {
    position: absolute;
    right: 0;
    top: 70px;

    @include customBreakpoint(600px) {
      display: none;
    }
  }
}

.button {
  width: max-content;
}
