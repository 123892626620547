@import '../../../../styles';

.container {
  @include element-flex(column);
  width: 100%;
  position: relative;
}

.amount {
  font-size: $font-size-xxl;
  font-weight: $font-bold;
  line-height: 36px !important;
  transition: opacity 0.5s;

  &.loading {
    opacity: 0.5;
  }
}

.shippingPrice {
  font-size: $font-size-s;
  color: $font-color-light;
}

.header {
  grid-template-columns: 100%;
  height: 60px;
}

.progressLine {
  @include element-flex(column);
  margin: 0 6px !important;
  width: calc(100% - 12px) !important;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.optionHeader {
  font-weight: 700;
  font-size: $font-size-m;
  line-height: 24px;
  @include trimText;
  white-space: nowrap;

  &.smaller {
    font-size: $font-size-s;
  }
}

.tableTitle {
  min-height: 60px;
}

.priceWrapper {
  @include element-flex(row);
  gap: 1ch;
  height: 100%;
}

.price {
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: 700;
  position: relative;
  height: 24px;

  &.empty::before {
    content: '';
    width: 32px;
    height: 2px;
    background: $font-color;
    border-radius: 99px;
    position: absolute;
    left: 0;
    top: 11px;
  }

  &.empty::after {
    content: 'zł';
    width: fit-content;
    height: fit-content;
    color: $font-color;
    position: absolute;
    left: 35px;
    top: 0;
  }
}

.prevPrice {
  text-decoration: line-through;
  font-size: $font-size-s;
  line-height: 24px;
  font-weight: 600;
  color: #6a798b;
}

.available {
  font-size: $font-size-s;
  font-weight: $font-regular;
  color: #6a798b;
}

.offerBox {
  @include element-flex(column);
  gap: 16px;
  padding: 12px 0;
  width: 90%;
  margin-left: 5%;

  &.horizontal{
    margin: 0;
    width: 100%;
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;
  height: 200px;

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }
}

.tableWrapper {
  position: relative;
  border-radius: 0;
  padding: 12px 0;
}

.supplierWrapper {
  width: 100%;
  height: 250px;
  padding: 18px 12px;
  border-radius: $radius-s;
  border: 1px solid $border-color-lighter;
  display: grid;
  gap: 12px;
  grid-template-columns: 100%;
  grid-template-rows: 24px 1fr;
  position: relative;

  &.emptyWrapper {
    height: 175px;
  }
}

.unavailableProductStatusWrapper {
  width: 100%;
  @include element-flex(row, flex-start, center);
}

.emptyWrapperTag {
  width: fit-content;
  max-width: 150px;
}

.lastTableWrapper {
  border-radius: 0 $radius-s $radius-s 0;
  padding: 12px 12px 12px 0;
}

.lastWrapper {
  border-radius: 0 $radius-s $radius-s 0;
}

.lastColumn {
  width: 100%;

  &.mainContainer {
    @include tabletWidth {
      margin-right: 10px;
      width: calc(100% - 10px);
    }
  }
}

.radio {
  width: 180px;
}

.contentWrapper {
  @include element-flex(column);
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

.content {
  width: 100%;
  @include element-flex(column);
  margin-top: auto;
  gap: 6px;
}

.empty {
  @include element-flex(column, center, center);
  width: 100%;
  height: 100%;
  grid-row: 1 / 3;
}

.columnSum {
  position: absolute;
  left: 12px;
  top: 0;
  font-weight: 400;
}

.bottomSum {
  padding-left: 20px;
}

.settings {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-self: flex-end;
  position: relative;
}

.headCheckbox {
  margin: 0 6px !important;
  width: calc(100% - 12px) !important;
  cursor: pointer;
}

.amountWrapper {
  @include element-flex(column);
  gap: 12px;
}

.columnHeader {
  @include element-flex(row, space-between, center);
}

.savings {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 5px;
  background-color: $green-color;
  border-radius: 5px;
}

.replacement {
  background: rgba(0, 1, 219, 0.07);

  &:hover {
    background: rgba(0, 1, 219, 0.1);
  }
}

.offer {
  background: rgba(249, 211, 112, 0.2);

  &:hover {
    background: rgba(249, 211, 112, 0.35);
  }
}

.info {
  position: absolute;
  right: 16px;
  top: 52px;
}

.withIcon {
  @include element-flex(row, flex-start, center);
  gap: 4px;
  font-size: $font-size-s;
  font-weight: normal;
  margin: 4px 0 0 -2px;
}

.link {
  cursor: pointer;
}

.productInfo {
  margin-top: 4px;
}

.tag {
  max-width: 150px !important;
  margin: 0 auto;
}

.background {
  width: calc(100% - 12px);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: $radius-s;
  background: $gray-lightest-color;
  cursor: not-allowed;
  z-index: 1;
}


.commentsAndOffers {
  width: 100%;
  height: 124px;
  @include element-flex(column, center);
  gap: 6px;
  padding: 6px 12px;
  background-color: $odd-row-background;
  margin-top: 24px;

  &.lastElement {
    border-radius: 0 $radius-s $radius-s 0;
  }
}

.giftsAndCommentWrapper {
  width: 100%;
  height: 100%;
  border: 1px solid $border-color-lighter;
  border-radius: $radius-s;
  padding: 0 12px;
  @include element-flex(column, center, center);
  gap: 3px;
}

.giftWrapper {
  @include element-flex(row, flex-start, baseline);
  gap: 6px;
  width: 100%;
  font-size: $font-size-s;
}

.giftValue {
  font-size: $font-size-m;
  font-weight: 700;
}


.comment {
  @include limit-lines(3);
  font-size: $font-size-s;
  width: 100%;
  line-height: 20px !important;
  text-align: left;
  font-weight: normal;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}