@import '../../../styles';

.container {
  width: 100%;
  @include element-flex(column);
  gap: 6px;
}

.name {
  padding-bottom: 6px;
}

.data {
  font-size: $font-size-m;
}
