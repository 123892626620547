@import 'src/styles';

.header {
  grid-template-columns: 1fr 70px 150px 150px 50px;
  gap: 24px;

  @include desktopWidth {
    grid-template-columns: 1fr 60px 120px 120px 50px;
  }

  &.supplierHeader{
    padding: 18px 6px;
    grid-template-columns: 1fr 150px 150px;

    @include customBreakpoint(1400px) {
      grid-template-columns: 1fr 120px 150px;
      align-items: center;
    }

    @include desktopWidth {
      display: none;
    }
  }

  @include smDesktopWidth {
    display: none;
  }
}
