@import 'src/styles';

.container{
  width: 100%;
  @include element-flex(column);
  box-shadow: $shadow-main;
  gap: 6px;
}

.wrapper{
  @include element-flex(column);
  gap: 3px;
  margin: 3px 0;
}