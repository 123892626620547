@import 'src/styles';

.container{
  @include element-flex(column, center, center);
  width: 100%;
  gap: 3px;
}

.wrapper{
  @include element-flex(column, center, center);
  width: 100%;
  position: relative;
  min-height: 90px;

  &.smaller{
    min-height: 50px;
  }

  &:before{
    content: '';
    transition: border-color .3s;
    border: 2px dashed transparent;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    border-radius: $radius-m;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.withBorder:before{
    border-color: $border-color;
  }

  &.withBlueBorder:before{
    border-color: $blue-color;
  }

  &.empty:after{
    content: 'Brak';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    @include element-flex(column, center, center);
    color: $font-color-light;
    font-size: $font-size-xxl;
    opacity: 0.5;
    border: 1px solid $border-color-lighter;
    border-radius: $radius-s;
  }

  &.emptyWithOptions:after{
    content: 'Przypisz';
    border: 2px dashed $border-color-light;
    transition: background 0.2s;
  }

  &.emptyWithOptions:hover:after{
    background: $border-color-lighter;
    cursor: pointer;
  }

  &.disabled:before{
    border: none;
  }

  &.disabled{
    opacity: 0.7;
  }
}

.label{
  width: 100%;
  text-align: center;
  font-size: $font-size-xs;
  color: $font-color-light;
  line-height: 14px;
  margin-bottom: 3px;
}