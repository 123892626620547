@import '../../styles';

.container {
  width: 100%;
  display: grid;
  align-items: center;
  max-width: 1500px;
  margin: 0 auto;

  @include smDesktopWidth {
    padding: 0 10px 20px 10px;
  }
}

.header {
  max-width: 1500px;
  margin: 0 auto 24px;
}

.mobileSearch {
  width: 100%;
  padding-bottom: 20px;

  @include smDesktopWidth {
    padding-bottom: 40px;
  }
}
