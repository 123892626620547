@import '../../../styles';

.container {
  width: 100%;
  padding: 0 calc(50% - 564px) 36px;
  @include element-flex(column, flex-start);
  background: $main-background;

  @include desktopWidth {
    padding: 0 24px;
  }

  @include tabletWidth {
    height: auto;
    bottom: 24px;
    max-width: 100vw;
    padding: 24px 24px 48px;
  }

  &.white {
    background-color: #fff;
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 6px;

  @include tabletWidth {
    width: 100%;
    gap: 12px;
    flex-direction: column;

    &:first-of-type {
      margin-bottom: 24px;
    }
  }
}

.conjunction {
  @include tabletWidth {
    display: none;
  }
}

.link {
  color: $blue-color;

  &:hover {
    color: darken($blue-color, 15%);
  }
}
