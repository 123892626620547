@import 'src/styles';

.container {
  @include element-flex(row, stretch, flex-start);
  background-color: $odd-row-background;
  width: 100%;
  padding: 18px 12px;
  gap: 24px;
  border-radius: 8px;

  @include lgDesktopWidth {
    @include element-flex(row, flex-start, flex-start);
    flex-wrap: wrap;
  }

  @include smDesktopWidth {
    @include element-flex(column);
  }

  @include tabletWidth {
    padding: 18px 10px;
  }
}

.row {
  @include element-flex(row);
  gap: 1ch;
  padding: 12px 0;
}

.column {
  @include element-flex(column, center);
  min-width: 300px;
  min-height: 50px;
  gap: 3px;

  @include lgDesktopWidth {
    min-width: 200px;
  }

  @include smDesktopWidth {
    min-width: unset;
  }
}

.box {
  width: 100%;
  gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr ;
  flex: 1;
  padding: 6px;

  @include desktopWidth {
    @include element-flex(column);
  }
}

.link {
  @include trimText;
  max-width: 300px;
}

.label {
  font-size: $font-size-s;
  font-weight: normal;

  @include smDesktopWidth {
    font-size: $font-size-m;
  }
}

.value {
  font-size: $font-size-m;
  font-weight: 700;
}

.buttonWrapper {
  @include element-flex(row, flex-end);
  width: 100%;
}
