@import 'src/styles';

.container {
  width: 100%;
  z-index: 2;
}

.header {
  padding: 0 25px;

  @include desktopWidth {
    padding: 0;
    width: fit-content;
    margin: 0;
  }
}

.title {
  min-width: 80px;
  margin: auto 0;
}

.wrapper {
  @include element-flex(column, flex-start, stretch);
  padding: 24px 12px;
  border-radius: $radius-s;
  background: #fff;
  position: sticky;
  top: 110px;

  @include desktopWidth {
    @include element-flex(row, flex-start, center);
    gap: 12px;
    width: 100%;
    padding: 10px 24px;
  }
}

.link {
  @include element-flex(row, flex-start, center);
  gap: 12px;
  padding: 12px;
  color: $font-color-light;
  font-size: $font-size-m;
  font-weight: 700;
  letter-spacing: 0;
  transition: color 0.2s;

  @include smDesktopWidth {
    white-space: nowrap;
  }

  svg > path {
    transition: all 0.2s;
  }

  &:hover {
    color: lighten($font-color, 10%);
    svg > path {
      fill: lighten($font-color, 10%);
    }
  }

  &.active {
    color: $font-color;

    svg > path {
      fill: $font-color;
    }
  }

  &.active:hover {
    color: darken($font-color, 5%);

    svg > path {
      fill: darken($font-color, 5%);
    }
  }
}

.select{
  min-width: 175px;
}

