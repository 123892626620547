@import 'src/styles';

.phoneFormatter {
  padding: 6px 8px !important;
  height: 40px !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: $radius-s !important;
  transition: all 0.2s !important;
  background: #fff;

  &.disabled{
    background: $disabled-input-background;
  }

  & input {
    font-size: 15px !important;
    font-weight: 700 !important;

    &::placeholder {
      color: #9a9fa5;
    }
  }

  &:hover {
    border: 1px solid $input-hover-color !important;
  }

  &:focus-within {
    border: 1px solid #4849ff !important;
    box-shadow: 0 0 0 1px #4849ff !important;
  }

  &.error {
    border: 1px solid $danger-color !important;
  }

  &:focus-within.error {
    border: 1px solid $danger-color !important;
    box-shadow: 0 0 0 1px $danger-color !important;
  }
}
