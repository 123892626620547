@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 160px 70px;
  gap: 18px;
  padding: 6px;
  border-radius: $radius-s 0 0 $radius-s;
  min-height: 120px;
  @include element-flex(column, center, flex-start);

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }
}

.savings {
  @include element-flex(row, flex-start, center);
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;
}

.text {
  font-size: $font-size-s;
  font-weight: 400;
  @include limit-lines(3);
}

.priceWrapper {
  @include element-flex(row);
  gap: 1ch;
  width: 100%;
}

.price {
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: 600;
}

.prevPrice {
  text-decoration: line-through;
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: 600;
  color: #6a798b;
}

.available {
  font-size: $font-size-s;
  font-weight: $font-regular;
  line-height: 24px;
  color: #6a798b;
}

.offerBox {
  @include element-flex(column);
  gap: 16px;
  padding: 12px 0;
  width: 100%;
  z-index: 2;
}

.row {
  width: 100%;
  @include element-flex(row, flex-start, center);
  gap: 12px;
  min-height: 27px;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 6px;
  row-gap: 12px;
}

.icon {
  width: 24px;
  height: 20px;
  padding-right: 6px;
}

.details {
  @extend .text;
  @include element-flex(row, flex-start, center);
  font-weight: 600;
  gap: 1ch;
}

.producer {
  @extend .details;
}

.tag {
  max-width: unset !important;
  width: fit-content;
  animation: fadeIn 0.2s forwards;
}

@keyframes fadeIn {
  @include keyframe-fadeIn;
}
