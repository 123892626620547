@import 'src/styles';

.container {
  @include element-flex(column, center, center);
  height: calc(100vh - 170px);
  width: 100%;
}

.wrapper {
  @include element-flex(column);
  gap: 18px;
  max-width: 548px;
  width: calc(100% - 24px);
  margin: 0 12px;
}

.text {
  font-size: $font-size-m;
}

.blue {
  color: $blue-color;
}
