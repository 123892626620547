@import '../../../../styles/index';

.header {
  grid-template-columns: 24px 1fr 1fr;
  gap: 24px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;

  @include smDesktopWidth {
    @include element-flex(row, flex-end, center);
    & > p {
      display: none;
    }
  }
}

.checkbox {
  @include smDesktopWidth {
    width: fit-content;
  }
}

.placeholderHeader {
  grid-template-columns: 24px 1fr 1fr;
  gap: 24px;

  @include smDesktopWidth {
    grid-template-columns: 24px 1fr 1fr;
  }
}

.button {
  margin: 24px auto 0;
}

.wrapper {
  max-height: 500px;
  width: 90vw;
  max-width: 700px;
  overflow: auto;
  padding: 0 12px;
}
