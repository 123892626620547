@import '../../../styles';

.container {
  width: 100%;
  max-width: 100%;
  height: 60px !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: $radius-s !important;
  margin: 0 !important;
  padding: 6px !important;
  mix-blend-mode: darken;
  box-sizing: border-box !important;
  overflow: hidden;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 150px 150px 150px 100px;
  width: 100%;
}
.line {
  height: 20px !important;
}

.background {
  width: 100%;
  border-radius: $radius-s !important;
  background: #ffffff;

  &:nth-of-type(odd) {
    background: $odd-row-background !important;
  }
}
