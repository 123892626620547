//Main fonts
$main-font: 'Lato', sans-serif;
$public-font: 'Plus Jakarta Display', sans-serif;

// Font sizes
$font-size-xxl: 24px;
$font-size-xl: 20px;
$font-size-l: 18px;
$font-size-m: 16px;
$font-size-s: 14px;
$font-size-xs: 12px;
$font-size-xxs: 10px;

// Font weights
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semiBold: 600;
$font-bold: 700;
$font-extraBold: 800;

// Border radius
$radius-l: 13px;
$radius-m: 10px;
$radius-s: 6px;

// Box shadows
$shadow-main: 3px 3px 15px 0 rgba(0, 0, 0, 0.05);
$shadow-input-focus: 0 0 0 2px rgba(255, 110, 49, 0.4);
$shadow-input-error: 0 0 0 2px rgba(239, 68, 68, 0.4);

// SVG colors
$spinner-upload-color: invert(24%) sepia(34%) saturate(181%) hue-rotate(171deg) brightness(87%) contrast(81%);

// Letter spacing
$letter-spacing-1: -0.1px;
$letter-spacing-2: -0.5px;
$letter-spacing-3: -0.66px;
$letter-spacing-4: -0.88px;
$letter-spacing-5: -1.2px;

// Spacing
$spacing-1: 5px;
$spacing-2: 10px;
$spacing-3: 15px;
$spacing-4: 15px;
$spacing-5: 20px;
$spacing-6: 25px;
$spacing-7: 30px;
$spacing-8: 40px;
$spacing-9: 50px;
$spacing-10: 55px;
$spacing-11: 60px;
$spacing-12: 70px;
$spacing-13: 80px;
$spacing-14: 90px;

// Paddings
$padding-main-container: $spacing-5 $spacing-10 $spacing-5 $spacing-7;
$node-desktop-padding: 90px 70px;
$node-mobile-padding: 30px 20px;

// Constants sizes
$nav-height: 84px;
$footer-height: 70px;
$mob-nav-height: 70px;
$sidebar-width: 245px;
$offset-top-navbar: 122px;
$public-pages-top-margin: 54px;
$topbar-notification-height: 44px;
$topbar-notification-transition-timing-function: linear;
$topbar-notification-transition-duration: 0.1s;

// PhotoUploader
$small-uploader: 100px;
$large-uploader: 150px;
