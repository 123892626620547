@import 'src/styles';

.container{
  @include element-flex(column, center, center);
  gap: 24px;
  width: 100%;
  max-width: 550px;
}

.buttonWrapper{
  @include element-flex(row, center, center);
  width: 100%;
  gap: 24px;
}

.box{
  @include element-flex(column);
  gap: 6px;
  width: 100%;
  padding: 12px;
  background: $gray-lightest-color;
  border-radius: $radius-m;
}