@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 40px;
  gap: 24px;
  padding: 12px;
  border-radius: $radius-s;
  min-height: 60px;
  position: relative;

  &.admin{
    grid-template-columns: 2fr 2fr 1fr 150px 100px 40px;
  }

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  @include smDesktopWidth {
    @include element-flex(column);
    gap: 12px;
    padding: 24px 10px;
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;

  &.reverse {
    justify-content: flex-end;
  }
}

.text {
  font-size: $font-size-s;
  font-weight: 400;
  word-break: break-word;
}

.tag {
  max-width: 170px !important;
}

.name {
  @include smDesktopWidth {
    font-size: $font-size-xl;
    font-weight: 700;
    text-align: left;
    min-width: 90px;
    padding-right: 52px;
  }
}

.menu {
  position: static !important;

  @include smDesktopWidth {
    position: absolute !important;
    right: 12px;
    top: 24px;
  }
}