@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.header {
  @include element-flex(column, center, center);
  gap: 30px;
  margin: 100px 0 60px;

  @include desktopWidth {
    margin: 60px 0 20px;
  }

  @include tabletWidth {
    margin: 40px 0 20px;
    gap: 15px;
  }

  h1 {
    font-size: 32px;
    line-height: 40px;

    @include desktopWidth {
      font-size: 28px;
      line-height: 34px;
    }

    @include tabletWidth {
      font-size: 24px;
      line-height: 30px;
    }
  }
}
