@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 36px;
}

.companies {
  @include element-flex(column, flex-start, stretch);

  @include desktopWidth {
    overflow: scroll;
  }
}
