@import 'src/styles';

.container {
  @extend .publicContainer;
  background: $main-background;
  padding: 48px calc(50% - 564px) 0;
  display: grid;
  grid-template-columns: 1fr 456px;
  column-gap: 156px;
  height: calc(100vh - 168px);

  @include lgDesktopWidth {
    padding-top: 36px;
  }

  @include desktopWidth {
    padding: 36px 24px 24px;
    grid-template-columns: 1fr 1fr;
  }

  @include smDesktopWidth {
    @include element-flex(column, center, flex-start);
  }

  @include tabletWidth {
    min-height: calc(100vh - (132px + 84px));
    @include element-flex(column, center, center);
  }
}

.newMemberContainer {
  width: 550px;
  margin: 0 auto 50px;
  padding: 50px 0;
  @include element-flex(column, center, center);
  min-height: calc(100vh - 230px);
}

.column {
  @include element-flex(column);
  position: relative;
  gap: $spacing-5;
  flex: 1 0;

  &.center {
    justify-content: center;
  }

  @include tabletWidth {
    @include element-flex(column, flex-start, center);
    width: 100%;

    &:not(:first-of-type) {
      display: none;
    }
  }
}

.title {
  font-size: $font-size-xxl;
  font-weight: $font-extraBold;
  line-height: 36px;
  color: $font-color;
  // max-width: 350px;
  max-width: 560px;
  z-index: 1;

  &.center {
    text-align: center;
  }

  @include tabletWidth {
    text-align: center;
    margin: 0 auto 12px;
  }
}

.text {
  width: 100%;
  font-size: $font-size-m;
  max-width: 350px;
  margin-top: 12px;
  z-index: 1;

  @include tabletWidth {
    text-align: center;
    margin: 0 auto;
  }
}

.image {
  width: 100%;
  max-height: 75vh;
  grid-row: 1 / 3;
  align-self: center;
  z-index: 1;

  @include smDesktopWidth {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translate(28%, -43%) scale(1.1);
    z-index: 0;
    opacity: 0.2;
  }

  @include tabletWidth {
    display: none;
  }
}

.card {
  width: 100%;
  height: fit-content;
  max-width: 560px;
  @include element-flex(column);
  background: #ffffff;
  border-radius: $radius-s;
  padding: 24px;
  gap: 12px;
  z-index: 1;

  &.withoutPadding {
    padding: 0 0 24px 0;
    max-width: 500px;
  }

  @include smDesktopWidth {
    width: 560px;
  }

  @include tabletWidth {
    width: 100%;
    margin: 0 auto 50px;
  }
}

.formLabel {
  font-size: $font-size-l;
  margin-bottom: 12px;
}

.smallFormLabel {
  font-size: $font-size-m;
  margin-bottom: 12px;
}

.checkboxWrapper {
  width: 100%;
  @include element-flex(row, flex-start, center);
  position: relative;
  gap: 12px;
  margin: 12px 0 36px;

  @include smDesktopWidth {
    margin: 12px 0;
  }
}

.buttons {
  @include element-flex(row, flex-end, center);
  gap: 24px;
  width: 100%;
  flex-wrap: wrap;

  &.center {
    justify-content: center;
  }

  @include tabletWidth {
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 12px;
  }
}

.button {
  min-width: 120px;
  height: 43px;
}

.buttonsWithMargin {
  margin-top: 48px;
  @include smDesktopWidth {
    margin-top: 24px;
  }
}

.tilesContainer {
  width: 200px !important;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: -220px;

  @include smDesktopWidth {
    position: static;
    width: 100%;
    max-width: 560px;
  }
}

.tilesDesktop {
  @include phoneWidth {
    display: none;
  }
}

.tilesMobile {
  display: none;
  @include phoneWidth {
    display: block;
    margin-bottom: 40px;
  }
}

.tiles {
  @include desktopWidth {
    justify-content: flex-start;
  }

  @include tabletWidth {
    justify-content: center;
  }
}

.tile {
  @include smDesktopWidth {
    flex: 1 1 30%;
  }
}

.singleButtonWrapper {
  width: 100%;
  @include element-flex(row, center, center);
  margin-top: 24px;
}

.welcomeWrapper {
  @include element-flex(column);
  gap: 6px;
  width: 100%;
  background: #fff;
  border-radius: $radius-s;
  padding: 24px;
}

.blue {
  color: $blue-color;
}
