@import 'src/styles';

.header {
  grid-template-columns: 1fr 130px 120px 80px 80px 100px 130px 90px;
  padding: 18px 6px;
  gap: 12px;
  place-items: center start;

  @include lgDesktopWidth {
    display: none;
  }

  p {
    @include element-flex(row, flex-start, center);
    gap: 1ch;
  }
}

.price {
  width: 100%;
  @include element-flex(row, center);
}
