@import 'src/styles';

.container{
  width: 100%;
  @include element-flex(column, flex-start, center);
  gap: 12px;
}

.buttonsWrapper{
  @include element-flex(row, center, center);
  gap: 24px;
}