@import 'src/styles';

.button {
  @include element-flex(row, center, center);
  column-gap: 12px;
  height: 42px;
  text-align: center;
  padding: 3px 24px;
  background: $blue-color;
  border-radius: 999px;
  font-size: $font-size-s;
  font-weight: $font-semiBold;
  font-family: $main-font;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
  line-height: normal;
  border: 1px solid transparent;
  letter-spacing: 0;

  @include smDesktopWidth {
    font-size: $font-size-m;
  }
  @include printView {
    display: none;
  }

  &:hover,
  &:active {
    background-color: darken($blue-color, 6%);
    color: #ffffff;
  }

  &.withIcon {
    position: relative;
    padding: 12px 24px;
  }

  &.mobile {
    padding: 8px 14px;
    height: 40px;
    column-gap: 6px;
  }

  &:focus {
    border: 1px solid #4849ff;
    box-shadow: 0 0 0 1px #4849ff;
  }

  & > span {
    white-space: nowrap;
  }

  &.green{
    background-color: $green-color;
    color: $font-color;

    &:hover{
      background-color: darken($green-color, 7%);
    }
  }

  &.red{
    background-color: $danger-color;
    color: $font-color;

    &:hover{
      background-color: darken($danger-color, 5%);
    }
  }
}

.loader {
  height: 18px;
}

.loaderWrapper {
  @include element-flex(row, center, center);
  gap: 6px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 40px;
  background-color: $blue-color;
  border-radius: 99px;
  z-index: 10;
  opacity: 1 !important;
}

.darkTextAndBorder {
  border: 1px solid $border-color;
  color: $font-color;

  &:hover,
  &:active {
    border-color: darken($font-color, 6%);
  }
}

.gray {
  background-color: $gray-color;
  line-height: normal;
  color: $blue-color;

  &:hover,
  &:active {
    background-color: darken($gray-lighter-color, 5%);
    color: darken($blue-color, 3%);
  }
}

.button.gray {
  & > svg > path {
    fill: $font-color-light;
  }

  .loader,
  .icon {
    filter: brightness(0.2);
  }
}

.empty {
  composes: darkTextAndBorder;
  background-color: transparent;
  color: $blue-color;
  line-height: normal;

  &:hover,
  &:active {
    background-color: rgba(136, 136, 136, 0.1);
    color: $blue-color;
  }
}

.cancel {
  composes: darkTextAndBorder;
  background-color: rgba(245, 105, 105, 0.2);
  line-height: normal;

  &:hover,
  &:active {
    color: #000000;
    background-color: rgba(245, 105, 105, 0.3);
  }
}

.white {
  composes: darkTextAndBorder;
  background-color: #ffffff;

  &:hover,
  &:active {
    background-color: darken(#ffffff, 3%);
  }
}

.disabled {
  background: $gray-light-color;
  color: $font-color-light;
  pointer-events: none;
  cursor: not-allowed !important;

  &:hover,
  &:active {
    background-color: $gray-light-color;
    color: $font-color-light;
  }
}

.icon {
  width: 22px;
  height: 22px;
  object-fit: contain;
}

.isLoading {
  @include disabled-ui();
}
