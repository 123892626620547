@import 'src/styles';

.buttons{
  @include element-flex(row, flex-end, center);
  gap: 24px;
}

.buttonsWrapper{
  @include element-flex(row, center, center);
  gap: 24px;
  width: 100%;
  margin-top: 24px;
}