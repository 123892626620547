@import 'src/styles';
@import '../../../variables';

.offerContainer {
  width: calc(100% + 12px);
  display: grid;
  gap: 12px;
  padding: 0 6px;
  margin-left: -6px;
  margin-top: 12px;
  border-radius: $radius-s;
  min-height: 100px;
  position: relative;
  background: #e4e8ef !important;

  grid-column: 1 / 7;
  grid-template-columns: $gross-grid-template-columns;

  &.net {
    grid-column: 1 / 8;
    grid-template-columns: $net-grid-template-columns;

    .wrapper.priceInputWrapper {
      @include customBreakpoint(1400px) {
        grid-row: 1;
        grid-column: 2;
      }

      @include tabletWidth {
        grid-row: 3;
        grid-column: 1;
      }
    }
  }

  @include customBreakpoint(1400px) {
    grid-template-columns: $custom-breakpoint-grid-template-columns !important;
    grid-column: 1 / 7 !important;
    column-gap: 24px;
    padding: 12px 6px;
  }

  @include customBreakpoint(1100px) {
    grid-template-columns: $smDesktopWidth-grid-template-columns !important;
    grid-column: 1 / 6 !important;
  }

  @include tabletWidth {
    grid-template-columns: $desktopWidth-grid-template-columns !important;
    grid-column: 1 / 3 !important;
    gap: 24px;
  }
}

.replacementContainer {
  @extend .offerContainer;

  &::before {
    width: calc(100% + 12px) !important;
    height: calc(100% + 12px);
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  position: relative;
  gap: 12px;

  &.productName {
    padding-right: 40px;
  }

  &.productDetails {
    @include element-flex(column);

    @include customBreakpoint(1400px) {
      justify-content: center;
      grid-column: 1;
      grid-row: 1 / 3;
    }

    @include tabletWidth {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }
  }

  &.replacementInputs {
    width: 100%;
    @include element-flex(row);

    @include customBreakpoint(1400px) {
      @include element-flex(column, center);
    }

    @include tabletWidth {
      gap: 24px;
    }
  }

  &.replacementDetails {
    width: 100%;

    @include tabletWidth {
      @include element-flex(column);
      gap: 24px;
    }
  }

  &.priceInputWrapper {
    @include customBreakpoint(1400px) {
      grid-row: 1;
      grid-column: 2 / 4;
    }

    @include tabletWidth {
      grid-row: 3;
      grid-column: 1 / 3;
    }
  }

  &.vatWrapper {
    @include customBreakpoint(1400px) {
      grid-row: 1;
      grid-column: 3;
    }

    @include tabletWidth {
      grid-row: 3;
      grid-column: 2;
    }
  }

  &.quantityWrapper {
    justify-content: center;

    @include customBreakpoint(1400px) {
      @include element-flex(column);
      gap: 0;
      grid-row: 1;
      grid-column: 4;
    }

    @include tabletWidth {
      grid-column: 1;
      grid-row: 5;
    }
  }

  &.amountWrapper {
    @include customBreakpoint(1400px) {
      @include element-flex(column);
      gap: 0;
      grid-column: 5;
      grid-row: 1;
    }

    @include customBreakpoint(1100px) {
      grid-column: 4;
      grid-row: 2;
    }

    @include tabletWidth {
      grid-column: 2;
      grid-row: 5;
    }
  }

  &.availabilitySelectWrapper {
    @include customBreakpoint(1400px) {
      grid-row: 2;
      grid-column: 2 / 4;
    }

    @include tabletWidth {
      grid-row: 4;
      grid-column: 1 / 3;
    }
  }

  &.menuWrapper {
    @include element-flex(row, center, center);

    @include customBreakpoint(1400px) {
      grid-row: 1;
      grid-column: 6;
      align-items: flex-start;
    }

    @include customBreakpoint(1100px) {
      grid-row: 1;
      grid-column: 5;
    }

    @include tabletWidth {
      position: absolute;
      right: 12px;
      top: 0;
    }
  }
}

.text {
  font-size: $font-size-s;
  font-weight: 400;
  line-height: 20px;
}

.numberValue {
  font-weight: 700;
  text-align: right;
  padding-right: 9px;
  width: 100%;

  @include customBreakpoint(1400px) {
    text-align: left;
    line-height: 40px;
    font-size: $font-size-m;
  }
}

.column {
  @include element-flex(column, flex-start, stretch);
  gap: 6px;
  width: 100%;
}

.menu {
  position: static !important;
}

.saveButton {
  width: 32px;
  height: 32px;
  align-self: center;
  justify-self: center;
}

.vat {
  width: 85px;

  @include customBreakpoint(1400px) {
    width: 100%;
  }
}

.producer {
  @include element-flex(row, flex-start, center);
  gap: 12px;
  @include limit-lines(2);
  display: flex !important;
  overflow: hidden;
  font-size: $font-size-m;

  & > p {
    line-height: normal;
  }
}

.priceInput {
  @include customBreakpoint(1400px) {
    width: 100% !important;
  }
}

.inputProducer {
  flex: 1;
}

.inputName {
  flex: 2;
}

.replacement {
  @extend .text;
  display: none;

  @include tabletWidth {
    display: block;
  }
}

.wrapper.productDetails {
  justify-content: center;
}

.offerThumbnail {
  box-shadow: 0 0 0 1px $border-color-light;
}

.replacementThumbnail {
  box-shadow: 0 0 0 1px $border-color-light;
}

.fixedPriceIndicator{
  @include element-flex(row, flex-end, center);
  gap: 3px;
  position: absolute;
  font-size: $font-size-xs;
  height: $font-size-xs;
  line-height: $font-size-xs;
  color: $font-color-light;
  top: -16px;
  letter-spacing: -0.3px;
  left: 0;

  &.active{
    color: $blue-light-color;

    .info path{
      fill: $blue-light-color;
    }
  }
}

.box{
  @extend .fixedPriceIndicator;
  top: unset;
  bottom: 3px;

  * {
    font-size: $font-size-xs;
    line-height: $font-size-xs;
    letter-spacing: -0.2px;
    margin: auto 0;
  }

}

.info{
  transform: scale(0.8);
}
