@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.inputWrapper {
  @include element-flex(row, stretch, center);
  width: 100% !important;

  position: relative;
}

.input {
  padding-left: 20px;
  height: 76px;
  width: 100%;
  font-size: $font-size-s;
  font-weight: $font-semiBold;
  font-family: 'Lato', sans-serif;
  border-radius: $radius-s;
  transition: all 0.2s;
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-shadow: none;

  &:hover {
    border: 1px solid $input-hover-color;
  }

  &::placeholder {
    color: #9a9fa5;
  }

  &:focus {
    border: 1px solid #4849ff;
    box-shadow: 0 0 0 1px #4849ff;
  }
}

.submit {
  position: absolute;
  right: 13px;
  top: 16px;

  @include element-flex(row, center, center);
  column-gap: 12px;
  height: 45px;
  text-align: center;
  padding: 12px 24px;
  background: $blue-color;
  border-radius: 999px;
  font-size: $font-size-s;
  font-weight: $font-semiBold;
  font-family: $main-font;
  color: white;
  cursor: pointer;
  z-index: 1;
  transition: background 0.2s, color 0.2s;
  line-height: normal;
  border: 1px solid transparent;
  letter-spacing: 0;

  &:hover,
  &:active {
    background-color: darken($blue-color, 6%);
    color: #ffffff;
  }
}
