@import '../../../styles/index';

.container {
  @include element-flex(row, center, center);
  width: 32px;
  height: 32px;
  gap: 3px;
  border-radius: 50%;
  background: transparent;
  transition: background 0.2s ease-in-out;
  position: absolute;
  right: 24px;
  top: 18px;
  box-shadow: $shadow-main;

  &:hover {
    background: $gray-lightest-color;
  }

  &.focus,
  &.active {
    background: $gray-lighter-color;
  }
}

.dot {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: $font-color-light;
}
