@import 'src/styles';

.overlay{
  width: 100%;
  height: 100%;
  background: #0000008f;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 19;
  opacity: 0;
  transition: all .3s cubic-bezier(.22,.65,.31,1);
  pointer-events: none;

  &.visible{
    opacity: 1;
    pointer-events: all;
  }
}

.container{
  position: fixed;
  width: 475px;
  top: 0;
  right: -510px;
  background: #fff;
  z-index: 20;
  height: 100%;
  overflow: auto;
  padding: 0 12px 24px;
  box-shadow: $shadow-main;
  transition: all .3s cubic-bezier(.22,.65,.31,1);
  pointer-events: none;

  &.slideIn{
    right: 0;
    pointer-events: all;
  }

  @include smDesktopWidth{
    width: 90vh;
    max-width: 475px;
  }
}

.item{
  width: calc(100% - 40px);
  @include element-flex(column, center);
  padding: 12px;
  border-radius: $radius-s;
  background: $gray-lighter-color;
  margin: 0 24px 18px 16px;
  position: relative;
  min-height: 60px;

  &.green{ background: transparentize($green-color, 0.4); }
  &.yellow{ background: transparentize($yellow-color, 0.4); }
  &.red{ background: transparentize($danger-color, 0.5); }
  &.blue{ background: transparentize($blue-tag-color, 0.4); }

  &:after{
    content: '';
    display: flex;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $font-color-light;
    position: absolute;
    top: 40px;
    left: -16px;
  }

  &:before{
    content: '';
    display: flex;
    width: 4px;
    height: calc(100% + 24px);
    background: $gray-light-color;
    position: absolute;
    top: 40px;
    left: -13px;
  }

  &.first{
    margin: 0 8px 18px 16px;
    width: calc(100% - 24px);

    .title{
      font-size: 17px;
      margin-bottom: 6px;
    }

    &:after{
      background: $blue-color;
      width: 14px;
      height: 14px;
      left: -18px;
    }
  }

  &.last {
    &:before{
      display: none;
    }
  }

}

.date{
  font-size: $font-size-s;
  color: $font-color-light;
  font-weight: normal;
}

.title{
  font-size: 15px;
  margin-bottom: 3px;
}

.text {
  font-size: $font-size-s;

  & > span{
    font-weight: 700;
  }
}

.content{
  font-style: italic;
  @include limit-lines(2);
}

.list{
  list-style: decimal;
  margin: 3px 0 0 20px
}

.product {
  font-size: $font-size-s;
  font-weight: normal;
}

.header{
  @include element-flex(column);
  gap: 12px;
  width: 100%;
  background: #fff;
  position: sticky;
  top: 0;
  padding: 24px 16px;
  z-index: 10;
}

.wrapper{
  @include element-flex(row, space-between, center);
  width: 100%;
}

.inputWrapper{
  @include element-flex(column, flex-start, center);
}

.bottomMessage{
  text-align: center;
  width: 100%;
}