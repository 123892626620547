@import 'src/styles';

.container{
  width: 100%;
  @include element-flex(column, flex-start, stretch);
  gap: 12px;

  & > *:last-child{
     margin-top: 24px;
  }
}