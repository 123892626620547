@import 'src/styles';

.menu{
  right: 12px;
  top: 12px;

  @include lgDesktopWidth{
    top: 24px;
  }
}

.wrapper{
  @include element-flex(column, flex-start, stretch);
  gap: 12px;
}