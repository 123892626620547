@import '../../../../../styles';
@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.container {
  @include element-flex(column, center, center);
  max-width: $txt-max;
  margin: 24px auto;

  &.small {
    margin: 24px auto 0;
    padding-bottom: 72px;
  }

  @include smDesktopWidth {
    max-width: unset;
    padding-bottom: 48px;
  }
}

.image {
  max-width: 90vw;
  cursor: pointer;

  &.small {
    max-width: 40vw;

    @include smDesktopWidth {
      max-width: 90vw;
    }
  }
}
