@import 'src/styles';

.container {
  width: 100%;
  @include element-flex(row, center, center);
  gap: 24px;
  background: lighten($danger-color, 5%);
  padding: 10px 0;
  max-height: 40px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999 !important;

  & > p {
    font-weight: 500;
    text-align: center;
  }
}
