@import '../../../../../../styles';

.container {
  width: 1000px;
}

.header {
  grid-template-columns: 40px 1fr 140px 170px 60px 170px;
  gap: 12px;

  @include desktopWidth {
    display: none;
  }
}

.buttonWrapper {
  @include element-flex(row, center, center);
  margin-top: 12px;
}
