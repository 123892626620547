@import './src/styles';

.container {
  @include element-flex(row, flex-end, center);
  padding: 0 30px 0 70px;
  gap: 24px;
  flex: 1;

  @include smDesktopWidth {
    display: none;
  }
}

.containerMobile {
  display: none;

  & > a {
    font-size: 18px;
    width: 100%;
    height: 100%;
    padding: 10px 0;
  }

  @include smDesktopWidth {
    @include element-flex(column);
    width: 100%;
    padding: 0 0;
    gap: 5px;
  }
}

.link {
  color: $blue-color;

  @include phoneWidth{
    text-align: center;
  }
}
