@import '../../../../../../styles';

.container {
  width: 100%;
}

.header {
  display: inline-grid;
  grid-template-columns: 30px 200px 190px 60px 120px 60px 120px;
  gap: 24px;
  flex: 1;
  padding: 0 12px;
}

.buttonWrapper {
  @include element-flex(row, center, center);
  margin-top: 12px;
}

.list {
  max-height: 60vh;
  overflow: auto;
}
