@import 'src/styles';

.container {
  @include element-flex(column, flex-start, stretch);
  width: 100%;
  gap: 12px;
  max-width: 500px;
}

.producerName {
  color: $blue-color;
  &:after,
  &:before {
    content: ' ';
  }
}

.label {
  display: inline-block;
  width: fit-content;
}

.button {
  width: 100%;
  margin-top: 36px;
  z-index: 0;
}
