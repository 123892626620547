@import '../../../styles';

.container {
  width: 100%;
  @include element-flex(row, space-between, center);
  padding: 48px 0 0;
  position: relative;
  bottom: 0;

  &.hidePadding{
    padding: 0;
  }

  @include smDesktopWidth {
    padding: 48px 20px 36px;
    @include element-flex(column, flex-start, center);
    gap: 24px;
  }
}

.wrapper {
  @include element-flex(row, center, center);
  gap: 6px;
}

.select {
  width: 130px;
}

.text {
  color: #6a798b;
  font-size: $font-size-s;
}

.button {
  @include element-flex(row, center, center);
  min-width: 50px;
  height: 30px;
  padding: 0 6px;
  font-size: $font-size-m;
  line-height: 24px;
  border-radius: $radius-s;
  transition: background 0.2s;
  position: relative;

  &.active::after {
    content: '';
    width: 35px;
    height: 3px;
    border-radius: 99px;
    background: $blue-color;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 200%);
  }

  &:hover {
    background: $border-color-lighter;
  }
}
