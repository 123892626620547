@import 'src/styles';

.button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  opacity: 0;
  animation: fadeIn 0.2s forwards;
  display: grid;
  place-items: center;
  background: $green-color;
  transition: background 0.2s;
  box-shadow: $shadow-main;
  border: 1px solid darken($green-color, 8%);

  &.large{
    width: 40px;
    height: 40px;
  }

  &:hover {
    background: darken($green-color, 12%);
  }

  &:focus {
    outline: 2px solid green;
    border-color: green;
  }

  &.deleteButton {
    background: $danger-color;
    border: 1px solid darken($danger-color, 5%);

    &:hover {
      background: darken($danger-color, 8%);
    }

    &:focus {
      outline: 1px solid red;
      border-color: red;
    }
  }
  &.downloadButton {
    background: $blue-color;
    border: 1px solid darken($blue-color, 5%);

    &:hover {
      background: darken($blue-color, 8%);
    }

    &:focus {
      outline: 1px solid $blue-light-color;
      border-color: $blue-light-color;
    }
  }

  &.disabled {
    opacity: 0.7 !important;
    cursor: not-allowed;

    &:hover {
      background: linear-gradient(145deg, #71ffcd, #5fd9ad);
      box-shadow: 4px 4px 7px #d0d0d0, -4px -4px 7px #ffffff;
    }
  }

  &.disabled.deleteButton {
    &:hover {
      background: $danger-color;
    }
  }
}

.tooltipWrapper {
  height: 32px;

  &.large{
    width: 40px;
    height: 40px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  85% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.download {
  width: 10px;

  & > path {
    fill: #fff;
  }
}
