@import '../../../../styles';

.container {
  @include element-flex(row);
  flex-wrap: nowrap;
  background: #fff;

  &:nth-of-type(odd) .productHead {
    background: #f6f7f8;

    & > .name {
      background: #f6f7f8;
    }
  }

  &:nth-of-type(even) .productHead {
    background: #fff;

    & > .name {
      background: #fff;
    }
  }

  &:nth-of-type(odd) .productBody {
    background-color: $odd-row-background;
  }
}

.headEmpty {
  background: #fff !important;

  & > .name {
    background: #fff !important;
  }
}

.productBody {
  @extend .container;

  & .columnHead {
    background: #fff;
  }
}

.column {
  @include element-flex(row, stretch);
  min-width: 230px;
  height: 60px;

  &.productHead {
    min-width: 600px;
    padding-left: 24px;
    position: sticky;
    left: -204px;

    @include smDesktopWidth {
      position: static !important;
      left: unset;
    }

    & > .name {
      position: sticky;
      left: 24px;

      @include smDesktopWidth {
        position: static !important;
        left: unset;
      }
    }
  }
}

.wrapper {
  flex: 1;
  height: 60px;
  @include element-flex(row, flex-start, center);
  gap: 6px;
  padding: 0 6px;

  &.name {
    flex: 4;
    & > p {
      @include limit-lines(2);
    }
  }

  &.increase {
    background: rgba(106, 241, 192, 0.1);
  }

  &.decrease {
    background: rgba(245, 105, 105, 0.1);
  }

  &.columnHead {
    @include element-flex(column, center, center);
    gap: 6px;
  }
}
