@import 'src/styles';

.container {
  @include element-flex(column, flex-end, flex-end);
  gap: 6px;
  width: max-content;

  @include smDesktopWidth {
    @include element-flex(column);
  }
}
.small {
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;
}

.counter {
  color: $font-color-light;
  padding-left: 0.5ch;
}

.suppliersLabel {
  @include smDesktopWidth {
    display: none;
  }
}
