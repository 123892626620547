@import 'src/styles';

.wrapper {
  @include element-flex(column);
  width: 100%;
  gap: 24px;
  background: #fff;
  padding: 24px 12px;
  border-radius: $radius-s;
  box-shadow: $shadow-main;

  @include tabletWidth {
    padding: 18px 10px;
  }
}

.headerTop {
  @include element-flex(column);
  padding-left: 12px;
  gap: 3px;

  @include smDesktopWidth {
    padding: 0;
    gap: 12px;
  }
}

.title {
  @include tabletWidth {
    padding: 0;
  }
}

.container {
  height: auto;
  position: relative;
  transition: height 0.3s cubic-bezier(0.71, 0.13, 0.46, 1.05);

  &.close {
    height: 400px;
  }
}

.showMore {
  width: 100%;
  height: 200px;
  display: grid;
  place-items: center;
  text-align: center;
  font-size: $font-size-s;
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  background: linear-gradient(to bottom, transparent, #f2f5f9 70%);
  color: $blue-color;

  &:hover {
    text-decoration: underline;
  }
}

.header {
  grid-template-columns: 1fr 150px 90px 90px 120px 90px 35px 35px;
  gap: 12px;

  @include lgDesktopWidth {
    & > p {
      display: none;
    }
    border: none;
    padding: 0;
  }
}

.buttonWrapper {
  @include element-flex(row, center);
  width: 100%;
  margin-top: 24px;
  gap: 24px;

  & > button {
    min-width: 250px;
  }
}

.checkboxWrapper {
  padding-left: 6px;
  width: 100%;
  @include element-flex(row, flex-end, center);
}

.comment {
  font-size: $font-size-m;
  padding: 0 12px;

  @include tabletWidth {
    padding: 0;
  }
}
