@import 'src/styles';

.container {
  padding: 6px 10px;
  border-radius: $radius-s;
  font-size: $font-size-xs;
  font-weight: $font-bold;
  color: $font-color;
  background: rgba(137, 169, 212, 0.24);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 165px;
  letter-spacing: -0.2px;
  cursor: default;
  line-height: normal;

  @include desktopWidth {
    max-width: 100px;
  }

  &.green {
    background: $green-color;
  }

  &.yellow {
    background: $yellow-color;
  }

  &.red {
    background: $danger-color;
  }

  &.blue {
    background: $blue-tag-color;
  }
  &.gray {
    background: $font-color-light;
    color: #fff;
  }
  &.darkGray{
    background: $font-color;
    color: #fff;
  }
}
