@import 'src/styles';

.container {
  width: 100%;
}

.header{
  display: grid;
  grid-template-columns: repeat(2, 1fr) 110px 110px 75px;
  width: 100%;
  gap: 24px;
  padding: 18px 12px;
}

.loader {
  margin-top: 24px !important;
}

.list {
  @include element-flex(column);
  width: 100%;
}
