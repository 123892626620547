@import 'src/styles';

.header {
  grid-template-columns: 2fr 2fr 1fr 40px;
  gap: 24px;

  &.admin{
    grid-template-columns: 2fr 2fr 1fr 150px 100px 40px;
  }

  @include smDesktopWidth {
    display: none;
  }
}
