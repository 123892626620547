.container {
  width: 100%;
  height: calc(100vh - 84px - 70px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 70px;
  text-align: center;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5ch;
  font-size: 20px;
}

.main {
  font-size: 100px;
}

.second {
  font-size: 30px;
}

.img {
  width: 300px !important;
  height: 300px !important;
}
