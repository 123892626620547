@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 20px 1fr 130px 60px 90px 90px 110px 140px;
  gap: 12px;
  padding: 6px 12px;
  border-radius: $radius-s;
  position: relative;
  align-items: center;

  @include printView {
    grid-template-columns: 20px 1fr 130px 60px 90px 90px;
  }

  &.cancelled {
    background: rgba(245, 105, 105, 0.1);
  }

  &.cancelled:nth-of-type(odd) {
    background: rgba(199, 86, 86, 0.15);
  }

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  @include shadow-on-focus;

  &.replacement {
    background: $blue-lightest-color;

    &:nth-of-type(odd) {
      background: darken($blue-lightest-color, 3%);
    }
  }

  &.offer {
    background: $yellow-light-color;

    &:nth-of-type(odd) {
      background: darken($yellow-light-color, 7%);
    }
  }

  &.hideStatusColumn {
    grid-template-columns: 20px 1fr 130px 60px 90px 90px 110px;

    @include lgDesktopWidth {
      grid-template-columns: 2fr repeat(2, 1fr) !important;
    }

    @include smDesktopWidth {
      grid-template-columns: 2fr repeat(2, 110px) !important;
    }
  }

  @include lgDesktopWidth {
    grid-template-columns: 2fr repeat(3, 1fr);
    row-gap: 24px;
    padding: 18px;
  }

  @include smDesktopWidth {
    grid-template-columns: 2fr repeat(3, 110px);
  }

  @include tabletWidth {
    grid-template-columns: 3fr 2fr;
    padding: 18px 10px;
  }

  @include phoneWidth {
    @include element-flex(column);
  }
}
.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;
  position: relative;

  &.yellowBg {
    background-color: darken($yellow-light-color, 2%);
    border-radius: $radius-s;
    padding: 0 3px;
    margin: 0 -3px 0 -3px;
  }

  @include lgDesktopWidth {
    @include element-flex(column);
    gap: 6px;
  }

  @include phoneWidth {
    order: 1;
  }

  &.qty{
    gap: 0;
  }

  &.productName {
    @include lgDesktopWidth {
      grid-column: 1 / 4;
      @include element-flex(row, flex-start, center);

      &.hideStatusColumn {
        grid-column: 1 / 6;
      }
    }

    @include tabletWidth {
      grid-column: 1 / 3;
    }
  }

  &.producer {
    @include tabletWidth {
      grid-column: 1 / 3;
    }
  }

  &.comment {
    @include lgDesktopWidth {
      grid-column: 1 / 5;
      padding: 24px 0 0;
    }

    @include tabletWidth {
      grid-column: 1 / 3;
    }
  }
}

.checkboxWrapper {
  @include element-flex(row, flex-start, center);
  width: 100%;
  gap: 12px;

  @include lgDesktopWidth {
    grid-column: 4;
    grid-row: 1;
  }

  @include tabletWidth {
    grid-column: 1 / 3;
    grid-row: 1;
    justify-content: flex-start;
  }

  @include phoneWidth {
    order: 0;
  }

  @include printView {
    display: none;
  }
}

.column {
  @include element-flex(column, center, flex-end);
  gap: 3px;
}

.tag {
  max-width: unset !important;
}

.checkbox {
  width: auto !important;
}

.text {
  font-size: $font-size-s;
  font-weight: 400;
  line-height: 18px;

  &.trim {
    @include limit-lines(3);
    cursor: pointer;
    line-height: normal;
  }
}

.container.offer,
.container.replacement {
  .text.trim {
    @include limit-lines(2);
  }
}

.priceWrapper {
  @include element-flex(row);
  gap: 1ch;
  width: 100%;
}

.price {
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: 600;
}

.prevPrice {
  text-decoration: line-through;
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: 600;
  color: #6a798b;
}

.available {
  font-size: $font-size-s;
  font-weight: $font-regular;
  line-height: 24px;
  color: #6a798b;
}

.offerBox {
  @include element-flex(column);
  gap: 16px;
  padding: 12px 0;
  width: 100%;
  z-index: 2;
}

.info {
  @include element-flex(row, flex-start, center);
  gap: 2px;

  &.replacement {
    color: $blue-color;
  }

  &.offer {
    color: $font-color-light;
  }
}

.menu {
  top: 14px;
  right: 6px;
}

.commentModal {
  @include printView {
    display: none;
  }
}

.arrow{
  width: 10px;
  height: 10px;
  margin-top: -3px;
  margin-left: 2px;
}

.down{
  @extend .arrow;
  fill: #5bd75b;
  transform: rotateZ(180deg);

  *{
    fill: #5bd75b;
  }
}

.up{
  @extend .arrow;
  fill: $danger-color;

  *{
    fill: $danger-color;
  }
}

.infoQty{
  position: absolute;
  right: -3px;
  top: -6px;
}

