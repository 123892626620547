@import 'src/styles';

.container{
  width: 100%;
}

.box{
  border-radius: $radius-m;
  background: $blue-lightest-color;

  @include shadow-on-focus;
}

.messagesWrapper{
  @include element-flex(column, flex-start, stretch);
  gap: 24px;
  padding: 24px;
  background: $blue-lightest-color;
  border-radius: $radius-m;
  max-height: 60dvh;
  overflow: auto;
  transition: opacity 0.1s;

  &.isLoading{
    opacity: 0.7;
  }

  @include tabletWidth{
    row-gap: 36px;
    padding: 18px 10px;
  }
}

.divider{
  width: 100%;
  text-align: center;
  color: $font-color-light;
  font-size: $font-size-xs;
  position: relative;
  border-bottom: 1px solid $border-color-lighter;
}

.inputWrapper{
  @include element-flex(row, stretch, flex-end);
  gap: 6px;
  background: $gray-light-color;
  padding: 12px;
  border-radius: $radius-m;
}

.textAreaBox{
  display: flex;
  box-shadow: $shadow-main;
}

.emptyMessage{
  width: 100%;
  text-align: center;
  font-size: $font-size-xl;
  font-weight: 400;
  color: $font-color-light;
  opacity: 0.5;
}