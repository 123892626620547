@import 'src/styles';

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr) 110px 110px 75px;
  width: 100%;
  gap: 24px;
  padding: 18px 12px;
  border-radius: $radius-s;
  min-height: 65px;

  & > p {
    width: 100%;
  }

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  &.owner {
    background: $blue-lightest-color;
  }
}

.menu {
  position: static !important;
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 6px;
  flex: 1;

  &.wide {
    grid-column: 2 / 4;
  }

  &.reverse {
    justify-content: flex-end;
  }
}
