@import 'src/components/layout/PublicPages/PublicPages.module.scss';
@import 'src/styles';

.container {
  display: flex;
  gap: 100px;
  min-height: 60vh;
  margin-top: 80px;
  margin-bottom: 80px;

  @include smDesktopWidth {
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

.left {
  max-width: 41%;

  @include smDesktopWidth {
    max-width: 490px;
    align-self: center;
  }
}

.divider {
  margin: 56px 0;
  @include smDesktopWidth {
    margin: 30px 0;
  }
}

.title {
  margin-bottom: 25px;
  font-size: 48px;
  line-height: 1.118em;
  font-weight: 600;

  @include tabletWidth {
    font-size: 40px;
  }

  @include phoneWidth {
    font-size: 35px;
  }
}

.blueTitle {
  color: $blue-color;
}

.desc {
  font-size: 20px;
  line-height: 1.636em;
  font-weight: 400;
  letter-spacing: $letter-spacing-s;
  margin-bottom: 25px;
}

.listWrapper {
  @include element-flex(column);
  gap: 12px;
  margin-top: 25px;
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 6px;

  & > p {
    font-size: $font-size-m;
    color: $blue-color;
  }
}

.right {
  @include element-flex(column, center, stretch);
}

.featureIcon {
  width: 24px;
  height: 24px;
}
