@import 'src/styles';

.link {
  color: $font-color;
  cursor: pointer;
  width: 100%;
  isolation: isolate;

  &:hover {
    text-decoration: none;
    color: $font-color;

    .info {
      text-decoration: underline;
    }
  }
}

.value {
  font-weight: 700;
}

.container {
  width: 100%;
  @include element-flex(row, flex-start, center);
  gap: 8px;
  padding: 18px 12px;
  background: $gray-lightest-color;
  border-radius: $radius-s;
  position: relative;
  transition: 0.2s background;

  &:hover {
    background: darken($gray-lightest-color, 7%);
  }

  &.unread {
    background: $blue-lightest-color;

    &:hover {
      background: darken($blue-lightest-color, 3%);
    }
  }

  &.tiny{
    padding: 6px;

    .text{
      font-size: 15px;
    }
  }

  &.fixed {
    max-width: 380px;
    background: rgba( 255, 255, 255, 0.9 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 8px );
    -webkit-backdrop-filter: blur( 8px );

    &:hover {
      background: darken(#fff, 3%) !important;
    }
  }
}

.text {
  font-weight: 400;
  font-size: $font-size-s;
  line-height: 19px;
  @include limit-lines(3);
}

.title {
  @extend .text;
  font-size: $font-size-m;
  font-weight: 700;
  color: $blue-color;
  padding-top: 3px;

  &.tiny{
    font-size: $font-size-s;
    line-height: normal;
  }
}

.info {
  color: $font-color-light;
  font-size: $font-size-s;
}

.timeAgo {
  position: absolute;
  top: 4px;
  right: 12px;
  color: $font-color-light;
  font-size: $font-size-xs;

  &.tiny{
    top: 50%;
    transform: translateY(-50%);
  }
}

.image {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
  background: #fff;
  object-fit: cover;

  &.tiny{
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
  }
}

.wrapper {
  @include element-flex(column);
  gap: 3px;
}
