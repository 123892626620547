.image {
  max-width: 100%;
}

.text {
  width: 100%;
  text-align: center;
  font-size: 21px;
}

.btns {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
}