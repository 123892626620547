@import 'src/styles';

.container {
  display: none;
  flex-direction: column;
  position: absolute;
  z-index: 90;
  top: 84px;
  left: 0;
  transform: translateY(-115%);
  transition: cubic-bezier(0.17, 0.67, 0.26, 0.98) 0.5s;

  height: max-content;
  width: 100%;
  padding: 20px;

  background-color: white;
  border-bottom: 1px solid whitesmoke;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

  @include smDesktopWidth {
    display: block;
  }
}

.containerActive {
  transform: translateY(0px);
}

.buttonsWrapper {
  display: flex;
  position: absolute;
  right: 3%;
  gap: 20px;
  justify-content: flex-end;

  @include phoneWidth {
    @include element-flex(column, flex-start, stretch);
    position: static;
    margin-bottom: 10px;
  }
}

.buttons {
  width: max-content;

  @include phoneWidth {
    width: 100%;
  }
}
