@import 'src/styles';

.container{
  @include element-flex(column, flex-start, center);
  gap: 36px;
}

.box{
  width: 100%;
  min-width: 624px;
  @include element-flex(column, flex-start, stretch);
  flex-wrap: wrap;
  max-height: 450px;
  gap: 24px;

  @include smDesktopWidth{
    max-height: 60dvh;
    min-width: unset;
    overflow: auto;
    flex-wrap: nowrap;
  }
}

.wrapper{
  @include element-flex(column);
  gap: 6px;
  width: 300px;

  @include smDesktopWidth{
    width: 100%;
  }
}

.title{
  font-size: $font-size-l;
}

.buttonWrapper{
  @include element-flex(row, center, center);
  gap: 24px;
}
