@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.flex {
  @include element-flex(row, stretch, center);
  min-height: 50vh;

  @include smDesktopWidth {
    @include element-flex(column, center, stretch);
  }
}

.left {
  flex: 1 1 70%;
  padding-left: 10px;
  margin-right: 20px;

  @include smDesktopWidth {
    flex: 1 1 100%;
    margin-right: unset;
    padding: 0;
  }

  & > h1 {
    margin: 0 0 2px;
    letter-spacing: 0 !important;
    line-height: normal;

    @include smDesktopWidth {
      margin: 0;
      font-size: 30px !important;
    }
  }

  & > p {
    font-weight: $font-bold;
    padding-top: 20px;
    max-width: 500px;
    margin-bottom: 40px;
    line-height: 24px;

    @include smDesktopWidth {
      font-size: $font-size-m;
      line-height: normal;
    }
  }
}

.wrapper {
  @include element-flex(row);
}

.button {
  max-width: max-content;
}

.right {
  flex: 30% 0 1;

  @include smDesktopWidth {
    margin-top: 48px;
  }

  & > img {
    max-width: 100%;
  }
}
