@import '../../../../styles';

.header {
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 24px;
  margin: 24px 0;

  @include tabletWidth {
    @include element-flex(column, center, center);
    margin-bottom: 12px;
    gap: 12px;
  }
}

.tableHeader {
  grid-template-columns: 1fr 2fr 1fr 1fr;
  gap: 24px;

  @include smDesktopWidth {
    display: none;
  }
}

.wrapper {
  @include element-flex(column, center);
  gap: 6px;
  height: 100%;

  @include tabletWidth {
    width: 100%;
    align-items: center;
  }
}

.picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: $shadow-main;
}

.importantData {
  font-size: $font-size-xl;
  color: $font-color;
  line-height: 28px;

  &.email {
    font-weight: 700;
    font-size: $font-size-xxl;
  }

  @include tabletWidth {
    font-size: $font-size-xl;
    line-height: normal;
    text-align: center;
    padding: 0 20px;
  }
}

.selectSmall {
  width: 100%;
  z-index: 2;
}

.container {
  @include element-flex(column, center, center);
  gap: 12px;
  width: 100%;
}

.button {
  margin: 24px auto 0;
  min-width: 150px;
}
