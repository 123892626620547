@import 'src/styles';

.container {
  width: 100%;
  max-width: 1000px;
  background: transparent !important;
  min-height: calc(100vh - 230px);
  margin: 0 auto;
}

.wrapper {
  @include element-flex(column, flex-start, stretch);
  background: transparent !important;
  gap: 48px;
}

.loaderWrapper {
  @include element-flex(column, center, center);
  min-height: 50vh;
}

.emptyState {
  @include element-flex(column, center, center);
  min-height: calc(100vh - 350px);
}
