@import 'src/styles';

.container {
  width: 100%;
  @include element-flex(column);
  gap: 12px;
  max-width: 400px;
}

.button {
  margin: 12px 0 0;
}