@import 'src/styles';

.button {
  @include element-flex(row, flex-start, center);
  width: fit-content;
  position: relative;

  @include lgDesktopWidth {
    margin-right: 12px !important;
  }

  & > p {
    font-size: $font-size-s;
    font-weight: 700;
  }

  &::after {
    content: '';
    position: absolute;
    right: -15px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    transform: translateY(-50%);
    z-index: 0;
    transition: background .2s, opacity .2s;
    border-top: 7px solid #dbdee1;
    border-bottom: 7px solid transparent;
    top: calc(50% + 4px);
  }

  &.asc::after {
    border-top: 7px solid transparent;
    border-bottom: 7px solid $font-color;
    top: calc(50% - 4px);
  }

  &.desc::after {
    border-top: 7px solid $font-color;
    border-bottom: 7px solid transparent;
    top: calc(50% + 4px);
  }
}
