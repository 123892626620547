@import 'src/styles';

.header {
  gap: 24px;
  padding: 18px 6px;
  grid-template-columns: 1fr 140px 90px 150px 150px;

  @include customBreakpoint(1400px) {
    grid-template-columns: 1fr 90px 100px 100px 150px;
    align-items: center;
  }

  @include desktopWidth {
    display: none;
  }
}

.row {
  @include element-flex(row, flex-start, center);
  gap: 6px;
  margin-left: -22px;
}
