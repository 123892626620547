@import 'src/styles';

.container{
  width: 100%;
  @include element-flex(column);
  gap: 6px;
  padding: 12px;
  background: $gray-lightest-color;
  border-radius: $radius-m;
}

.title{
  margin-bottom: 12px;
}