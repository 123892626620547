@import '../../../../styles';

.container {
  @include element-flex(column, flex-start, center);
  gap: 12px;
  width: 100%;
}

.input {
  margin-bottom: 12px;
  width: 100%;
}

.select {
  width: 100%;
  margin-bottom: 12px;
  z-index: 10;
}
