@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.top {
  @include element-flex(row, space-between, center);

  @include tabletWidth {
    @include element-flex(column, center, center);
    gap: 30px;
  }

  & img {
    width: 241px;
    max-width: 100%;

    @include phoneWidth {
      width: 180px;
    }
  }
}

.divider {
  margin: 40px 0;

  @include tabletWidth {
    margin: 35px 0 60px;
  }
}

.content {
  @include element-flex(row, flex-start, center);
  margin-bottom: 40px;
  width: 100%;

  @include smDesktopWidth {
    @include element-flex(column);
    gap: 40px;
    margin-bottom: 45px;
  }

  & h2 {
    font-size: $font-size-xl !important;
  }

  & > .divider {
    height: 200px;
    width: 1px;
    margin: 0 40px;

    @include desktopWidth {
      height: 250px;
    }

    @include smDesktopWidth {
      display: none;
    }
  }
}

.input {
  padding-right: 200px;
}

.left {
  @include element-flex(row, stretch, center);
  width: 100%;
  gap: 24px;

  @include tabletWidth {
    @include element-flex(column, center, center);
  }

  .title {
    flex: 1;
  }

  & > a {
    font-size: $font-size-m;
    color: $blue-color;
  }
}

.right {
  width: 100%;
  max-width: 446px;

  display: flex;
  flex-direction: column;

  & > h2 {
    color: $blue-color;
  }

  & > p {
    max-width: 350px;
    color: $blue-color;
    margin-bottom: 25px;
    letter-spacing: 0.01em;
  }
}
