@import 'src/styles';

.shippingTooltip {
  @include element-flex(row, center, center);
  width: 20px;
  height: 20px;
}

.left {
  margin-right: $spacing-1;
}

.right {
  margin-left: $spacing-1;
}
