@import 'src/styles';

.container {
  @include element-flex(column);
  gap: 24px;
  width: 500px;
  padding-top: 12px;

  &.secondVersion {
    width: 900px;
  }

  @include smDesktopWidth {
    width: 100%;
  }
}

.wrapper {
  @include element-flex(row, stretch, flex-start);
  width: 100%;
  gap: 24px;
  z-index: 3;

  @include smDesktopWidth {
    @include element-flex(column, flex-start, center);
  }
}

.column {
  @include element-flex(column, center, center);
  flex: 1;
  width: 100%;
  gap: 24px;
  position: relative;
}

.button {
  margin: 0 auto;
  width: 250px;
}

.selectWrapper {
  width: 100%;
  @include element-flex(row, space-between, center);
  gap: 12px;

  @include smDesktopWidth {
    @include element-flex(column, flex-start, stretch);
    gap: 24px;
  }
}

.select {
  width: 100%;
}

.inputFile {
  visibility: hidden;
  height: 0;
  width: 0;
}

.selectSmall {
  flex: 2;
}

.selectLarge {
  flex: 3;
}

.inputProducer {
  width: auto;
}

.unitWrapper {
  width: 300px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;

  &.withQty {
    grid-template-columns: 2fr 1fr;
  }
}

.switchWrapper {
  @include element-flex(row, flex-start, center);
  gap: 6px;
}

.modalControls {
  @include element-flex(column);
  gap: 12px;
}
