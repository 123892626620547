@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  gap: 12px;
  padding: 6px 12px;
  border-radius: $radius-s;
  min-height: 60px;
  grid-template-columns: 20px 1fr 140px 110px 40px;

  @include printView {
    grid-template-columns: 1fr 120px 100px 40px;
  }

  &.admin {
    grid-template-columns:  20px 1fr 140px 140px 110px 120px 40px;

    @include printView {
      grid-template-columns: 1fr 100px 110px 110px 40px;
    }

    @include customBreakpoint(1150px) {
      @include element-flex(column, flex-start, stretch);
      position: relative;
      padding: 40px 12px 12px;
    }
  }

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  @include shadow-on-focus;

  @include smDesktopWidth {
    @include element-flex(column, flex-start, stretch);
    position: relative;
    padding: 40px 12px 12px;
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;
}

.checkbox {
  @include element-flex(row, flex-start, center);
  gap: 12px;

  @include smDesktopWidth {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  @include printView {
    display: none;
  }
}

.text {
  font-size: $font-size-s;
  font-weight: 400;

  @include smDesktopWidth {
    font-size: $font-size-m;
    text-align: left;
    min-width: 90px;
  }
}

.referencePrice {
  @include printView {
    display: none;
  }
}

.menu{
  position: static;
}

.menuWrapper{
  @include smDesktopWidth{
    position: absolute;
    right: 8px;
    bottom: 12px;
  }
}
