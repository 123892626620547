@import 'src/styles';

.tableTitle {
  @include element-flex(row, flex-start, center);
  gap: 6px;
  font-size: $font-size-xl;
  font-weight: 800;
  color: $font-color;
}

.select{
  min-width: 200px;
  width: 300px;
}

.wrapper{
  padding: 0 12px;
  width: 100%;
}