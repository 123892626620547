@import 'src/styles';
@import '../../variables';

.mainContainer {
  width: 100%;
  @include element-flex(column, flex-start, stretch);
  gap: 6px;
  background: $odd-element-background;
  padding: 12px 3px;
  border-radius: $radius-s;
  margin-bottom: 6px;
  box-shadow: inset 0px 0px 9px darken($odd-element-background, 2%);
  position: relative;

  &:nth-of-type(even) {
    background: $even-element-background;
    box-shadow: inset 0px 0px 9px darken($even-element-background, 4%);
  }
}
