@import 'src/styles';

.container{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto auto;
  align-items: center;
  gap: 12px;
  z-index: 2;

  &.fromList{
    grid-template-columns: repeat(3, 1fr) auto;
  }

  @include tabletWidth{
    @include element-flex(column, flex-start, stretch);
  }
}

.wrapper{
  width: 100%;
  @include element-flex(row, flex-end, center);
  gap: 12px;
}