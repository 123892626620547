@import 'src/styles';

.container {
  width: 100%;
  @include element-flex(column);
  gap: 6px;
  padding: 0 0;
  border-radius: $radius-s;
}
.title {
  width: 100%;
  padding: 6px 0;
  font-size: $font-size-l;
  font-weight: 400;
  color: $font-color;
}

.emptyState {
  font-size: $font-size-m;
  color: $font-color-light;
  font-weight: 400;
  width: 100%;
  text-align: center;
  padding: 24px 0;
}
