@import 'src/styles';

.container{
  width: 90%;
  max-width: 650px;
  margin-top: 12px;
  margin-right: auto;
  position: relative;

  &.right{
    margin-right: 0;
    margin-left: auto;
    box-shadow: none;
    border: none;

    .details{
      justify-content: flex-end !important;

      @include tabletWidth{
        align-items: flex-end !important;
      }
    }
  }
}

.messageBody{
  padding: 12px 18px;
  box-shadow: 3px 3px 9px $blue-lightest-color;
  border: 1px solid $blue-lightest-color;
  background: #fff;
  border-radius: 25px;
  width: fit-content;
  margin-right: auto;
  position: relative;

  @include shadow-on-focus;

  &.right{
    color: #fff;
    background: $blue-light-color;
    margin-right: unset;
    margin-left: auto;
  }
}

.details{
  @include element-flex(row, flex-start, center);
  flex-wrap: wrap;
  column-gap: 6px;
  line-height: 16px;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  left: 0;
  padding: 0 12px;
  font-size: $font-size-xs;
  color: $font-color-light;
  width: 100%;
}