@import '../../../styles/index';

.container {
  width: 100%;
  padding: 12px;
  display: grid;
  grid-template-columns: 30px 200px 160px 40px 100px 100px 120px;
  gap: 24px;
  flex: 1;
  border-radius: $radius-s;

  &.withProducer {
    grid-template-columns: 30px 200px 120px 150px 40px 100px 100px 100px;
  }

  @include desktopWidth {
    grid-template-columns: 90px 1fr 1fr 150px 110px 30px !important;
    min-width: 80vw;
  }

  @include tabletWidth {
    @include element-flex(column);
    position: relative;
    gap: 12px;
    padding: 18px 10px;
  }

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  &.selected {
    background: rgba(0, 1, 219, 0.1);
  }
}

.radio {
  margin: auto;
  @include desktopWidth {
    grid-column: 6;
    grid-row: 1;
  }

  @include tabletWidth {
    position: absolute !important;
    right: 0;
    top: 12px;
    margin: 0;
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 6px;

  @include tabletWidth {
    order: 1;
  }

  &.productName {
    @include desktopWidth {
      grid-column: 1 / 4;
      grid-row: 1;
    }
  }

  &.producer {
    @include desktopWidth {
      grid-column: 1 / 4;
      grid-row: 3;
    }
  }

  &.availability {
    @include desktopWidth {
      grid-column: 4;
      grid-row: 1;
    }
  }

  &.price {
    @include desktopWidth {
      grid-column: 2 / 4;
      grid-row: 2;
    }
  }

  &.value {
    @include desktopWidth {
      grid-column: 4 / 6;
      grid-row: 2;
    }
  }

  &.status {
    @include desktopWidth {
      grid-column: 5;
      grid-row: 1;
    }

    @include tabletWidth {
      order: 0;
    }
  }
}

.tag {
  @include desktopWidth {
    max-width: unset !important;
    margin-left: auto;
  }
}

.text {
  font-size: $font-size-m;
  @include limit-lines(3);

  &.name{
    cursor: default;
  }
}
