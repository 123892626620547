@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.container {
  width: 100%;

  & > h2 {
    text-align: center;
  }

  .steps {
    margin-top: 40px;
    @include element-flex(row, space-between, center);

    @include smDesktopWidth {
      @include element-flex(column, unset, center);
    }

    .content {
      @include element-flex(column, center, center);
      width: 100%;
      max-width: 550px;
      margin-right: 40px;
      border-radius: $radius-s;
      aspect-ratio: 16 / 9;

      @include smDesktopWidth {
        max-width: unset;
        margin: unset;
        padding: 0 10%;
      }

      & img {
        max-width: 100%;
        display: none;
      }

      .visible {
        display: block;
      }
    }

    .menu {
      display: grid;
      grid-auto-columns: 1fr;
      row-gap: 16px;

      width: 100%;
      max-width: 46%;

      @include smDesktopWidth {
        margin-top: 5%;
        max-width: 80%;
      }
    }
  }
}
