@import 'src/styles';

.container {
  @include smDesktopWidth {
    padding: 0 10px;
  }
}

.search {
  width: auto !important;

  @include smDesktopWidth {
    width: 100% !important;
  }
}
