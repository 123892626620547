@import 'src/styles';


.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  border-radius: $radius-s;
  box-shadow: $shadow-main;
  background-color: #fff;
  position: relative;

  gap: 24px;


  @include smDesktopWidth {
    width: calc(100% - 20px);
    margin: 0 10px;
    padding: 18px 10px;


  }

  @include tabletWidth { flex-direction: column;
    display: flex;
    align-items: center;}



}

.headerTitle {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.description {
  width: 100%;
}

.tableTitle {
  @include element-flex(row, flex-start, center);
  gap: 6px;
  font-size: $font-size-xl;
  font-weight: 800;
  color: $font-color;
}

.section {
  width: 100%;
  display: flex;


}

.products {
  width: 100%;

}

.btns {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  @include tabletWidth {
    flex-direction: column;
  }
}

.header {
  width: 100%;
  display: grid;
  gap: 12px;
  padding: 6px 12px;
  border-radius: $radius-s;
  min-height: 40px;
  grid-template-columns: 30px 1fr 100px  200px;
  @include smDesktopWidth {
    display: flex;
    justify-content: flex-end;

  }
}

.isMobile {
  @include smDesktopWidth {
   display: none;


  }
}

.checkbox {
  width: auto;
}

.txt {
  margin-bottom: 12px;
}