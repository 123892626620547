.rdw-editor-wrapper
    .rdw-option-wrapper
        min-width: 20px
        height: 20px
        margin: 0 2px
        border: 1px solid transparent
        border-radius: 6px
        background: none
        transition: border-color .2s
        +m
            min-width: 16px
            height: 16px
        +dark-common
            background: lighten($n4, 30)
        &:hover
            box-shadow: none
            border-color: $n4
        &.rdw-option-active
            box-shadow: none
            border-color: $p1
            +dark-common
                border-color: $p1
                box-shadow: inset 0 0 0 1px $p1
    .rdw-history-wrapper
        margin-left: auto
    .public-DraftStyleDefault-block
        margin: 12px 16px
    .rdw-emoji-modal,
    .rdw-link-modal
        box-shadow: 0 10px 25px rgba($n8, .1)
