@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  gap: 12px;
  padding: 6px 12px;
  border-radius: $radius-s;
  min-height: 60px;
  grid-template-columns: 30px 1fr 100px 200px;

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  @include shadow-on-focus;

  @include smDesktopWidth {
    @include element-flex(column, flex-start, stretch);
    position: relative;
    padding: 18px 10px;
  }
}

.link{
  font-size: 14px;
  color: #fff;
  text-decoration: underline;
  line-height: 20px;

  &:hover{
    color: #e8e8e8;
  }
}

.tooltipWrapper{
  @include element-flex(column);

  & > h4{
    margin-bottom: 3px;
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;
}

.checkbox {
  @include element-flex(row, flex-start, center);
  gap: 12px;

  @include smDesktopWidth {
    justify-content: flex-end;
  }


}

.text {
  font-size: $font-size-s;
  font-weight: 400;

  @include smDesktopWidth {
    font-size: $font-size-m;
    text-align: left;
    min-width: 90px;
  }
}

