@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  gap: 24px;
  padding: 8px 6px;
  border-radius: $radius-s;
  min-height: 60px;
  position: relative;
  grid-template-columns: 1fr 150px 140px 150px;

  @include customBreakpoint(1400px) {
    grid-template-columns: 1fr 150px 100px 150px;
    padding: 18px 10px;
  }

  @include desktopWidth {
    grid-template-columns: repeat(4, 1fr);
  }

  @include smDesktopWidth {
    grid-template-columns: 2fr repeat(2, 110px) 150px !important;
  }

  @include tabletWidth {
    @include element-flex(column);
  }

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;

  @include desktopWidth {
    @include element-flex(column);
    gap: 6px;
  }

  &.price {
    white-space: pre-wrap;

    @include desktopWidth {
      grid-column: 1;
      grid-row: 2;

      & > p {
        white-space: nowrap;
      }
    }
  }

  &.dateWrapper {
    @include desktopWidth {
      grid-column: 4 / 5;
      grid-row: 1;
    }
  }

  &.callToAction{
    justify-content: center;

    @include desktopWidth{
      @include element-flex(row, flex-end, center);
      padding-bottom: 24px;
      grid-column: 4 / 5;
      grid-row: 1;
    }

    @include tabletWidth{
      padding-bottom: 0;
      min-height: 32px;
    }
  }

  &.quantity{
    @include desktopWidth{
      grid-column: 3 / 5;
      grid-row: 2;
    }
  }

  &.category{
    @include desktopWidth{
      grid-column: 1 / 3;
      grid-row: 2;
    }
  }
}

.text {
  @include limit-lines(3);
  word-break: break-word;
  font-size: $font-size-s;
  font-weight: 400;
  line-height: 18px;

  &.date {
    font-size: $font-size-s;
    color: $font-color-light;
    font-weight: 500;
  }

  @include smDesktopWidth {
    font-size: $font-size-m;
    text-align: left;
    min-width: 80px;
  }
}

.delete {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: $danger-color;
  margin: auto 6px auto auto;
  transition: background 0.2s;

  & > svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: darken($danger-color, 5%);
  }
}

.menu {
  @include smDesktopWidth {
    top: 12px !important;
    right: 12px !important;
  }
}

.nameWrapper {
  @include element-flex(row, flex-start, center);
  @include smDesktopWidth {
    padding-bottom: 12px;
  }

  @include desktopWidth {
    grid-column: 1 / 3;
  }

  @include smDesktopWidth {
    grid-column: 1 / 4;
  }
}

.thumbnailWrapper {
  position: relative;
}

.userIcon {
  width: 80%;
  height: 80%;
  object-fit: cover;
  filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(285deg) brightness(103%) contrast(101%);
}

.userIconWrapper {
  background-color: $blue-light-color;

  @include element-flex(row, center, center);
  overflow: hidden;
  position: absolute;
  left: -2px;
  bottom: -2px;

  border-radius: 100%;
  width: 18px;
  height: 18px;
}

.columnWrapper {
  @include element-flex(column);
}

.rowWrapper {
  @include element-flex(row);
  color: $blue-light-color;
  gap: 24px;
}

.price {
  @include smDesktopWidth {
    width: 100% !important;
  }
}
