@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.hero-1 {
  width: 100%;
  border-radius: $radius-m;
  border: $border-main;
}

.hero-2 {
  width: 34%;
  position: absolute;
  left: 0;
  bottom: -26px;

  border: $border-main;
  border-radius: $radius-m;

  box-shadow: $shadow-img;
}

.hero-3 {
  width: 30%;
  top: -55px;
  right: -75px;
  position: absolute;

  border-radius: $radius-m;

  box-shadow: $shadow-img;

  @include desktopWidth {
    right: -15px;
  }

  @include phoneWidth {
    right: -5px;
  }
}
