@import '../../../../styles';

.header {
  grid-template-columns: 2fr 1fr 1fr;
  gap: 24px;

  @include smDesktopWidth {
    display: none;
  }
}
