@import 'src/styles';

.container{
  @include element-flex(column, flex-start, stretch);
  gap: 24px;
  width: 600px;

  @include smDesktopWidth{
    width: 100%;
  }
}

.code{
  @include element-flex(row, center, center);
  border-radius: $radius-m;
  border: 2px solid $border-color-light;
  padding: 12px;
  position: relative;
}

.clear{
  position: absolute;
  right: 12px;
  top: 12px;
}
