@import 'src/styles';

.container {
  min-width: 300px;
  @include element-flex(column, center);

  @include tabletWidth {
    min-width: calc(100vw - 20px);
    width: 100%;
  }
}

.progress {
  padding: 0;
  height: 40px !important;
  gap: 6px;

  & > p {
    font-size: 14px;
  }
}
