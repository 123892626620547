@import '../index';

.pageHeader {
  @include element-flex(row, space-between, center);
  width: 100%;
  margin-bottom: 24px;
  gap: 24px;
  min-height: 45px;
  flex-wrap: wrap;
  z-index: 3;

  @include smDesktopWidth {
    padding: 20px 10px;
    margin-bottom: 0;
    flex-wrap: wrap;
    position: relative;
  }
}

.pageHeaderMenu{
  position: static !important;

  @include smDesktopWidth{
    position: absolute !important;
    z-index: 2;
  }
}

.pageTitle {
  font-size: $font-size-xxl;
  line-height: 36px;
  font-weight: 800;
  word-break: break-word;
  @include limit-lines(2);

  &.blur{
    filter: blur(6px);
  }

  @include tabletWidth {
    font-size: 24px;
    line-height: 30px;
  }
}

.pageSubtitle {
  font-size: $font-size-m;
  min-width: fit-content;
  line-height: normal;
  font-weight: 400;
  text-wrap: none;
  min-height: 19px;

  &.maxWidth{
    @include trimText;
    max-width: 210px;
    min-width: unset;
    cursor: default;
  }
}

.titleWrapper {
  @include element-flex(row, flex-start, baseline);
  gap: 1ch;
  min-width: 200px;

  @include smDesktopWidth {
    min-height: 30px;
  }

  &.center {
    align-items: center;

    @include smDesktopWidth {
      align-items: start;
    }
  }

  &.wrapOnMobile {
    @include smDesktopWidth {
      @include element-flex(row, flex-start, center);
    }
  }

  @include smDesktopWidth {
    @include element-flex(column);
    gap: 6px;
    width: 100%;
  }

  @include tabletWidth {
    gap: 12px;
  }
}

.headerWrapper {
  @include element-flex(row, flex-end, center);
  gap: 24px;
  flex: 1;

  &.valuationsHeader {
    padding-right: 15px;

    @include desktopWidth {
      @include element-flex(row, flex-end);
      flex-wrap: wrap;
    }

    @include tabletWidth {
      @include element-flex(row);
    }
  }

  @include smDesktopWidth {
    flex-wrap: wrap;
    @include element-flex(column);
  }
}

.tableWrapper {
  @include element-flex(column, flex-start, stretch);
  width: 100%;
  padding: 12px;
  border-radius: $radius-m;
  background: #ffffff;

  @include smDesktopWidth {
    padding: 12px 6px;
  }
}

.tableHeader {
  padding: 18px 12px;
  border-bottom: 1px solid rgba(137, 169, 212, 0.24);
  display: grid;

  & > p {
    font-weight: 700;
  }
}

.tableCategory {
  @include element-flex(column, flex-start, stretch);
  width: 100%;
  margin-top: 12px;

  @include smDesktopWidth {
    margin-top: 0;
  }
}

.tableCategoryTitle {
  width: 100%;
  font-size: $font-size-m;
  font-weight: 800;
  min-height: 50px;
  line-height: 24px;
  padding: 18px 12px;

  &.large {
    font-size: $font-size-l;
    padding: 12px;
    min-height: unset;
  }

  &.noPadding {
    padding: 10px 0 12px;
  }

  @include smDesktopWidth {
    @include limit-lines(2);
    font-size: $font-size-l;
  }
}

.counter{
  font-size: $font-size-xs;
  color: $font-color-light;
}

.prodId{
  position: absolute;
  font-size: 12px;
  color: #fff;
  margin-top: -30px;
  margin-left: 4px;
  mix-blend-mode: difference;
  text-shadow: 0 0 2px #000;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}

.defaultThumbnail:hover + .prodId{
  opacity: 1;
}

.defaultThumbnail {
  width: 48px;
  height: 48px;
  border-radius: $radius-s;
  object-fit: cover;
  box-shadow: $shadow-main;

  max-width: 48px;
  max-height: 48px;

  &.withPointer {
    cursor: pointer;
  }

  &.medium {
    width: 60px;
    height: 60px;
    max-width: 60px;
    max-height: 60px;
  }

  &.tiny{
    width: 36px;
    height: 36px;
    max-width: 36px;
    max-height: 36px;
  }

  @include smDesktopWidth {
    width: 69px;
    height: 69px;
    max-width: 69px;
    max-height: 69px;

    &.tiny{
      width: 36px;
      height: 36px;
      max-width: 36px;
      max-height: 36px;
    }
  }
}

.originalThumbnail {
  width: 450px;
  height: 450px;
  border-radius: $radius-l;
  object-fit: contain;

  @include tabletWidth {
    width: 60vw;
    height: 60vw;
  }

  @include phoneWidth {
    width: 80vw;
    height: 80vw;
  }
}

.originalThumbnailBox {
  width: 100%;
  @include element-flex(column, center, center);
}

.listContent {
  @include element-flex(column);
  width: 100%;
  gap: 16px;

  @include smDesktopWidth {
    padding: 0 10px;
  }
}

.tooltip {
  z-index: 1000 !important;
}

.cardTitle {
  font-size: $font-size-xl;
  font-weight: 700;
}

.defaultWrapper {
  @include element-flex(column);
  width: 100%;
  gap: 12px;
}

.buttonWrapper {
  @include element-flex(row, flex-end);
  flex-wrap: wrap;
  gap: 24px;

  &.reverse {
    flex-direction: row-reverse;
  }

  @include smDesktopWidth {
    justify-content: flex-start;
  }
}

.thumbnailContentWrapper {
  display: flex;
  gap: 12px;
  position: relative;

  @include smDesktopWidth {
    flex-wrap: wrap;
    gap: 6px;
  }
}

.popoverWrapper{
  @include element-flex(row, flex-end, center);
  gap: 6px;
  flex-wrap: wrap;
}

.thumbnailContentLabel {
  color: $font-color-light;
  line-height: 26px;

  @include smDesktopWidth {
    position: absolute;
    top: -3px;
    left: 0;
    transform: translateY(-100%);
  }
}

.thumbnailTag {
  max-width: 130px !important;
}

.control {
  width: 30px;
  height: 30px;
  position: absolute;
  background: url('../../assets/icons/arrow-down-solid.svg') no-repeat center;
  background-size: 50%;
  top: 70px;
  transition: opacity 0.2s, background-color 0.2s;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: #4849ff10;
  }

  &.prev {
    left: -30px;
    transform: rotate(90deg);
  }

  &.next {
    right: -30px;
    transform: rotate(-90deg);
  }

  &.disabled {
    opacity: 0;
  }

  @include smDesktopWidth {
    display: none;
  }
}

.companyLogo {
  width: 35px;
  height: 35px;
  object-fit: contain;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  box-shadow: $shadow-main;

  &.large {
    width: 50px;
    height: 50px;
  }

  &:hover path {
    fill: #000000;
  }
}

.publicContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;

  @include smDesktopWidth {
    padding: 36px 10px;
  }
}
