@import 'src/styles';

.container{
  width: 100%;
  @include element-flex(column);
  gap: 12px;
  max-width: 400px;
}

.button{
  margin-top: 12px;
}