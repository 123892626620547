@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.container {
  max-width: 700px;
  min-height: calc(100vh - $nav-height);
  margin: 0 auto;
  margin-top: $spacing-11;
  background-color: $background-default;

  display: flex;
  flex-direction: column;
  gap: $spacing-9;

  @include tabletWidth {
    margin-top: $spacing-8;
  }
  @include phoneWidth {
    margin-top: $spacing-6;
  }
}

.repeated:not(:last-of-type) {
  margin-bottom: $spacing-2;
}

.img {
  display: block;
  margin: 0 auto;
  border-radius: $radius-s;
  margin-top: $spacing-5;
}
