@import 'src/styles';

.container{
  @include element-flex(row, stretch, center);
  padding: 0 12px 0 6px;
  gap: 6px;
  border-radius: $radius-s;
  width: 100%;

  &:nth-of-type(odd){
    background: $odd-row-background;
  }
}

.name{
  flex: 1;
}

.wrapper{
  @include element-flex(row);
  padding: 6px 0;
}