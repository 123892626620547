@import 'src/styles';

.container {
  @include element-flex(column, flex-start, stretch);
  width: 100%;
  max-width: 610px;
  gap: 12px;
}

.button{
  margin-top: 24px;
}
