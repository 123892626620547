@import 'src/styles';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  width: 100%;
  margin-bottom: 20px;
  z-index: 2;

  @include desktopWidth{
    grid-template-columns: 1fr 1fr;
  }

  @include smDesktopWidth {
    padding: 18px 0;
    @include element-flex(column-reverse, flex-start, stretch);
  }
}

.select {
  width: 100%;

  @include smDesktopWidth {
    max-width: unset !important;
  }
}

.search {

}
