@import 'src/styles';

.wrapper {
  @include element-flex(column);
  width: 100%;
  gap: 24px;
  background: #fff;
  padding: 12px;
  border-radius: $radius-m;
  box-shadow: $shadow-main;

  @include tabletWidth {
    padding: 18px 10px;
  }
}

.sectionTop {
  display: grid;
  grid-template-columns: 1fr 160px;
  align-items: center;
  padding: 12px 12px 0;
  gap: 24px;
  width: 100%;
}


.headerTop {
  @include element-flex(column);
  gap: 3px;
}

.title {
  @include tabletWidth {
    padding: 0;
  }
}

.container {
  height: auto;
  position: relative;
  transition: height 0.3s cubic-bezier(0.71, 0.13, 0.46, 1.05);
  padding: 0;
  border-radius: $radius-m;
  background: #fff;

  &.close {
    height: 400px;
  }
}

.sectionTitle{
  padding-bottom: 3px !important;
}

.description{
  padding-bottom: 12px;
}

.tag {
  width: auto;
  max-width: fit-content;

  @include smDesktopWidth {
    max-width: unset !important;
  }
}

.showMore {
  width: 100%;
  height: 200px;
  display: grid;
  place-items: center;
  text-align: center;
  font-size: $font-size-s;
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  background: linear-gradient(to bottom, transparent, #f2f5f9 70%);
  color: $blue-color;

  &:hover {
    text-decoration: underline;
  }
}

.header {
  grid-template-columns: 20px 1fr 130px 60px 90px 90px 150px;
  gap: 12px;

  &.withMenu {
    padding-right: 50px;
  }

  @include lgDesktopWidth {
    @include element-flex(row, flex-end, center);
    & > p {
      display: none;
    }
    border: none;
  }

  @include phoneWidth {
    padding: 18px 10px;
  }
}

.buttonWrapper {
  @include element-flex(row, center);
  width: 100%;
  margin-top: 24px;
  gap: 24px;

  & > button {
    min-width: 250px;
  }

  @include tabletWidth {
    @include element-flex(column, center, stretch);
  }
}

.supplierDetails{
  width: 100%;
}

.productCheckboxWrapper {
  display: grid;
  grid-template-columns: 1fr 24px;
  gap: 6px;
  width: 100%;
  max-width: 150px;
  align-items: center;


  &.flex {
    @include element-flex(row, flex-start, center);
    width: 100%;
  }

  @include lgDesktopWidth {
    max-width: unset;
  }
}

.checkboxWrapper {
  @extend .productCheckboxWrapper;
  width: 150px;
  @include element-flex(row, flex-end, center);
}

.checkboxPlaceholder {
  width: 90px;
}

.comment {
  font-size: $font-size-m;
  padding: 0 12px;
  word-break: break-word;

  @include tabletWidth {
    padding: 0;
  }
}

.checkboxText {
  font-weight: 700;
}

.confirmTitle {
  margin-top: 12px;
  text-align: center
}
