@import src/styles/helpers
@import src/styles

.actions
    position: relative
    display: inline-block
    &.active
        z-index: 3
        .head
            svg
                fill: $n5
            +dark
                background: $n6
                svg
                    fill: $n1
        .body
            visibility: visible
            opacity: 1
            transform: translateY(0)
    &.small
        .head
            width: 32px
            height: 32px
            svg
                width: 20px
                height: 20px
        .body
            right: -8px
            &:before
                right: 14px
    &.up
        .body
            top: auto
            bottom: calc(100% + 12px)
            box-shadow: 0 20px 32px -6px rgba(0, 0, 0, 0.08), 0 0 7px -4px rgba(0, 0, 0, 0.05), 0 16px 24px -4px rgba(0, 0, 0, 0.1)
            &:before
                top: 100%
                bottom: auto
                transform: rotate(180deg)

.head
    position: relative
    width: 40px
    height: 40px
    border-radius: 50%
    background: $n
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    border: 1px solid rgba(0, 0, 0, 0.23)
    user-select: none
    transition: all .2s
    svg
        transition: fill .2s
        fill: $n4
    &:hover
        svg
            fill: $p1
    +dark
        background: $n8
        &:hover
            svg
                fill: $p1

    &.disabled
        opacity: 0.6
        cursor: not-allowed

    &:hover
        svg
          fill: $n4

    &:focus
      border: 1px solid #4849FF
      box-shadow: 0 0 0 1px #4849FF



.body
    position: absolute
    top: calc(100% + 12px)
    right: -12px
    width: 264px
    padding: 6px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0 40px 64px -12px rgba(0, 0, 0, 0.08), 0 0 14px -4px rgba(0, 0, 0, 0.05), 0 32px 48px -8px rgba(0, 0, 0, 0.1)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(3px)
    transition: all .2s
    +dark
        background: $n8
        border-color: $n6
    &.displayOver
        position: fixed
        transform: translate(-100%, 3px) !important
        transition: transform .2s, opacity .2s !important

    &:before
        content: ""
        position: absolute
        right: 20px
        bottom: 100%
        width: 20px
        height: 10px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        +dark
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23272B30'/%3E%3C/svg%3E")

.option
    display: grid
    grid-template-columns: 36px 1fr
    align-items: center
    width: 100%
    padding: 12px
    border-radius: 6px
    border: 1px solid transparent

    &.red
      color: $danger-color
      svg > path,
      svg > g > path
        fill: $danger-color
        transition: all .2s

      &:hover
        color: darken($danger-color, 10%) !important

        svg > path,
        svg > g > path
          fill: darken($danger-color, 10%) !important

    &.blue
      color: $blue-light-color
      svg > path,
      svg > g > path
        fill: $blue-light-color
        transition: all .2s

      &:hover
        color: darken($blue-light-color, 10%) !important

        svg > path,
        svg > g > path
          fill: darken($blue-light-color, 10%) !important

    +base1-s
    color: $n4
    transition: all .2s
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    svg
        margin-right: 12px
        fill: $n4
        transition: fill .2s

    span
        text-align: left

    &:focus
      border: 1px solid #4849FF
      box-shadow: 0 0 0 1px #4849FF

    &:hover,
    &.selectioned
        background: $n3
        color: $n7
        svg
            fill: $n7
        +dark
            background: transparent
            color: $n1
            svg
                fill: $n1

    &.disabled,
    &.disabled:hover
        opacity: 0.7
        cursor: not-allowed
        background: #fff
        color: $n4

        svg
          fill: $n4

        &.blue
          color: transparentize($blue-light-color, 0.2)
          svg > path,
          svg > g > path
            fill: transparentize($blue-light-color, 0.2)

          &:hover
            color: transparentize($blue-light-color, 0.3) !important

            svg > path,
            svg > g > path
              fill: transparentize($blue-light-color, 0.3) !important

        &.red
          color: transparentize($danger-color, 0.2)
          svg > path,
          svg > g > path
            fill: transparentize($danger-color, 0.2)

          &:hover
            color: transparentize($danger-color, 0.3) !important

            svg > path,
            svg > g > path
              fill: transparentize($danger-color, 0.3) !important