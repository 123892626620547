@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.divider {
  height: 1px;
  border: none;
  background-color: $gray-light-color;

  &--short {
    margin: 0 calc(50% - 564px);
  }
}
