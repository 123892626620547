@import 'src/styles';

.container{
  width: 100%;
}

.card {
  @include element-flex(column, center,center);
  width: 100%;
  height: 100%;
}

.emptyCard{
  height: 449px;
  width: 100%;
  @include element-flex(column, center, center);
}
