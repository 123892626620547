@import 'src/styles';

.header {
  grid-template-columns: 1fr 130px 130px repeat(2, 150px) 40px;
  gap: 24px;

  @include desktopWidth {
    display: none;
  }
}
