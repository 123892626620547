@import 'src/styles';

.container {
  width: 100%;
  position: relative;
  padding: 3px;
  border-radius: $radius-s;

  &:nth-of-type(odd) {
    background: $odd-row-background !important;
  }
}
.menu {
  top: 0 !important;
  right: 0 !important;
}

.opacity {
  opacity: 0.5 !important;
}
