@import 'src/styles';

.container {
  width: 100%;
  @include element-flex(column, flex-start, stretch);
  gap: 24px;
}

.wrapper {
  @include element-flex(column, flex-start, stretch);
  gap: 18px;
}

.menu{
  position: static;
}