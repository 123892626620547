@import 'src/styles';

.container {
  @include element-flex(column, center, center);
  gap: 12px;
  padding: 36px 0;

  &.tiny{
    padding: 0;
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 6px;
    max-width: fit-content;
    margin: auto;

    & > h3{
      align-self: end;
    }

    .text {
      text-align: left;
      align-self: start;
      grid-row: 2;
      grid-column: 2;

      @include smDesktopWidth {
        font-size: $font-size-m;
      }
    }
  }
}

.img {
  width: 200px;
  height: 180px;

  &.tiny{
    width: 100px;
    height: 100px;
    grid-column: 1;
    grid-row: 1 / 3;
  }
}

.text {
  text-align: center;

  @include smDesktopWidth {
    font-size: $font-size-m;
  }
}
