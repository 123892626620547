@import 'src/styles';

.container {
  width: 100%;
  opacity: 1;
  overflow: visible;
  height: fit-content;
  max-height: fit-content;
  padding: 0;
  position: relative;
  z-index: 5;
  transition: height 0.2s;

  &.isHidden {
    @include smDesktopWidth {
      height: 0;
      overflow: hidden;
    }
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding: 11px 0;

  &.single{
    grid-template-columns: 100%;
  }

  @include smDesktopWidth {
    @include element-flex(column, flex-start, stretch);
    padding: 0 0 12px;
    gap: 12px;
  }
}
