@import 'src/styles';

.wrapper {
  @include element-flex(column, center, center);
  width: 100%;
  margin: 48px 0 24px;
  opacity: 0.7;
  height: 440px;
}

.container{
  width: 100%;
  min-height: 440px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  flex-grow: 5;
  gap: 12px;

  @include smDesktopWidth{
    @include element-flex(column);
  }
}
