@import '../../../../styles';

.container {
  width: 600px;
  height: 70vh;
  @include element-flex(column, flex-start, stretch);
  gap: 24px;
  padding: 24px 0;

  @include smDesktopWidth {
    width: 100%;
    height: auto;
  }
}

.wrapper {
  @include element-flex(column, flex-start, stretch);
  width: 100%;
  gap: 72px;
  z-index: 3;
}

.row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  row-gap: 12px;
  place-items: start start;

  @include phoneWidth {
    @include element-flex(column, flex-start, stretch);
    width: 100%;
  }
}

.qtySelector {
  width: 100% !important;
}

.button {
  margin: 0 auto;
  width: 250px;
  z-index: 0;
}

.selectWrapper {
  width: 100%;
  display: block;
  gap: 12px;
}

.selectSmall {
  display: inline-block;
  width: 100%;
}

.label {
  display: inline-block;
  white-space: nowrap;
  padding: 8px 10px;
  width: 90px;
}

.inputBlock {
  display: flex;
}
