@import '../../../../styles';

.container {
  @include element-flex(column, flex-start, stretch);
  width: 100%;
  gap: 12px;

  @include smDesktopWidth {
    max-width: 100vw;
  }
}

.button {
  @include element-flex(column, flex-start, stretch);
  width: 100%;
  margin-top: 36px;
}

.pricesWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @include smDesktopWidth {
    @include element-flex(column);
  }
}

.wrapper {
  @include element-flex(column);
  gap: 6px;
  margin-top: 12px;
}
