@import 'src/styles';

.container {
  @include element-flex(column, flex-start, stretch);
  gap: 24px;
  width: 100%;

  @include smDesktopWidth {
    padding: 0 10px 20px;
  }
}

.header {
  display: grid;
  gap: 24px;
  grid-template-columns: 2fr 1fr 1fr;
  width: 100%;

  @include smDesktopWidth {
    @include element-flex(column, flex-start, stretch);
    margin-top: 0;
  }
}

.headerExtended {
  grid-template-columns: 2fr 1fr 1fr 1fr;

  @include desktopWidth {
    grid-template-columns: 1fr 1fr;
  }
}

.search {
  width: 100% !important;
}

.optionsHeader {
  justify-content: flex-start;
}

.topHeader {
  margin-bottom: 12px !important;
}

.switchCenter {
  @include element-flex(row, flex-end, center);
  gap: 36px;
  height: 45px;
}
