@import 'src/styles';

.wrapper {
  @include element-flex(column, flex-end, flex-end);
  gap: 6px;
}

.row{
  @include element-flex(row, flex-start, center);
  gap: 12px;
}