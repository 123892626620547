@import '../../../../../../styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 150px 1fr 150px 50px;
  place-items: start start;
  padding: 6px;
  gap: 12px;
  border-radius: $radius-s;
  background: #ffffff;
  position: relative;

  @include desktopWidth {
    grid-template-columns: 1fr 120px 40px;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 12px;
    column-gap: 24px;
  }

  @include tabletWidth {
    @include element-flex(column, flex-start, flex-start);
    gap: 12px;
    padding: 12px 0 0 0 !important;
  }

  &:nth-of-type(even) {
    background: $odd-row-background;
  }

  & > p {
    word-break: break-word;
  }

  &.form {
    margin-top: 10px;
    background: #ffffff;

    @include desktopWidth {
      border-top: 1px solid $border-color-light;
      border-radius: 0;
      padding: 24px 6px;

      .button {
        margin-top: 15px;
      }
    }
  }

  &.center {
    place-items: center start;
    padding: 8px 6px 8px 12px;

    @include tabletWidth {
      padding: 8px 12px;
      place-items: start start;
    }
  }

  &.added {
    @include tabletWidth {
      padding: 8px 12px !important;
    }
  }
}

.quantity {
  width: 100%;

  @include tabletWidth {
    width: 100% !important;
  }
}

.button {
  @include desktopWidth {
    grid-row: 1;
    grid-column: 3;
    padding-top: 20px;
  }

  @include tabletWidth {
    display: none;
  }
}

.mobileButton {
  display: none;

  @include tabletWidth {
    display: initial;
    width: 100%;
    max-width: 500px;
    margin: 12px auto 0;
  }
}
