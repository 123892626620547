@import 'src/styles';

.container {
  @include element-flex(column, flex-end, flex-end);
  gap: 6px;

  @include smDesktopWidth {
    @include element-flex(column-reverse);
    gap: 12px;
  }
}
.small {
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;
}

.tag {
  max-width: unset;
}
