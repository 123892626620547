@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 20px 1fr 130px 60px 90px 90px 150px;
  gap: 12px;
  padding: 6px 12px;
  border-radius: $radius-s;
  min-height: 60px;
  position: relative;

  background: #fff;

  &.cancelled {
    background: rgba(245, 105, 105, 0.1);
  }

  &.cancelled:nth-of-type(odd) {
    background: rgba(199, 86, 86, 0.15);
  }

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  &.replacement {
    background: $blue-lightest-color;

    &:nth-of-type(odd) {
      background: darken($blue-lightest-color, 3%);
    }
  }

  &.offer {
    background: $yellow-light-color;

    &:nth-of-type(odd) {
      background: darken($yellow-light-color, 7%);
    }
  }

  &.withMenu {
    padding-right: 50px;
  }

  &.hideCommentColumn {
    grid-template-columns: 20px 1fr 130px 60px 90px 90px 150px;
  }

  @include lgDesktopWidth {
    grid-template-columns: repeat(4, 1fr) 200px;
    row-gap: 24px;
    padding: 18px 12px;
  }

  @include smDesktopWidth {
    grid-template-columns: 2fr repeat(3, 110px);
  }

  @include tabletWidth {
    grid-template-columns: 2fr repeat(3, 110px);
    padding: 18px 10px;
  }

  @include phoneWidth {
    @include element-flex(column);
  }
}
.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;

  @include phoneWidth {
    order: 1;
  }

  &.yellowBg {
    background-color: darken($yellow-light-color, 2%);
    border-radius: $radius-s;
    padding: 0 3px;
    margin: 0 -3px 0 -3px;
  }

  @include lgDesktopWidth {
    @include element-flex(column);
    gap: 6px;
  }

  @include phoneWidth {
    order: 1;
  }

  &.withChildren {
    justify-content: flex-end;
    @include lgDesktopWidth {
      grid-row: 1;
      grid-column: 5;
    }

    @include smDesktopWidth {
      grid-column: 4 / 6;
    }

    @include phoneWidth {
      order: 0;
      margin: 0 0 -18px auto;
    }
  }

  &.productName {
    @include lgDesktopWidth {
      grid-column: 1 / 4;
      @include element-flex(row, flex-start, center);

      &.hideStatusColumn {
        grid-column: 1 / 6;
      }
    }

    @include tabletWidth {
      grid-column: 1 / 4;
    }
  }

  &.index{
    @include lgDesktopWidth {
      position: absolute;
      top: -12px;
      left: 12px;
    }
  }

  &.qty {
    @include lgDesktopWidth {
      grid-row: 2;
      grid-column: 3;
    }
  }

  &.forOne {
    @include lgDesktopWidth {
      grid-row: 2;
      grid-column: 4;
    }
  }

  &.sum {
    @include lgDesktopWidth {
      grid-row: 2;
      grid-column: 5;
    }
  }

  &.producer {
    @include lgDesktopWidth {
      grid-row: 2;
      grid-column: 1 / 3;
    }
    @include tabletWidth {
      grid-column: 1 / 3;
    }
  }
}

.checkboxWrapper {
  @include element-flex(row, flex-end, center);
  width: 100%;
  gap: 12px;

  @include lgDesktopWidth {
    grid-column: 4;
    grid-row: 1;
  }

  @include tabletWidth {
    grid-column: 1 / 3;
    grid-row: 1;
    justify-content: flex-start;
  }

  @include phoneWidth {
    order: 0;
  }
}

.tag {
  max-width: unset !important;
}

.checkbox {
  width: auto !important;
}

.text {
  font-size: $font-size-s;
  font-weight: 400;
  line-height: 18px;

  &.trim {
    @include limit-lines(3);
    cursor: pointer;
    line-height: normal;
  }
}

.container.offer,
.container.replacement {
  .text.trim {
    @include limit-lines(2);
  }
}

.priceWrapper {
  @include element-flex(row);
  gap: 1ch;
  width: 100%;
}

.price {
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: 600;
}

.prevPrice {
  text-decoration: line-through;
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: 600;
  color: #6a798b;
}

.available {
  font-size: $font-size-s;
  font-weight: $font-regular;
  line-height: 24px;
  color: #6a798b;
}

.offerBox {
  @include element-flex(column);
  gap: 16px;
  padding: 12px 0;
  width: 100%;
  z-index: 2;
}

.info {
  @include element-flex(row, flex-start, center);
  gap: 2px;

  &.replacement {
    color: $blue-color;
  }

  &.offer {
    color: $font-color-light;
  }
}

.menu {
  top: 14px;
  right: 6px;
}
