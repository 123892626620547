@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.card {
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #e9eaf0;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(8, 15, 52, 0.04);
}

.form {
  padding: 57px 40px 54px;
}

.inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.input {
  flex: 1 1 40%;
  min-width: 200px;
}

.textareaWrapper {
  flex-basis: 100%;
  min-width: 200px;
}

.textareaLabel {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.textarea {
  padding: 6px 8px;
  height: 5em;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  border-radius: $radius-s;
  transition: all 0.2s;
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-shadow: none;
  resize: none;

  &.disabled {
    background: $disabled-input-background;
  }

  &:hover {
    border: 1px solid $input-hover-color;
  }

  &.error {
    border: 1px solid $danger-color;
  }

  &::placeholder {
    color: #9a9fa5;
  }

  &:focus {
    border: 1px solid #4849ff;
    box-shadow: 0 0 0 1px #4849ff;
  }

  &:focus.error {
    border: 1px solid $danger-color;
    box-shadow: 0 0 0 1px $danger-color;
  }
}

.info {
  margin-top: 8px;
  color: #808191;
}

.button {
  margin-top: 20px;
  margin-left: auto;
}
