@import 'src/styles';

.supplierColor{
  display: flex;
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  border-radius: 50%;
  border: 1px solid $border-color-lighter;
}