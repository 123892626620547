@import '../../../../styles/index';

.container {
  width: 100%;
}

.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @include desktopWidth {
    @include element-flex(column, flex-start, stretch);
  }
}

.addNew {
  @include element-flex(row, center, center);
  gap: 12px;
  width: 100%;
  padding-right: 18px;
  border-radius: $radius-s;
  font-size: $font-size-l;
  min-height: 100px;
  background: $blue-lightest-color;

  &:hover {
    text-decoration: underline;
  }
}
