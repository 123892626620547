@import 'src/styles';

.box{
  width: 100%;
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 0;
  border-radius: $radius-s;

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  &.withRefPrice{
    grid-template-columns: 400px 120px 1fr;
  }

  @include tabletWidth{
    @include element-flex(column);
  }
}

.container {
  width: 400px;
  min-width: 400px;
  gap: 12px;
  padding: 6px;
  min-height: 150px;
  @include element-flex(column, center, flex-start);

  @include tabletWidth{
    min-width: unset;
    width: 100%;
  }
}

.savings {
  @include element-flex(row, flex-start, center);
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;
}

.text {
  font-size: $font-size-s;
  font-weight: 400;
  @include limit-lines(3);

  & > p{
    color: $blue-color;
  }
}

.priceWrapper {
  @include element-flex(row, flex-start, center);
  gap: 1ch;
  width: 100%;
}

.price {
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: 600;
}

.prevPrice {
  text-decoration: line-through;
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: 600;
  color: #6a798b;
}

.available {
  font-size: $font-size-s;
  font-weight: $font-regular;
  line-height: 24px;
  color: #6a798b;
}

.offerBox {
  @include element-flex(column);
  gap: 16px;
  padding: 12px 0;
  width: 100%;
  z-index: 2;
}

.row {
  width: 100%;
  @include element-flex(row, flex-start, center);
  gap: 12px;
  min-height: 27px;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 6px;
  row-gap: 12px;
}

.icon {
  width: 24px;
  height: 20px;
  padding-right: 6px;
}

.details {
  @extend .text;
  @include element-flex(row, flex-start, center);
  gap: 6px;
}

.tag {
  max-width: unset !important;
  width: fit-content;
  animation: fadeIn 0.2s forwards;
}

@keyframes fadeIn {
  @include keyframe-fadeIn;
}

.priceWrapper{
  max-width: 190px;
  min-width: 190px;
  width: 190px;

  &.ref{
    width: calc(100% - 6px);
    margin-right: 6px;
    max-width: unset;
    min-width: unset;
    height: 100%;
  }
}

.prices{
  width: 100%;
  @include element-flex(row, flex-start, center);
  max-width: 1000px;
  gap: 6px;
  flex-wrap: wrap;
}

.emptyStateWrapper{
  @include element-flex(row, center, center);
  width: 190px;
}

.tag{
  width: 100%;
  text-align: center;
}

.detailsValue{
  @include lgDesktopWidth{
    @include trimText;
    max-width: 120px
  };
}