@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.grid {
  display: grid;
  gap: 24px;
  margin-top: 71px;

  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);

  @include desktopWidth {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  @include phoneWidth {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, max-content);
    margin-top: 30px;
  }
}

.box {
  width: 100%;
  border-radius: $radius-m;
  background-color: $background-gray;
  margin: 0 auto;

  padding: 26px 24px;

  @include element-flex(column);

  & > img {
    margin-bottom: 12px;
  }

  & > h3 {
    font-weight: $font-extraBold;
    line-height: $line-height-up-2;
    margin-bottom: 10px;
  }

  & > p {
    //font-weight: $font-semiBold;
    line-height: 24px;
  }
}
