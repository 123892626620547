@import 'src/styles';

.section {
  padding: 24px;
  border-radius: $radius-s;
  background: #ffffff;
  margin-top: 24px;
}

.textArea {
  width: 100%;
  padding: 12px;
  border-radius: $radius-s;
  border: 1px solid $border-color-light;
  min-height: 80px;
  font-size: $font-size-s;
  resize: vertical;
  transition: all 0.2s;

  &:focus {
    border: 1px solid #4849ff;
    box-shadow: 0 0 0 1px #4849ff;
  }
}

.title {
  font-size: $font-size-l;
  font-weight: bold;
  margin-bottom: 12px;
}
