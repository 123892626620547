@import 'src/styles';

.container{
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 24px;
  width: 800px;
  max-height: 80dvh;
  overflow: auto;

  @include smDesktopWidth{
    width: 100%;
  }

  @include tabletWidth{
    grid-template-columns: 100%;
    gap: 36px;
  }
}

.imageWrapper{
  @include element-flex(column, flex-start, stretch);
  width: fit-content;

  @include tabletWidth{
    margin: 0 auto;
  }
}

.image{
  box-shadow: $shadow-main;
  object-fit: contain;
  border: 2px solid $border-color-light;
  border-radius: $radius-m;
  width: 300px;
  height: 300px;
}

.details{
  @include element-flex(column, center);
  gap: 12px;
}

.box{
  @include element-flex(column);
  font-size: 15px;
}

.value{
  font-size: 15px;
}

.row{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: stretch;
  gap: 24px;
  width: 100%;
}

.label{
  color: $blue-color;
  font-size: $font-size-s;
  width: fit-content;
  white-space: nowrap;
}

.producer{
  width: 100%;
  @include element-flex(row, flex-start, baseline);
  color: $font-color-light;
  font-size: 15px;
  gap: 0.5ch;
}

.titleWrapper{
  width: 100%;
  @include element-flex(column);
  padding-bottom: 12px;
}

.metaData{
  @include element-flex(row, flex-start, center);
  gap: 12px;
}

.topRow{
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 12px;
}

.wrapper{
  @include element-flex(column);
  gap: 24px;
  grid-column: 1 / 3;

  @include tabletWidth{
    grid-column: 1;
    margin-left: -12px;
  }
}

.titleLoader{
  width: 100%;
  max-height: 31px;
}

.loader{
  width: 100%;
  max-height: 20px;
}

.skeletonBox {
  @extend .box;
  max-height: 42px;
}

.imageWrapper{
  position: relative;
}

.zoomIcon{
  position: absolute;
  right: 6px;
  bottom: 6px;
  background: #ffffff8f;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  place-items: center;
  display: grid;
  pointer-events: none;
}

