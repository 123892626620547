@import src/styles/helpers
@import src/styles

.sidebar
  position: fixed
  top: 0
  left: 0
  bottom: 0
  display: flex
  flex-direction: column
  width: 260px
  padding: 0 12px
  background: $n1
  overflow: auto
  max-height: 100vh
  -webkit-overflow-scrolling: touch
  -ms-overflow-style: none
  scrollbar-width: none

  @include printView
    display: none

  &.mobile
    transform: translateX(-100%)
    transition: transform .2s

  &.isMobileOpen
    transform: none

  &::-webkit-scrollbar
    display: none

  &.isAdminView
    top: 40px !important

  +dark
    background: $n7

  &.isClose
    width: 100px

    .companySelect,
    .logo
      overflow: hidden
      width: 58px

    .logoSvg
      & > g:last-child
        opacity: 0
        display: none

  &.active
    +d
      align-items: stretch
      width: 300px
      box-shadow: 4px 0 32px rgba($n8, .05)
      .item,
      .link
        width: 100%
        font-size: 14px

        svg
          margin-right: 12px
      .counter
        display: block
      .toggle
        position: absolute
        top: 16px
        right: 16px

        svg
          fill: $n7

          &:first-child
            display: none

          &:nth-child(2)
            display: inline-block
    +m
      width: 100%

.logo
  display: block
  width: 115px
  margin-left: 19px

  &.medicover
    width: 10px
    margin-left: 6px

    .logoSvg
      width: 170px
      height: 60px

  svg,
  img
    width: 100%

  .logoSvg
    width: 115px
    height: 37px

  & ~ g
    transition: opacity .2s

.companySelect
  width: 100%
  margin-bottom: 24px

.menu
  display: flex
  flex-direction: column
  margin-bottom: auto

.item
  margin-bottom: 8px

.dropdown
  margin-bottom: 11px
  padding-bottom: 11px

  &:not(:last-of-type)
    border-bottom: 1px solid rgba(137, 169, 212, 0.24)

.item,
.link
  display: flex
  align-items: center
  width: 100%
  height: 48px
  padding: 0 12px
  border-radius: 12px
  font-size: 14px
  font-weight: 600
  white-space: nowrap
  color: $n4
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  transition: background .2s, box-shadow .2s, color .2s
  +d
    width: 48px
    font-size: 0
  +m
    width: 100%
    font-size: 14px

  svg
    margin-right: 12px
    fill: $n4
    transition: fill .2s
    +d
      margin-right: 0
    +m
      margin-right: 12px

  &:hover,
  &.active
    color: $n7

    svg
      fill: $n7

    +dark
      color: $n1
      svg
        fill: $n1

  &.active
    background: $n3
    box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.05), inset 0 1px 1px #FFFFFF
    +dark
      background: $n6
      box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.4), inset 0 1px 1px rgba(255, 255, 255, 0.11)

.toggle
  display: none
  width: 48px
  height: 48px
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  +d
    display: inline-block
  +m
    display: none

  svg
    fill: $n4

    &:nth-child(2)
      display: none

.close
  display: none
  +m
    display: block
    position: absolute
    top: 16px
    left: 16px
    width: 48px
    height: 48px
    svg
      fill: $n7
      +dark
        fill: $n1

.foot
  margin-top: 24px
  padding: 18px 0
  border-top: 1px solid rgba(137, 169, 212, 0.24)
  position: sticky
  bottom: 0
  background: $n1

  +d
    margin-top: 12px
    padding-top: 12px
  +dark
    border-color: $n6

.button
  width: 100%
  display: flex
  align-items: center
  padding: 0 25px

.theme
  margin-top: 16px

.counter
  flex-shrink: 0
  min-width: 24px
  margin-left: auto
  border-radius: 6px
  background: $s2
  text-align: center
  line-height: 24px
  color: $n7
  +d
    display: none
  +m
    display: block

.overlay
  +d
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 12
    background: rgba($n2, .8)
    visibility: hidden
    opacity: 0
    transition: all .2s
    +dark
      background: rgba($n6, .9)
    &.active
      visibility: visible
      opacity: 1
  +m
    display: none

.info
  font-size: 14px
  color: $n4
  padding-left: 12px
  &:hover
    text-decoration: underline

.loader
  width: 100%
  display: grid
  place-items: center
  height: 300px

.logoWrapper
  @include element-flex(column, center, stretch)
  width: 100%
  background: $n1
  position: sticky
  padding: 0
  height: 68px !important
  min-height: 68px !important
  top: 0
  z-index: 2
  margin-bottom: 28px
  cursor: pointer
