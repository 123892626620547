@import './vars';
@import './colors';
@import './mixins';
@import './responsive';

:global {
  .introjs-tooltip {
    //background: rgba(242, 245, 249, 0.2) !important;
    //box-shadow: 0 8px 22px 0 rgba( 31, 38, 135, 0.2 ) !important;
    //backdrop-filter: blur( 2px ) !important;
    //-webkit-backdrop-filter: blur( 8px ) !important;
  }

  .reactEasyCrop_CropArea {
    border-radius: $radius-s;
  }

  .cropAreaRound {
    .reactEasyCrop_CropArea {
      border-radius: 50% !important;
    }
  }

  .publicContainer {
    min-height: calc(100vh - #{$nav-height});
    background: $main-background;
    margin-top: $nav-height;
  }

  .infinite-scroll-component__outerdiv {
    width: 100% !important;
    height: auto;
    overflow: hidden !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    @include appearance(none);
    -moz-appearance: textfield;
  }

  .introjs-button {
    @include element-flex(row, center, center);
    column-gap: 12px;
    height: 40px !important;
    text-align: center;
    padding: 0 24px;
    background-color: $blue-color;
    text-shadow: none;
    border-radius: 999px;
    font-size: $font-size-s;
    box-shadow: none !important;
    font-weight: $font-semiBold;
    font-family: $main-font;
    color: white;
    cursor: pointer;
    z-index: 1;
    transition: background-color 0.2s, color 0.2s;
    line-height: normal;
    border: 1px solid transparent;

    &:focus,
    &:hover,
    &:active {
      background-color: darken($blue-color, 6%);
      color: #ffffff;
    }

    &.withIcon {
      position: relative;
      padding: 12px 24px 12px 54px;
    }

    &.mobile {
      padding: 12px 24px;
    }
  }

  input[type="color"] {
    -webkit-appearance: none;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 0;
    overflow: hidden;
    border-radius: $radius-s;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type="color"]::-webkit-color-swatch {
    border: 1px solid transparent;
  }

  [data-rmiz-modal-overlay="visible"],
  [data-rmiz-modal-overlay="hidden"]{
    background: #0000008f !important;
  }

  .rnc__notification {
    isolation: isolate;
    margin-top: 10px;
    width: 100% !important;
  }

  .rnc__notification-parent {
    max-width: 400px !important;
    isolation: isolate;
    background: #000;

    @include smDesktopWidth {
      max-width: calc(100vw - 24px) !important;
    }
  }

  .rnc__notification-item {
    border-radius: $radius-m !important;
    overflow: hidden !important;
    box-shadow: none !important;
    backdrop-filter: blur(4px) !important;
  }

  .rnc__notification-container--bottom-right {
    min-width: 400px !important;
    bottom: 45px !important;
    isolation: isolate;

    @include smDesktopWidth {
      max-width: calc(100vw - 24px) !important;
    }
  }

  #qr-reader{
    overflow: hidden !important;
    border-radius: 10px !important;
    border: none !important;
    background: $gray-color;
    padding: 12px;

    div:nth-child(1) > img:nth-child(2){
      display: none !important;
    }
  }

  #qr-reader__scan_region {
    left: 12px;
    right: 12px;
    width: calc(100% - 24px) !important;
    margin-top: 12px;
  }

  #qr-reader__scan_region{
    overflow: hidden !important;
    border-radius: 10px !important;
  }

  #qr-reader__dashboard_section_csr > span:first-of-type {
    display: block;
    font-size: $font-size-s;
    color: $font-color;
    font-weight: normal;
    line-height: 20px;
    text-align: left;
    margin: 6px !important;
  }

  #html5-qrcode-anchor-scan-type-change{
    display: none !important;
  }

  #html5-qrcode-select-camera{
    padding: 6px 8px;
    height: 40px;
    width: 100%;
    font-size: 15px;
    font-weight: 700;
    font-family: 'Lato', sans-serif;
    border-radius: $radius-s;
    transition: all 0.2s;
    border: 1px solid rgba(0, 0, 0, 0.23);
    box-shadow: none;

    &.disabled {
      background: $disabled-input-background;
    }

    &:hover {
      border: 1px solid $input-hover-color;
    }

    &.error {
      border: 1px solid $danger-color;
    }

    &::placeholder {
      color: #9a9fa5;
    }

    &:focus {
      border: 1px solid #4849ff;
      box-shadow: 0 0 0 1px #4849ff;
    }
  }

  #html5-qrcode-button-camera-permission,
  #html5-qrcode-button-camera-start,
  #html5-qrcode-button-camera-stop{
    @include element-flex(row, center, center);
    column-gap: 12px;
    height: 42px;
    text-align: center;
    padding: 3px 24px;
    background: $blue-color;
    border-radius: 999px;
    font-size: $font-size-s;
    font-weight: $font-semiBold;
    font-family: $main-font;
    color: white;
    cursor: pointer;
    transition: all 0.2s;
    line-height: normal;
    border: 1px solid transparent;
    letter-spacing: 0;
    margin: 0 auto;

    @include smDesktopWidth {
      font-size: $font-size-m;
    }
    @include printView {
      display: none;
    }

    &:hover,
    &:active {
      background-color: darken($blue-color, 6%);
      color: #ffffff;
    }
  }
}

:root {
  background: #f2f5f9;

  @include desktopWidth {
  }
}
