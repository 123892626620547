@import 'src/styles';

.container{
  width: 100%;
  display: grid;
  grid-template-columns: 250px 80px repeat(3, 220px) 170px 90px repeat(4, 220px) 90px;
  padding: 6px 0;
  border-radius: $radius-s;
  gap: 3px;

  &.extended{
    grid-template-columns: 250px 80px 220px 293px 220px 90px repeat(4, 220px) 90px;
  }

  &.owner{
    background: $blue-lightest-color !important;
  }


  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

}

.nameWrapper{
  @include element-flex(row, flex-start, center);
  gap: 3px;
  padding-left: 6px;
  flex-wrap: wrap;
}

.name{
  font-size: $font-size-m;
  line-height: 20px;
}

.email{
  color: $font-color-light;
  line-height: 20px;
}

.checkbox{
  width: auto !important;
  @include element-flex(row, center, center);
}

.info{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(120%, -50%);
}

.checkboxWrapper{
  position: relative;
}

.wrapper{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  gap: 5px;
  width: 100%;

  &.double{
    grid-template-columns: repeat(2, 1fr);
  }

  &.extended{
    grid-template-columns: repeat(4, 1fr);
  }

  &.single{
    @include element-flex(row, center, center);
  }
}