@import 'src/styles';

.container {
  @include element-flex(column, flex-start, flex-end);
  width: 100%;
  gap: 24px;
}

.smallSelect{
  width: 100px;
}

.input {
  width: 100%;
}

.select {
  z-index: 2;
}
