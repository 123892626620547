@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 130px 130px repeat(2, 150px) 40px;
  padding: 10px 12px;
  gap: 24px;
  border-radius: $radius-s;

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  &.blur .text{
    filter: blur(4px);
  }

  @include phoneWidth {
    @include element-flex(column);
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;

  @include desktopWidth {
    @include element-flex(column);
    gap: 6px;
  }

  @include phoneWidth {
    display: block;
    order: 1;

    & > p {
      display: inline-block;

      &:first-child {
        padding-right: 0.5ch;
      }
    }
  }

  &.flex {
    @include element-flex(row, flex-start, center);
  }

  &.userDetails {
    @include element-flex(row, flex-start, center);

    @include phoneWidth {
      order: 0;
    }
  }

  &.menu {
    @include desktopWidth {
      grid-column: 4;
      grid-row: 1;
    }

    @include tabletWidth {
      position: absolute;
      right: 12px;
      top: 0;
    }

    @include phoneWidth {
      top: 24px;
    }
  }
}

.text {
  color: $font-color;
  font-size: $font-size-s;
  word-break: break-all;
  @include limit-lines(3);

  &.email {
    @include phoneWidth {
      font-size: $font-size-s;
      font-weight: 700;
    }
  }
}

.label {
  @extend .text;
  font-weight: 700;

  @include smDesktopWidth {
    font-weight: 400;
  }
}

.nameLabel {
  min-width: 50px;
}

.name {
  word-break: break-word;
}

.role {
  @extend .text;
  padding: 3px 6px;
  border-radius: $radius-s;
  font-weight: normal;
}

.menu {
  position: static;
}

.thumbnail {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.roleTag {
  @include phoneWidth {
    order: 0;
  }
}

.tag {
  @include phoneWidth {
    max-width: 150px !important;
  }
}
