@import '../../styles';

.container {
  width: 100%;
  padding-bottom: 100px;

  @include smDesktopWidth {
    padding: 20px 20px 100px 20px;
  }
}

.search {
  flex: 0;
}

.mobileSearch {
  width: 100%;
  padding: 0 20px;
}
