@import 'src/styles';

.container {
  width: 100%;
  max-width: 400px;

  @include smDesktopWidth {
    max-width: 280px;
  }
}
