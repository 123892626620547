@import '../../../../styles';

.container {
  width: 100%;
}

.section {
  padding: 24px;
  border-radius: $radius-s;
  background: #ffffff;
  margin-top: 24px;
}

.title {
  font-size: $font-size-l;
  font-weight: bold;
}

.extrasHeader {
  grid-template-columns: 1fr 150px 1fr 150px 50px;
  gap: 12px;

  @include desktopWidth {
    display: none;
  }
}

.table {
  @include smDesktopWidth {
    padding: 12px 0;
  }
}
