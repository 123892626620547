@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.blue {
  color: $blue-color;
}

.blue-light {
  color: $blue-light-color;
}

.blue-dark {
  color: $blue-dark-color;
}

.green {
  color: $green-color;
}

.danger {
  color: $danger-color;
}

.yellow {
  color: $yellow-color;
}
