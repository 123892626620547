@import '../../styles';

.container {
  width: 100%;
}

.buttonWrapper {
  @include element-flex(row, flex-end, center);
  gap: 24px;
}
