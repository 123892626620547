@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.top {
  & > h2 {
    text-align: center;
  }

  & > p {
    padding: 0 24px;
    margin: 14px auto 48px;
    text-align: center;

    max-width: $txt-max;
  }
}

.storage {
  @include element-flex(row, space-between);
  margin-bottom: 70px;

  @include tabletWidth {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
}

.left {
  max-width: 48%;

  & > img {
    max-width: 100%;
  }

  @include tabletWidth {
    max-width: 90%;
    margin-bottom: 40px;
  }
}

.right {
  margin: auto 0 auto 10%;

  & > h3 {
    font-size: 36px;
    font-weight: $font-extraBold;
    margin-bottom: 14px;

    @include desktopWidth {
      font-size: 28px;
    }

    @include phoneWidth {
      font-size: 20px;
    }
  }

  & > p {
    margin-bottom: 32px;
    margin-right: 10%;
  }
}

.features {
  display: grid;
  row-gap: 18px;

  @include desktopWidth {
    row-gap: 10px;
  }
}

.feature {
  display: flex;
  align-items: center;

  & > img {
    margin-right: 10px;
  }

  & > p {
    line-height: 14px;
  }
}
