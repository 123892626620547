@import '../../styles';

.treeContainer {
  display: flex;
  flex-direction: column;
  justify-content: right;
  width: 100%;
  gap: 16px;
  background-color: #fff;
  padding: 24px;
  border-radius: $radius-s;

  @include smDesktopWidth {
    padding: 24px 12px 150px;
  }
}

.label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 6px;
  padding: 18px 24px;
  min-height: 30px;
  margin-right: 0;
  position: relative;
}

.labelButtons {
  display: flex;
  flex-direction: row;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 12px 0 24px;
}

.saveButton {
  background-color: $green-color;

  &:hover {
    background: darken($green-color, 10%);
  }
}

.buttonWrapper {
  @include element-flex(row, flex-start, center);
  flex-wrap: wrap;
  gap: 24px;
}
