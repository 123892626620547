@import '../PublicPages.module.scss';

.container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 50px;

  @include lgDesktopWidth {
    padding: 0 !important;
  }

  @include smDesktopWidth {
    padding: 0 20px !important;
  }
}

.title {
  margin-bottom: 34px;
  font-size: 22px;
  line-height: 1.636em;
  font-weight: 900;
  letter-spacing: 0;
  text-align: center;

  @include phoneWidth {
    font-size: 18px;
  }
}

.noTitle {
  display: none;
}

.tiles {
  width: 100%;
  max-width: 1900px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 20px;
  justify-content: space-around;
  padding: 0 calc(50% - 700px);

  @include customBreakpoint(1440px) {
    padding: 0 20px;
  }

  @include desktopWidth {
    padding: 0 calc(50% - 350px);
  }

  @include tabletWidth {
    @include element-flex(column, space-between, stretch);
  }
}

.tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 150px;
  flex: 0 1 288px;

  gap: 13px;
  padding: 33px 12px;
  border-radius: 28px;

  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #eff0f6;
  background-color: #fff;
  box-shadow: 0 6px 12px 0 rgba(20, 20, 43, 0.05);

  @include tabletWidth {
    padding: 25px 12px;
    flex: 0 1 150px;
  }
}

.tileTitle {
  font-family: $public-font;
  font-size: 1em;
  line-height: 50px !important;
  font-weight: 700;

  @include desktopWidth {
    font-size: 0.8em;
  }
}

.tileDesc {
  font-size: clamp(12px, 0.3em, 24px);
  font-weight: 900;
  letter-spacing: -0.2px;
}

.miniTile {
  flex: 0 1 200px;
  gap: 10px;
  padding: 18px 10px;
  border-radius: 18px;
  box-shadow: 0 4px 8px 0 rgba(20, 20, 43, 0.05);

  font-size: 0.6em;

  @include phoneWidth {
    flex: 0 1 170px;
  }
}

.colorBlue {
  color: $blue-color;
}

.colorPurple {
  color: $purple-color;
}

.colorYellow {
  color: $yellow-color;
}

.colorLightBlue {
  color: $blue-light-color;
}
