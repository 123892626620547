@import 'src/styles';

.icon{
  width: 16px;
  height: 16px;
}

.wrapper{
  @include element-flex(column, flex-start, center);
  gap: 12px;
}
