@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.container {
  display: flex;
  border-radius: $radius-s;
  border: $border-main;
  padding: 22px 29px 22px 24px;

  transition: 0.4s ease;

  filter: opacity(0.35);

  @include phoneWidth {
    padding: 14px 21px 14px 16px;
  }

  &:hover {
    cursor: pointer;
    filter: opacity(0.5);
  }
}

.number {
  @include element-flex(row, center, center);

  font-family: $public-font;
  font-size: 17px;

  width: 38px;
  min-width: 38px;
  height: 38px;
  margin-right: 16px;
  border-radius: 100px;

  color: white;
  background-color: $blue-color;

  @include phoneWidth {
    font-size: 12px;

    width: 28px;
    min-width: 28px;
    height: 28px;
    margin-right: 12px;
  }
}

.description h3 {
  font-weight: $font-extraBold;
  margin-bottom: 4px;
}

.active,
.active:hover {
  filter: opacity(1);
  box-shadow: $shadow-soft;
}
