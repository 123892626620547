@import 'src/styles';

.container {
  @include element-flex(column);
  width: 100%;
  gap: 12px;

  @include smDesktopWidth {
    background-color: transparent;
    padding: 24px 0;
  }
}

.row {
  @include element-flex(row, stretch, flex-start);
  gap: 24px;
  width: 100%;

  @include smDesktopWidth {
    @include element-flex(column, flex-start, center);
  }
}

.phoneInput {
  width: 100%;
  padding: 16.5px 14px;
}

.wrapper {
  width: 100%;
  @include element-flex(row, stretch, center);
  gap: 24px;

  &.street {
    display: grid;
    grid-template-columns: 300px repeat(2, 100px);
  }

  &.zipCode {
    display: grid;
    grid-template-columns: 150px 1fr;
  }

  @include smDesktopWidth {
    @include element-flex(column, flex-start, stretch);
  }
}

.checkbox {
  padding: 12px 0;
}

.button {
  margin: 24px auto 0;
  width: 200px;
}

.form {
  width: 100%;
  gap: 12px;
  @include element-flex(column);

  @include smDesktopWidth {
    gap: 24px;
  }
}

.userDetails {
  @include element-flex(column);
  width: 100%;
  gap: 3px;

  &.reverse {
    align-items: flex-end;
  }
}

.topSection {
  @extend .wrapper;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 36px;
}

.company {
  @include element-flex(column);
  gap: 3px;
  margin-bottom: 24px;
}

.userDataWrapper {
  @include element-flex(column);
  gap: 36px;
}
