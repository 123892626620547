@import 'src/styles';

.modal {
  max-width: 600px;
}

.mobileModal {
  max-width: 100%;
}

.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 100px;
  gap: 24px;
  padding-bottom: 12px;
  &.cancelledWrapper {
    grid-template-columns: 1fr;
  }
}

.products {
  list-style-type: numeric;
  padding-left: 15px;
  @include element-flex(column);
  gap: 10px;
  max-height: 300px;
  overflow: auto;
}

.button{
  margin-left: auto;
}