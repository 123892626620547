@import 'src/styles';

.container {
  @include element-flex(row, flex-start, center);
  width: 100%;
  padding: 6px;
  gap: 12px;
  border-radius: $radius-s;
  background-color: #fcfcfc;
  transition: background 0.2s cubic-bezier(0.67, -0.01, 0.61, 0.98);
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 120px 40px;

  &:hover {
    background: rgba(0, 1, 290, 0.1);
  }

  &:nth-of-type(odd) {
    background-color: $odd-row-background;

    &:hover {
      background: rgba(0, 1, 290, 0.1);
    }
  }

  &.hidden {
    opacity: 0.5;
  }

  &.loading {
    animation: loading 0.7s ease alternate infinite;
  }

  @include smDesktopWidth {
    display: grid;
    grid-template-columns: 1fr 100px 40px;
    padding: 12px 6px;
  }

  @include phoneWidth {
    grid-template-columns: 1fr 50px;
  }
}

.box {
  width: 100%;
  display: grid;
  grid-template-columns: 48px 1fr 40px;
  place-items: center;

  &:hover ~ .button {
    visibility: visible;
    opacity: 1;
  }

  @include smDesktopWidth {
    @include element-flex(row, flex-start, center);
  }

  @include phoneWidth {
    grid-column: 1 / 3;
  }
}

.qtySelect {
  &:hover ~ .button {
    visibility: visible;
    opacity: 1;
  }
}

.wrapper {
  @include element-flex(column, center, flex-start);
  width: 100%;
  height: 100%;
  padding: 0 12px;
}

.title {
  font-size: $font-size-s;
  font-weight: $font-bold;
  line-height: 16px;
  width: 100%;
}

.subtitle {
  @include element-flex(row, flex-start, baseline);
  gap: 12px;
  font-size: $font-size-xs;
  font-weight: $font-bold;
  color: $blue-light-color;
  line-height: 16px;
}

.thumbnail {
  width: 48px;
  height: 48px;
  border-radius: $radius-s;
}

.button {
  @include element-flex(column, center, center);
  width: 36px;
  height: 36px;
  box-sizing: border-box;
  padding: 6px;
  align-self: center;
  justify-self: center;
  border-radius: 50%;
  background: $blue-light-color;
  transition: opacity 0.2s cubic-bezier(0.67, -0.01, 0.61, 0.98), visibility 0.2s cubic-bezier(0.67, -0.01, 0.61, 0.98),
    background 0.2s;
  opacity: 1;
  cursor: pointer;
  position: relative;
  right: 0;
  top: 50%;

  &:hover {
    background: darken($blue-light-color, 10%);
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  &.disabled {
    opacity: 0.8 !important;
    cursor: not-allowed;
    &:hover {
      background: initial;
    }
  }

  &.onClear {
    background: $danger-color;
  }

  @include smDesktopWidth {
    position: static;
    margin: auto;
  }
}

.qtySelector {
  @include smDesktopWidth {
    width: 100%;
  }
}

.hiddenInfo {
  grid-column: 2 / 4;
  color: $blue-color;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @include phoneWidth {
    grid-column: 1 / 3;
    text-align: center;
  }
}

@keyframes loading {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.9;
  }
}
