@import 'src/styles';

.select {
  min-width: 210px !important;
  max-width: 210px !important;
  transition: min-width 0.2s cubic-bezier(0.66, 0.41, 0.12, 0.94), max-width 0.2s cubic-bezier(0.66, 0.41, 0.12, 0.94);

  &.expandable {
    &.selected,
    &:active,
    &:focus-within {
      min-width: 320px !important;
      max-width: 320px !important;
    }
  }

  &.fullWidth {
    width: 100% !important;
    max-width: unset !important;
    min-width: unset !important;
  }
}

.roleSelect {
  min-width: 200px;

  @include smDesktopWidth {
    min-width: unset;
  }
}
