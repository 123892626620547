@import 'src/styles';

.container {
  display: flex;
  position: relative;
  z-index: auto;
}

.input {
  display: none;
}

.fileInfo {
  @include element-flex(column, center, flex-start);
  @include trimText;
  height: 45px;
  position: relative;
  padding-right: 20px;
}
.fileSize {
  font-size: $font-size-xs;
  color: $font-color-light;
}

.fileName {
  @include trimText;
  font-size: $font-size-s;
  color: $font-color;
  font-weight: $font-semiBold;
  max-width: 40ch;
  margin-right: 10px;
}

.clearButton {
  @include element-flex(column, center, center);
  width: 20px;
  height: 20px;
  padding: 3px;
  border: none;
  border-radius: 50%;
  background: transparent url(../../../assets/icons/closeIcon.svg) no-repeat;
  position: absolute;
  right: -2px;
  top: 0;
  cursor: pointer;
}

.loaderWrapper {
  @include element-flex(column, center, center);
  height: 50px;
  width: 100px;
  background: $blue-color;
  border-radius: $radius-m;
}

.loadButton {
  min-width: 100px;
  z-index: auto;
  margin-right: 20px;
  word-break: keep-all;
  white-space: nowrap;
  transition: all 0.2s;

  &.center{
    margin: 0 auto;
  }

  &.error {
    border: 1px solid $danger-color !important;
  }

  &.error:active,
  &.error:focus {
    box-shadow: 0 0 0 1px $danger-color !important;
  }
}
