@import 'src/styles';

.container {
  @include element-flex(column);
  width: 100%;
  gap: 36px;

  @include smDesktopWidth {
    padding: 0 10px 20px;
  }
}

.box {
  width: 100%;
  border-radius: $radius-s;
  background: #ffffff;
  padding: 24px;
}

.title {
  font-size: $font-size-m;
  font-weight: $font-bold;
  margin-bottom: 12px;
}

//form with grid columns
.form {
  @include element-flex(column);
  gap: 36px;
  width: 100%;
}

.address {
  @include element-flex(column, flex-start, stretch);
  gap: 12px;
  width: 100%;
  margin-top: 24px;
}

.payments {
  @extend .box;
  width: 100%;
  grid-column: 2;
  grid-row: 1;
  height: 100%;
}

.ship {
  @extend .box;
  grid-column: 2;
  grid-row: 2;
}

.radioWrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;
}

.value {
  @extend .box;
  background: $gray-color;
  grid-column: 1 / 3;

  .title {
    margin: 0;
  }
}

.amount {
  font-size: $font-size-xxl;
  font-weight: $font-bold;
}

.suppliers {
  margin: 12px 0;
  overflow: hidden;
  height: auto;
  position: relative;

  &.largeList {
    height: 420px;
    transition: height 0.3s cubic-bezier(0.71, 0.13, 0.46, 1.05);
  }
}

.showMore {
  width: 100%;
  height: 100px;
  display: grid;
  place-items: center;
  text-align: center;
  font-size: $font-size-s;
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  background: linear-gradient(to bottom, transparent, #f2f5f9 50%);
  color: $blue-color;

  &:hover {
    text-decoration: underline;
  }
}

.supplierName {
  font-size: $font-size-xl;
  font-weight: 800;
  padding: 6px 6px 18px;
  border-bottom: 1px solid $border-color-lighter;

  & > span {
    font-size: $font-size-s;
    opacity: 0.8;
  }
}

.header {
  grid-template-columns: 1fr 200px repeat(4, 110px);
  gap: 12px;
  padding: 18px 6px;
}

.detailsWrapper {
  @include element-flex(column);
  width: 100%;
  gap: 24px;
}

.card{
  width: 100%;
  @include element-flex(row, space-between, center);
}
