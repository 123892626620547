@import 'src/styles';

.container{
  width: 800px;
  display: grid;
  grid-template-columns: 2fr 150px 3fr 150px;
  gap: 12px;
  padding: 6px;
  border-radius: $radius-s;

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }
}

.wrapper{
  width: 100%;
}