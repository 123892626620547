@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.teams {
  & > h2 {
    text-align: center;
    margin-bottom: 14px;
  }

  & > p {
    text-align: center;
    line-height: 1.75em;
    max-width: $txt-max;
    margin: 0 auto 54px;
    padding: 40px 24px 0;

    @include phoneWidth {
      padding-top: 20px;
      margin-bottom: 25px;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 26px;

  margin-bottom: 48px;

  @include tabletWidth {
    grid-template-columns: 1fr;
    margin-bottom: 10px;
  }
}

.card {
  @include element-flex(column);
  padding: 56px 40px 43px;
  border-radius: $radius-s;
  background-color: $background-gray;

  @include tabletWidth {
    justify-self: center;
    max-width: 400px;
    margin-bottom: 30px;
  }

  & > h3 {
    margin-bottom: 10px;

    @include tabletWidth {
      font-size: 22px;
    }

    @include phoneWidth {
      font-size: 17px;
    }
  }

  & > p {
    margin-bottom: 40px;
  }

  & > img {
    width: 100%;
    box-shadow: $shadow-img;
    border: $border-main;
    border-radius: $radius-s;

    margin-top: auto;
  }
}

.button {
  min-width: min-content;
  max-width: max-content;
  margin: 0 auto;

  & > span {
    line-height: normal;
  }
}
