@import 'src/styles';

.container{
  width: 100%;
  overflow: auto;
}

.header{
  display: grid;
  grid-template-columns: 90px 1fr 100px 90px 100px 100px;
  width: 100%;

  @include tabletWidth{
    grid-template-columns: 90px 1fr 80px;
  }
}

.order{
  @extend .header;
  padding: 12px;
  border-radius: $radius-s;

  & > p:first-child{
    color: $blue-color;
  }

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }
}

.loadMore{
  width: 100%;
  @include element-flex(row, center, center);
  padding: 24px 0 12px;
}