@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  column-gap: 24px;
  row-gap: 12px;
  padding: 6px;
  border-radius: $radius-s;
  min-height: 60px;
  position: relative;
  grid-template-columns: 1fr 180px 120px 120px 90px 50px;

  &.formList{
    grid-template-columns: 1fr 100px 150px 130px 130px 90px 50px;
  }

  @include desktopWidth {
    grid-template-columns:1fr 150px 100px 100px 70px 50px;
  }

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  @include smDesktopWidth {
    @include element-flex(column);
    padding: 36px 12px 24px;
    gap: 12px;
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;

  &.column{
    @include element-flex(column, center);
    gap: 6px;
  }
}

.tag{
  padding: 3px 6px;
  font-size: 12px;
  max-width: 130px;
}

.text {
  @include limit-lines(3);
  word-break: break-word;
  font-size: $font-size-s;
  font-weight: 400;
  line-height: 18px;

  &.blue{
    color: $blue-color;
  }

  @include smDesktopWidth {
    font-size: $font-size-m;
    text-align: left;
    min-width: 80px;
  }
}

.delete {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: $danger-color;
  margin: auto 6px auto auto;
  transition: background 0.2s;

  & > svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: darken($danger-color, 5%);
  }
}

.menu {
  @include smDesktopWidth {
    top: 12px !important;
    right: 12px !important;
  }
}

.nameWrapper {
  @include smDesktopWidth {
    padding-bottom: 12px;
  }
}

.thumbnailWrapper {
  position: relative;
}

.userIcon {
  width: 80%;
  height: 80%;
  object-fit: cover;
  filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(285deg) brightness(103%) contrast(101%);
}

.userIconWrapper {
  background-color: $blue-light-color;

  @include element-flex(row, center, center);
  overflow: hidden;
  position: absolute;
  left: -2px;
  bottom: -2px;

  border-radius: 100%;
  width: 18px;
  height: 18px;
}

.suppliers{
  @include element-flex(row, flex-start, center);
  flex-wrap: wrap;
  padding: 3px;
  width: 100%;
  gap: 12px;
  grid-column: 1 / 7;
}