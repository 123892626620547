@import 'src/styles';

.container{
  width: 750px;
  @include element-flex(column, flex-start, center);
  gap: 24px;
}

.header{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
}

.checkbox{
  width: auto;
  @include element-flex(row);
  gap: 6px;
  font-weight: 700;
}

.text{
  width: 100%;
}

.wrapper{
  width: 100%;
  @include element-flex(column);
  gap: 6px;
  max-height: 50vh;
  overflow: auto;
}

.wrapperHeader{
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 1;
  padding: 0 12px 0 6px;
}

.emptyState{
  width: 100%;
  text-align: center;
  font-size: $font-size-l;
  font-weight: $font-bold;
  color: $font-color-light;
  max-width: 600px;
}