@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.wrapper {
  @include element-flex(row, space-between, center);
  width: 100%;
  gap: 20px;

  & > h2 > span {
    color: $blue-color;
  }

  @include smDesktopWidth {
    @include element-flex(column, center, space-between);
  }
}

.center {
  @include element-flex(row, center, center);
  text-align: center;
}

.lean > h2 {
  font-weight: $font-bold !important;
}

.categoryButton {
  max-width: 25%;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: $spacing-2;

  @include smDesktopWidth {
    justify-content: center;
    flex-wrap: wrap;
  }
}
