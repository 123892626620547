@import 'src/styles';

.container{
  width: 100%;
  @include element-flex(column, flex-start, center);
  gap: 24px;
}

.buttonWrapper{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 24px;
  width: 100%;
}

.inputsWrapper{
  width: 100%;
  @include element-flex(row, stretch, flex-start);
  gap: 14px
}

.select{
  width: auto;
}