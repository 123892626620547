@import '../../../styles';

.container {
  width: 100%;
  height: 212px;
  box-shadow: none !important;
  border: none !important;
  border-radius: $radius-s !important;
}

.columnWrapper {
  width: 100%;
  height: 112px;
  padding: 18px 24px 18px 24px !important;

  &.small {
    height: 212px !important;
  }
}

.rightWrapper {
  width: 100%;
  height: 18px;
  display: grid;
  place-items: center;
  margin: 55px 0 0 auto;
}

.rightWrapperSmall {
  @extend .rightWrapper;
  margin: 45px auto 0 0;
}
