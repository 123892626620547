@import '../../../styles';

.container {
  @include element-flex(row, center, center);
  gap: 6px;
  width: 86px;
  height: 36px;
  border-radius: $radius-s;
  border: 1px solid $border-color-lighter;
  padding: 6px;
  margin-bottom: 12px;
  transition: background-color 0.2s;
  font-size: $font-size-s;
  color: $font-color-light;
  font-weight: 700;

  &.wide {
    transform: none;
    position: relative;
  }

  @include smDesktopWidth {
    transform: none;
    position: relative;
    left: 10px;
    margin: 24px 0 0;
  }
  
  @include printView {
    display: none;
  }

  &:hover {
    background-color: $gray-lightest-color;
  }
}

.icon {
  width: 14px;
  height: 14px;
}
