@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.wrapper {
  min-height: calc(100vh - $nav-height);
  background-color: $background-default;
}

.container {
  font-family: Lato, sans-serif;
  max-width: 700px;
  margin: 0 auto;

  * {
    margin: 10px 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: $font-regular;
    margin-top: 20px;
  }

  h1,
  h2 {
    margin-bottom: 20px;
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: 1.6em;
  }

  p {
    line-height: 1.75em;
  }

  img {
    display: block;
    max-width: 80%;
    object-fit: cover;
    margin: $spacing-6 auto $spacing-2;
    border-radius: $radius-s;
  }

  li {
    margin: 0 0 4px 20px;
    font-weight: $font-regular;

    &:last-of-type {
      margin-bottom: 14px;
    }
  }

  ul > li {
    list-style-type: disc;
  }

  ol > li {
    list-style-type: decimal;
  }
}
