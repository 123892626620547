@import 'src/styles';

.header {
  grid-template-columns: 100px 3fr 2fr 120px 120px 120px 40px;
  gap: 24px;

  @include lgDesktopWidth {
    grid-template-columns: 85px 3fr 2fr 110px 120px 90px 40px;
  }

  @include desktopWidth {
    display: none;
  }
}
