@import 'src/styles';

.videoBox {
  width: 100%;
  height: 300px;
  background: $main-background;
  box-shadow: $shadow-main;
  aspect-ratio: 16 / 9;

  > div {
    height: 100%;
    width: 100%;
  }

  @include smDesktopWidth {
    height: calc((100vw - 80px) / 1.777);
    aspect-ratio: 16 / 9;
  }
}

.icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
