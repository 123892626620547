@import src/styles/helpers
@import src/styles

.search
  position: relative
  width: fit-content
  z-index: 2
  transition: width .2s cubic-bezier(.66,.41,.12,.94)

  &.open,
  &.open.small,
  &.expand.small
    width: 350px
  &.open.medium,
  &.expand.medium
    width: 450px
  &.open.large,
  &.expand.large
    width: 550px
    @include desktopWidth
      width: 400px

  @include smDesktopWidth
    border-radius: 0

  .direction
    visibility: visible
    opacity: 1
    cursor: default

  &.active
    .result
      visibility: hidden
      opacity: 0

    .direction,
    .close
      visibility: visible
      opacity: 1

    .body
      visibility: visible
      opacity: 1

  &.fullWidth
    width: 100% !important

  &.disabled
    @include disabled-ui
    cursor: not-allowed !important

.head
  position: relative
  z-index: 2

  &.fullWidth
    width: 100% !important

.direction,
.result,
.close
  position: absolute
  top: 50%
  transform: translateY(-50%)
  transition: all .2s

  svg
    transition: fill .2s

.start
  left: 12px

  svg
    fill: $n4

  &:hover
    svg
      fill: $n7

.result
  right: 8px
  width: 56px
  height: 32px
  background: $n
  border-radius: 8px
  box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.05), inset 0 -1px 1px rgba(0, 0, 0, 0.04), inset 0 2px 0 rgba(255, 255, 255, 0.25)
  font-size: 16px
  font-weight: 600
  color: $n7
  +dark
    background: $n7
    box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.05), inset 0 -1px 1px rgba(0, 0, 0, 0.04), inset 0 1px 0 rgba(255, 255, 255, 0.08)
    color: $n1
  +m
    display: none

  &:hover
    color: $p1

.direction
  left: 10px

  svg
    fill: $p1

.close
  right: 10px
  width: 32px
  height: 32px

  svg
    fill: $shades1

  &:hover
    svg
      fill: $p3

.direction,
.close
  visibility: hidden
  opacity: 0

.input
  height: 40px
  width: 210px
  padding: 0 68px 0 42px
  background: #fff
  border: 1px solid rgba(0, 0, 0, 0.23)
  border-radius: 6px
  box-sizing: border-box
  +base1-s
  color: $n7
  transition: all .2s
  +dark
    background: $n6
    color: $n1

  &:hover
    border-color: $input-hover-color

  &.active,
  &.active.small,
  &.expand.small
    width: 350px
  &.active.medium,
  &.expand.medium
    width: 450px
  &.active.large,
  &.expand.large
    width: 550px
    @include desktopWidth
      width: 400px

  &::placeholder
    color: $shades1

  &:focus
    box-shadow: 0 0 0 1px #4849FF
    width: 350px
    background: $n
    border: 1px solid #4849FF
    +dark
      background: $n8

  &.fullWidth
    width: 100% !important

.body
  position: absolute
  top: -12px
  left: -12px
  right: -12px
  padding: 60px 12px 12px
  border: 1px solid transparent
  box-shadow: 0 0 14px -4px rgba(0, 0, 0, 0.05), 0 32px 48px -8px rgba(0, 0, 0, 0.1)
  background: $n
  border-radius: 16px
  visibility: hidden
  opacity: 0
  transition: all .2s
  +m
    top: 100%
    left: 0
    right: 0
    max-height: calc(100vh - 152px)
    padding: 16px 8px
    border-radius: 0
    border-top: 1px solid $n3
    box-shadow: 0 12px 32px rgba($n8, .05)
    overflow: auto
  +dark
    background: $n7
    border-color: $n6


.box
  &:not(:last-child)
    margin-bottom: 24px
    padding-bottom: 24px
    border-bottom: 1px solid $n3
    +dark
      border-color: $n6
    +m
      margin-bottom: 16px
      padding-bottom: 16px

.category
  margin-bottom: 12px
  padding-left: 12px
  +caption1
  color: $n4

.loader
  width: 100%
  height: 125px
  display: grid
  place-items: center

.clear
  position: absolute
  right: 12px
  top: 50%
  transform: translate(0, -50%)
  z-index: 10

  & > svg > path
    transition: fill .2s
    fill: hsl(0, 0%, 70%)

  &:hover
    & > svg > path
      fill: hsl(0, 0%, 40%)
