@import '../../../styles';

.container {
  width: 100%;
  padding-top: 12px;

  @include smDesktopWidth {
    width: 100%;
  }
}

.wrapper {
  @include element-flex(row);
  width: 100%;
  gap: 24px;
  max-width: 500px;
  margin: 0 auto;

  @include smDesktopWidth {
    @include element-flex(column, flex-start, center);
  }
}

.column {
  @include element-flex(column);
  flex: 1;
  width: 100%;
  gap: 24px;
  position: relative;
  max-width: 500px;
  margin: 0 auto 24px;
}

.button {
  margin: 36px auto 0;
  width: 250px;
}

.qty {
  width: 100%;
}

.select {
  width: 100%;
}

.inputFile {
  visibility: hidden;
  height: 0;
  width: 0;
}

.rowWrapper {
  width: 100%;
  @include element-flex(row);
  gap: 24px;
}

.columnCenter{
  @include element-flex(column, center, center);
}

.productId {
  position: absolute;
  left: 0;
  top: -24px;
  font-size: $font-size-s;
  color: $font-color-light;
}
