@import 'src/styles';

.container {
  @include element-flex(column);
  gap: 24px;
  width: 700px;
  max-width: 90vh;
  max-height: 70vh;
  overflow: auto;

  &.smaller {
    width: 500px;
    overflow: visible;
  }
}

.wrapper {
  gap: 3px;
  padding: 12px 0;
}

.emptyState {
  @include element-flex(row, center, center);
  margin: 24px 0;
}

.header {
  @include element-flex(row, space-between, center);
}
