@import 'src/styles';

.container {
  @include element-flex(column, flex-end, flex-end);
  gap: 6px;
}

.modal {
  width: 600px !important;
}

.comment {
  max-width: 600px;
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: normal;
  word-break: break-all;
}

.buttonWrapper{
  @include element-flex(row, center, center);
  margin-top: 24px;
  gap: 24px;
}

.button {
  @include element-flex(column, center, center);
  transition: background 0.3s;
  border-radius: $radius-s;
  height: 24px;
  width: 24px;
  position: relative;

  &:before{
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    transition: background 0.3s;
    border-radius: $radius-s;
    z-index: -1;
  }

  &:hover:before {
    background: $blue-lightest-color;
  }
}

.wrapper{
  @include element-flex(row, flex-end, center);
  gap: 6px;
  width: fit-content;
}
