@import '../../../styles';

.inputWrapper {
  @include element-flex(row, stretch);
  width: 100% !important;

  & > div {
    width: 100%;
  }

  label {
    color: #9a9fa5;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0;
    padding-top: 1px;
  }

  &:focus-within {
    .button {
      opacity: 1 !important;
    }
  }
}

.notchedOutline {
  &:focus {
    border-width: 2px !important;
    border-color: #4849ff !important;
  }
}

.button {
  transition: opacity 0.2s;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.input {
  padding: 6px 8px;
  height: 40px;
  width: 100%;
  font-size: 15px;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  border-radius: $radius-s;
  transition: all 0.2s;
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-shadow: none;

  &.disabled {
    background: $disabled-input-background;
  }

  &:hover {
    border: 1px solid $input-hover-color;
  }

  &.error {
    border: 1px solid $danger-color;
  }

  &::placeholder {
    color: #9a9fa5;
  }

  &:focus {
    border: 1px solid #4849ff;
    box-shadow: 0 0 0 1px #4849ff;
  }

  &:focus.error {
    border: 1px solid $danger-color;
    box-shadow: 0 0 0 1px $danger-color;
  }
}
