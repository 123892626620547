@import '../../styles';

.container {
  width: 100%;

  @include smDesktopWidth {
    padding: 0 10px 30px;
  }
}

.search {
  width: auto !important;

  @include smDesktopWidth {
    width: 100% !important;
  }
}
