@import 'src/styles';

.container{
  @include element-flex(row, center, center);
  gap: 6px;
  position: absolute;
  right: 9px;
  top: 3px;
  height: 18px;

  &.aboveInput{
    padding: 2px;
    border-radius: $radius-s;
    top: -10px;
    left: 0;
    right: unset;
  }

  &.withBgc{
    background: $blue-lightest-color;
    animation: fadeOut .4s;
    animation-delay: 6.7s;
  }
}

.text{
  font-size: $font-size-xs;
  line-height: $font-size-xxs;
  color: $font-color-light;
}

.loader{
  transform: scale(0.4);
  margin: 0 5px;
}

.tick{
  width: 13px;
  height: 13px;

  & > * {
    fill: $blue-color;
  }
}

.loading{
  @include element-flex(row, center, center);
  gap: 3px;
  animation: fadeIn .2s forwards;
}

.saved{
  @extend .loading;
  animation: fadeOut .3s forwards;
  animation-delay: 6.5s;

  & > .text{
    color: $blue-color;
  }

  & > .text,
  & > svg {
    opacity: 0.8;
  }
}

.error{
  @extend .saved;
  animation: none;

  & > .text{
    color: $danger-color;
    font-weight: 700;
  }

  & > .text,
  & > svg {
    opacity: 1;
  }
}


@keyframes fadeOut {
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    background: transparent;
  }
}

@keyframes fadeIn {
  @include keyframe-fadeIn;
}