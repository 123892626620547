@import 'src/styles';

.card {
  width: 100%;
  position: relative;

  & > span {
    width: 40% !important;
  }

  @include printView {
    display: none;
  }
}

.title{
  @include element-flex(row, flex-start, center);
  gap: 12px;
  line-height: 24px;
  color: $font-color;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;

  &:hover{
    color: #000;
  }
}

.box{
  position: relative;
  width: 100%;
}
