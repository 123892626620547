@import 'src/styles';

.container {
  @include element-flex(column, flex-start, stretch);
  gap: 24px;
  width: 600px;

  @include smDesktopWidth{
    width: 100%;
    margin: 0;
  }

  @include tabletWidth{
    max-height: 80dvh;
    overflow: auto;
    padding: 24px 10px;
  }
}

.button {
  @include element-flex(column, flex-start, stretch);
  width: 100%;
}

.row{
  @include element-flex(row, stretch, stretch);
  gap: 24px;

  @include tabletWidth{
    @include element-flex(column, stretch, stretch);
  }
}

.input{
  width: 100%;
  flex-grow: 1;
}
