@import 'src/styles';

.container{
  @include element-flex(column);
  width: 100%;

  @include tabletWidth{
    overflow: auto;
  }
}

.isLoading{
  opacity: 0.5;
  pointer-events: none;
}

.wrapper{
  @include element-flex(column, flex-start, stretch);
  padding: 0 0 12px 0;
  animation: fadeIn .2s;

  @include customBreakpoint(515px){
    padding: 12px 0;
  }
}

.textRight {
  text-align: left;
}

.row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 100px repeat(3, 130px);
  padding: 6px;
  border-radius: 3px;
  gap: 6px;

  &.horizontal{
    grid-template-columns: 100px 1fr 100px repeat(3, 130px);

    @include smDesktopWidth{
      grid-template-columns: 70px 1fr 70px repeat(3, 100px);
    }

    @include tabletWidth{
      grid-template-columns: 2fr 3fr;
    }
  }

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  @include tabletWidth{
    grid-template-columns: 1fr 65px 75px 75px;
  }

  @include customBreakpoint(515px){
    grid-template-columns: 1fr 1fr;
    height: auto;
    padding: 12px 6px;
  }
}

.tableHeader {
  @extend .row;
  display: grid;
  padding: 18px 6px 12px;
  gap: 6px;
  position: sticky;
  top: 0;
  background: #fff !important;

  & > p {
    font-weight: 700;
  }

  @include smDesktopWidth{
    grid-template-columns: 1fr 80px 130px 110px 130px;
  }

  @include tabletWidth{
    grid-template-columns: 1fr 65px 75px 75px 75px;
  }

  &.hidden{
    display: none;
  }

  @include customBreakpoint(515px){
    display: none;
  }
}

.rowSupplierName {
  @include element-flex(row, flex-start, center);
  gap: 3px;
  max-width: fit-content;

  @include customBreakpoint(515px){
    grid-column: 1;
    row-gap: 8px;

    & > p{
      font-weight: 700;
      font-size: 15px;
    }
  }
}

.data {
  @include element-flex(row, flex-start, center);

  &.count{
    @include customBreakpoint(515px){
      grid-column: 2;
      grid-row: 1;
    }
  }
}

.info{
  margin-left: 6px;
}

.summary {
  font-size: $font-size-l;
  font-weight: $font-bold;

  &.twoColumns {
    grid-column: 1 / 3;
  }
}

.summaryRow {
  @extend .row;
  padding: 0 18px;
  background: transparent !important;
}

.title {
  font-size: $font-size-xxl;
  font-weight: $font-bold;
  text-align: center;
  width: 100%;
}

.table {
  min-height: 132px;
  padding: 0 12px;

  @include smDesktopWidth {
    max-height: 40dvh;
  }
}


@keyframes fadeIn {
  @include keyframe-fadeIn;
}