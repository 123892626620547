@import src/styles/helpers
@import src/styles

.modal
    display: flex
    justify-content: center
    align-items: center
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    padding: 24px
    background: #0A1B2D5C
    opacity: 0
    animation: showModal .4s forwards
    overflow: auto
    z-index: 999
    +m
        padding: 48px 12px !important
    +dark
        background: $n8

    &.blur
        backdrop-filter: blur( 10px )
        -webkit-backdrop-filter: blur( 10px )

    &.hideHeader
        padding: 0 !important


@keyframes showModal
    0%
        opacity: 0
    100%
        opacity: 1

.outer
    display: inline-block
    min-width: 500px
    margin: auto
    border-radius: 13px
    z-index: 2
    background: #ffffff
    padding: 24px

    &.hideHeader
      padding: 0 !important

    @include smDesktopWidth
        min-width: unset
        width: calc(100vw - 36px)
        padding: 12px

.header
    width: 100%
    display: flex
    justify-content: space-between
    align-items: flex-start
    margin-bottom: 12px
    gap: 24px
    word-break: break-word

    & > h3
      padding-top: 6px
      max-width: 550px
      @include limit-lines(3)


.wrapper
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.control
    position: absolute
    top: 24px
    left: 24px
    right: 24px
    display: flex
    align-items: center
    +m
        top: 16px
        left: 16px
        right: 16px
    .button
        +dark
            background: $n7
            box-shadow: inset 0 0 0 2px $n6
            color: $n1
            &:hover
                background: $n6

.close
    width: 36px
    min-width: 36px
    height: 36px
    margin-left: auto
    border-radius: 50%
    background: $n
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06)
    font-size: 0
    svg
        fill: $n5
        transition: transform .2s
    +dark
        background: $n7
        svg
            fill: $n1
    &:hover
        svg
            transform: rotate(90deg)
