@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  place-items: center;
  gap: 24px;

  @include desktopWidth {
    @include element-flex(column);
  }

  @include smDesktopWidth {
    padding: 0 10px 20px;
  }

  & > p {
    font-size: 70px;
    margin-top: 200px;
  }
}

.centerContent{
  @include element-flex(column, center, center);
  padding: 24px;
  background: #fff;
  border-radius: $radius-s;
  box-shadow: $shadow-main;

  & > * {
    max-width: 600px !important;
  }
}

.card {
  width: 100%;
  @include element-flex(row, stretch, flex-start);
  gap: 24px;
}

.title {
  padding-left: 24px;
  margin-bottom: 24px;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 24px;

  @include desktopWidth {
    @include element-flex(column);
  }

  &.top {
    @include desktopWidth {
      @include element-flex(row, stretch, center);
    }

    @include smDesktopWidth {
      @include element-flex(column);
    }
  }
}

.box {
  @include element-flex(column);
  padding: 24px 12px 24px 84px;
  height: auto;
  position: relative;
}

.amount {
  font-size: $font-size-s;
  line-height: 22px;
  font-weight: 700;
}

.text {
  font-size: $font-size-s;
  line-height: 22px;
  font-weight: 400;
  color: $font-color-light;
}

.icon {
  @include element-flex(column, center, center);
  width: 44px;
  height: 44px;
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
  padding: 12px;
  border-radius: 50%;
  background: $gray-color;

  & > svg > path,
  & > svg > g > path {
    fill: $blue-color;
  }
}

.topLineChart {
  @extend .card;
  flex: 6;
  width: 100%;
  height: 380px;
  padding: 24px 12px;

  @include smDesktopWidth {
    height: auto;
  }
}

.topPieChart {
  @extend .card;
  flex: 4;
  width: 100%;
  height: 380px;
  padding: 12px 12px 0 6px;

  @include smDesktopWidth {
    height: auto;
  }
}

.justifyLeft{
  justify-content: flex-start !important;
}

.buttonWrapper{
  align-items: flex-start !important;
}

.headerContainer{
  padding: 6px 24px;
}
