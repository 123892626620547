@import 'src/styles';

.container{
  width: 100%;
  @include element-flex(row, flex-end, center);
  gap: 24px;
}

.value {
  @include element-flex(column);
  margin-right: auto;
  gap: 3px;

  .title {
    margin: 0;
  }
}

.title {
  font-size: $font-size-xl;
  font-weight: $font-bold;
  margin-top: 6px;
  padding-top: 6px;

  &.center{
    width: 100%;
    text-align: center;
  }
}

.ship {
  @include element-flex(column);
  font-size: $font-size-m;
  color: $font-color-light;
  font-weight: 400;
  line-height: 22px;
}
