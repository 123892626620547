@import 'src/styles';

.container{
  @include element-flex(column);
  width: 100%;
}

.tableHeader {
  grid-template-columns: 1fr 80px 130px 110px;
  padding: 18px 6px 12px;
  gap: 6px;
  position: sticky;
  top: 0;
  background: #fff;

  &.horizontal{
    grid-template-columns: 100px 1fr 80px 130px 110px;

    @include smDesktopWidth{
      grid-template-columns: 70px 1fr 80px 100px 90px;
    }

    @include tabletWidth{
      display: none;
    }
  }
}

.textRight {
  text-align: left;
  margin-left: -3px;
}

.row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 80px 130px 110px;
  padding: 6px;
  gap: 6px;

  &.horizontal{
    grid-template-columns: 100px 1fr 80px 130px 110px;

    @include smDesktopWidth{
      grid-template-columns: 70px 1fr 80px 100px 90px;
    }

    @include tabletWidth{
      grid-template-columns: 2fr 3fr;
    }
  }

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }
}

.rowSupplierName {
  @include element-flex(row, flex-start, center);
  gap: 3px;
  max-width: fit-content;
}

.data {
  @include element-flex(row, flex-start, center);
}

.summary {
  font-size: $font-size-l;
  font-weight: $font-bold;

  &.twoColumns {
    grid-column: 1 / 3;
  }
}

.summaryRow {
  @extend .row;
  padding: 0 18px;
  background: transparent !important;
}

.title {
  font-size: $font-size-xxl;
  font-weight: $font-bold;
  text-align: center;
  width: 100%;
}

.tableWrapper {
  max-height: 350px;
  overflow: auto;
}

.table {
  box-shadow: $shadow-main;
  padding-top: 0;

  @include smDesktopWidth {
    max-height: 30vh;
  }
}
