@import 'src/styles';

.container{
  @include element-flex(column);
  width: 100%;
  max-width: 90vw;
  overflow: auto;

  @include smDesktopWidth{
    max-width: 95vw;
  }
}

.header{
  display: grid;
  grid-template-columns: 250px 80px repeat(3, 220px) 170px 90px repeat(4, 220px) 90px;
  gap: 3px;
  padding: 0;

  &.extended{
    grid-template-columns: 250px 80px 220px 293px 220px 170px 90px repeat(4, 220px) 90px;
  }
}

.division{
  @include element-flex(column);
  background: $blue-lightest-color;
  border-radius: $radius-s;
  padding: 3px 6px 0;

  &.admin{
    background: transparentize($danger-color, 0.2);
    justify-content: center;
    padding: 0 6px;
  }
}

.selectAll{
  @extend .division;
  justify-content: center;
  background: transparent;
}

.name {
  width: 100%;
  text-align: center;
  font-size: 15px;
}

.columns{
  @include element-flex(row, stretch, center);
  gap: 5px;
  place-items: center;
  height: 100%;
  text-align: center;
  width: 100%;
  padding: 3px 0;
}

.columnName{
  @include element-flex(row, center, center);
  gap: 3px;
  width: 100%;
  flex-grow: 1;
  min-width: 67px;
  text-align: center;
  line-height: 16px;
  cursor: pointer;
}

.membersWrapper{
  @include element-flex(column);
  gap: 3px;
  margin-top: 3px;
}