@import '../../../../styles';

.container {
  @include element-flex(column, flex-start, flex-end);
  width: 100%;
}

.input {
  margin-bottom: 48px;
  width: 100%;
}
