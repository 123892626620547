@import 'src/styles';

.container{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr) auto 1fr;
  gap: 24px;

  @include smDesktopWidth{
    @include element-flex(column);
  }
}