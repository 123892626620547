@import '../../styles';

.container {
  @include element-flex(column);
  gap: 36px;

  @include desktopWidth {
    @include element-flex(column, flex-start, stretch);
  }

  @include smDesktopWidth {
    padding: 0 10px;
    margin: 0;
  }
}

.column {
  width: 100%;
  @include element-flex(column);
  gap: 48px;
}

.header {
  grid-column: 1 / 3;
}

.box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(588px, 1fr));
  width: 100%;
  column-gap: 24px;
  row-gap: 36px;

  @include desktopWidth {
    @include element-flex(column);
  }
}
