@import '../../../../styles';

.container {
  width: 100%;
  @include element-flex(row, space-between, center);
  gap: 12px;
  padding: 12px 24px;
  border-radius: $radius-s;

  @include phoneWidth {
    @include element-flex(column);
    padding: 24px 12px;
  }

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }
}

.sumText {
  font-size: $font-size-m;

  &.final {
    font-size: $font-size-xl;
    font-weight: 700;
  }

  @include tabletWidth {
    font-size: $font-size-l;
  }
}

.sumAmount {
  font-size: $font-size-m;
  display: flex;
  align-items: center;
  gap: $spacing-2;

  &.final {
    font-size: $font-size-xl;
    font-weight: 700;
  }

  @include tabletWidth {
    font-size: $font-size-l;
    font-weight: 700;
  }
}

.gapRemove {
  gap: unset;
  column-gap: unset;
  row-gap: unset;
}
