@import 'src/styles';

.suppliers {
  @include element-flex(column);
  gap: 24px;
  margin: 0;
  padding-bottom: 24px;

  @include smDesktopWidth {
    margin: 0 10px;
    width: calc(100% - 20px);
  }
}

.header {
  grid-template-columns: 20px 1fr 130px 60px 90px 90px 110px 140px;
  gap: 12px;
  padding: 18px 12px;
  width: 100%;

  @include lgDesktopWidth {
    display: none;
  }

  @include printView {
    grid-template-columns: 20px 1fr 130px 60px 90px 90px;
  }
}

.buttonWrapper {
  @include element-flex(row, center, center);
  width: 100%;
  padding: 24px 0 0;
}

.checkbox {
  width: auto !important;
  margin-left: auto;
}

.link {
  font-size: $font-size-m;
  font-weight: normal;
  cursor: pointer;
  @include printView {
    display: none;
  }
}

.orderComment {
  @include element-flex(column);
  gap: 6px;
  padding: 12px 0;
  flex: 1;
}

.tag {
  max-width: unset !important;
}

.giftsAndComment {
  @include element-flex(column, flex-start, stretch);
  gap: 12px;
}

.comment {
  width: 100%;
  font-size: $font-size-s;
  line-height: normal;
  font-weight: normal;
  padding: 12px 24px;
  background: $gray-color;
  border-radius: $radius-s;

  @include tabletWidth {
    font-size: $font-size-m;
    line-height: 24px;
    padding: 24px 12px;
  }
}

.gifts {
  background: $gray-color;
}

.menu {
  position: static !important;
}

.commentTitle {
  @include printView {
    display: none;
  }
}

.orderTracing {
  @include printView {
    display: none;
  }
}

.topSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 24px;
  padding: 0 12px;
}

.buttons {
  display: flex;
  gap: 24px;
}