@import 'src/styles';

.container {
  width: 100%;
  @include element-flex(row, flex-start, center);
  gap: 24px;
  margin-bottom: 20px;
  z-index: 2;
  padding: 24px;
  border-radius: $radius-s;
  box-shadow: $shadow-main;
  background: #ffffff;

  @include smDesktopWidth {
    @include element-flex(column, flex-start, stretch);
    padding: 18px 10px;
  }
}

.wrapper {
  width: auto;
  min-width: 150px;
}
