@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.button {
  background-color: $background-gray;
  color: $font-color;
  border: $border-main;
  border-radius: $radius-s;

  font-weight: $font-medium;
  font-size: 15px;
  white-space: nowrap;
  padding: 10px 16px;

  transition: $transition-main;

  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;

  @include desktopWidth {
    font-size: 12px;
    padding: 5px 12px;
  }

  @include tabletWidth {
    padding: 3px 10px;
  }
}

a.button:hover {
  cursor: pointer;
  color: $blue-color;
  border-color: $blue-color;
}

.blue {
  border: none;
  color: white;
  background-color: $blue-color;
}

a.blue:hover {
  color: white;
  background-color: $blue-dark-color;
}
