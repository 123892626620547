@import 'src/styles';

.container {
  @include element-flex(column);
  align-items: flex-end;
  gap: 24px;
  width: 100%;
  padding-bottom: 50px;

  @include desktopWidth {
    padding: 12px;
  }
}

.header {
  grid-template-columns: 1fr 200px 200px;
  gap: 24px;
}

.tag {
  max-width: unset !important;
}

.title {
  @include printView {
    margin: 36px 0 0 18px;
  }
}

.card{
  width: 100%;
}

.topWrapper{
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 24px;
}
