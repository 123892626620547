.panel
    position: relative
    display: flex
    align-items: center
    margin: auto -40px -40px
    padding: 60px 40px 20px
    +x
        margin: auto -24px -32px
        padding: 44px 24px 20px
    +m
        margin: auto -16px -24px
        padding: 40px 16px 16px
    &:before
        content: ""
        position: absolute
        top: 40px
        left: 0
        right: 0
        bottom: 0
        z-index: -1
        background: $n1
        box-shadow: inset 1px 0 0 $n2
        +x
            top: 24px
        +dark-common
            background: $n7
            box-shadow: inset 1px 0 0 $n8
