@import '../../../../styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 36px;

  @include customBreakpoint(1250px) {
    grid-template-columns: 100%;
  }
}

.wrapper {
  @include element-flex(column);
  width: 100%;
  background: #ffffff;
  padding: 24px;
  gap: 6px;
  border-radius: $radius-s;
}

.wrapper_costs {
  @include element-flex(column);
  gap: 48px;
  width: 100%;
  background: #ffffff;
  padding: 24px;
  border-radius: $radius-s;

  @include desktopWidth {
    @include element-flex(row);
  }

  @include tabletWidth {
    @include element-flex(column);
    gap: 36px;
  }
}

.title {
  font-size: $font-size-l;
  font-weight: bold;
  margin-bottom: 12px;

  &.withoutMargin {
    margin: 0;
  }
}

.box {
  @include element-flex(column);

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
}

.errorMessage {
  color: $danger-color;
  font-size: $font-size-xs;
  animation: fadeIn 0.4s;
  width: 100%;
  margin-top: 4px;
  font-weight: 700;
}

.addShippingForm {
  @include element-flex(column);
  width: 100%;
  gap: 12px;
  padding: 12px;
  border-radius: $radius-s;
  background: $blue-lightest-color;
  margin-top: 12px;
}

.shippingButton {
  margin-left: auto;
}

.shippingLink {
  text-align: center;
  width: 100%;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
