@import 'src/styles';

.container{
  width: 100%;

}

.button {
  position: fixed;
  z-index: 1;
  right: 100px;
  bottom: 20px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 10px rgb(0 0 0 / 30%);
  transition: box-shadow 0.2s;
  background: #fff;
  animation: fadeIn .2s;

  &:hover {
    box-shadow: 0 0 15px rgb(0 0 0 / 30%);
  }
}

.icon {
  width: 60px;
  height: 60px;
  transform: scale(0.5) rotateZ(180deg);
  & > * {
    fill: $blue-color;
  }
}

@keyframes fadeIn {
  @include keyframe-fadeIn();
}