@import ../../../styles/helpers
@import ../../../styles

.header
    position: fixed
    top: 0
    left: 260px
    right: 0
    z-index: 15
    display: flex
    align-items: center
    padding: 12px 40px
    height: 68px
    background: #F2F5F9
    border-bottom: 1px solid rgba(137, 169, 212, 0.24)

    @include printView
        display: none

    @include smDesktopWidth
        transition: transform .2s

    &.isSidebarClose
        left: 100px

    &.isMobileOpen
        transform: translateX(260px)

    &.isAdminView
        top: 40px !important

    @include smDesktopWidth
        padding: 12px 20px
        left: 0 !important

.control
    display: flex
    align-items: center
    margin-left: auto

.companyWrapper
    @include element-flex(row, flex-start, center)
    min-width: 280px

.control .button,
.control > div,
.buttonSearch
    &:not(:last-child)
        margin-right: 24px

.buttonSearch
    display: none
    width: 48px
    height: 48px
    +m
        display: inline-block
    svg
        fill: $n4
        transition: fill .2s
    &.active
        svg
            fill: $n7
            +dark
                fill: $n1

.control .button
    +t
        display: none

.btns
    display: flex
    align-items: center
    margin-left: auto
    +m
        margin-left: 0

.link
    margin-right: 32px
    +button1
    color: $n7
    transition: color .2s
    +m
        margin-right: 24px
    +dark
        color: $n1
    &:hover
        color: $p1
