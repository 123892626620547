@import '../../../styles';

.container {
  @include element-flex(column, flex-end, flex-end);
  width: 100%;
  gap: 6px;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: -3px;
}

.modal {
  width: 600px !important;
}

.comment {
  max-width: 600px;
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: normal;
  word-break: break-all;
}

.button {
  background: transparent;
  padding: 3px;
  border-radius: $radius-s;
  transition: background 0.2s;

  &:hover {
    background: transparentize($blue-light-color, 0.9);
  }
}
