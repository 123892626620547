@import 'src/styles';

.progressBar {
  position: relative;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  background: conic-gradient(#7d2ae8 3.6deg, #eded 0deg);
  display: flex;
  align-items: center;
  justify-content: center;


  &::before {
    content: '';
    position: absolute;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background-color: white;
  }
}
.value {
  position: relative;
  font-size: $font-size-xxl;
}

.label {
  position: absolute;
  bottom: 25px;
color: $font-color-light;
}