@import 'src/styles';
@import '../../variables';

.container {
  width: 100%;
}

.header {
  grid-template-columns: $gross-grid-template-columns;
  gap: 12px;
  padding: 18px 12px;

  position: sticky;
  top: 68px;
  background: #ffffff;
  z-index: 2;

  &.headerNetto {
    grid-template-columns: $net-grid-template-columns;
  }

  &.publicSticky {
    top: 0 !important;
  }

  @include customBreakpoint(1400px) {
    display: none;
  }
}

.value {
  text-align: center;
}

.table {
  row-gap: 12px;

  @include desktopWidth {
    row-gap: 36px;
  }
}

.title{
  @include element-flex(row, flex-start, center);
  gap: 6px;
  width: 100%;
  background: $blue-lightest-color;
  border-radius: $radius-s;
  font-size: 18px;
  font-weight: 700;
  padding: 18px 12px;
  margin-bottom: 6px;

  &.notCollection{
    background: $yellow-light-color;
  }
}
