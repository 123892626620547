@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.loader {
  @include element-flex(row, center, center);
  min-height: 600px;
}

.container {
  @include element-flex(column);
  max-width: 700px;
  margin: 60px auto 0;

  @include tabletWidth {
    margin: 40px auto 0;
  }

  @include phoneWidth {
    margin: 20px auto 0;
  }
}

.thumbnail {
  margin: 60px auto 120px;
  border-radius: $radius-s;
  overflow: hidden;
  width: 100%;
  height: 400px;

  @include tabletWidth {
    margin: 40px auto 80px;
    height: 300px;
  }

  @include phoneWidth {
    margin: 30px auto 60px;
    height: 230px;
  }
}

.img {
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  transition: $transition-smooth-end;
}

.content {
  width: 100%;

  & > h3 {
    color: $blue-color !important;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 25px;

    @include desktopWidth {
      font-size: 34px;
      line-height: 40px;
    }

    @include phoneWidth {
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 15px;
    }
  }

  & p {
    font-size: 22px;
    margin-bottom: 45px;

    @include desktopWidth {
      font-size: 18px;
    }

    @include phoneWidth {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }
}

.top {
  @include element-flex(row);
  gap: 30px;
  margin-bottom: 20px;
}

.date,
.time {
  display: flex;
  font-size: 17px;
  font-weight: $font-regular;

  @include desktopWidth {
    font-size: 15px;
  }

  @include phoneWidth {
    font-size: 13px;
  }

  & > img {
    max-width: 30px;
    margin-right: 10px;

    @include desktopWidth {
      max-width: 26px;
    }

    @include phoneWidth {
      max-width: 20px;
    }
  }

  & > div {
    color: $font-color;
  }
}

.date {
  font-weight: $font-bold;
}

.divider {
  width: 22px;
  align-self: center;
}

.excerpt {
  margin-top: 24px;
  font-size: $font-size-m;
}

.articleTitle {
  color: $blue-color;
}
