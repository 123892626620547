@import 'src/styles';

.header {
  gap: 12px;
  display: grid;
  align-items: center;
  grid-template-columns: 20px 1fr 140px 110px 40px;

  @include printView {
    grid-template-columns: 1fr 120px 100px 40px;
  }

  &.admin {
    grid-template-columns:  20px 1fr 140px 140px 110px 120px 40px;

    @include printView {
      grid-template-columns: 1fr 100px 110px 110px 40px;
    }

    @include customBreakpoint(1150px) {
      @include element-flex(row, flex-end, flex-start);
      & > p {
        display: none;
      }
    }
  }

  @include smDesktopWidth {
    @include element-flex(row, flex-end, flex-start);
    & > p {
      display: none;
    }
  }

}

.checkboxWrapper {
  @include smDesktopWidth {
    width: auto;
  }
}

.checkbox {
  @include printView {
    display: none;
  }
}

.referencePrice {
  @include printView {
    display: none;
  }
}
