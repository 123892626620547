@import 'src/styles';

.container {
  @include element-flex(column);
  gap: 6px;
  width: 100%;
  padding: 24px;
  border-radius: $radius-s;
  box-shadow: $shadow-main;
  background-color: #fff;

  @include smDesktopWidth {
    width: calc(100% - 20px);
    margin: 0 10px;
    padding: 18px 10px;
  }
}

.wrapper {
  grid-template-columns: 2fr   1fr 1fr 1fr;
  grid-gap: 24px;
  gap: 24px;
  display: grid;
  width: 100%;
  padding: 3px 0;


  &.status {
    grid-template-columns:  100px 2fr 1fr 1fr 1fr 1fr;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(176, 176, 176, 0.3);
  }

  &.header {
    @include smDesktopWidth {
      display: none;
    }
  }

  @include smDesktopWidth {
    @include element-flex(column);
    gap: 12px;
    padding: 18px 10px;
    border-radius: $radius-s;
    border: none !important;

    &:nth-of-type(even) {
      background: $odd-row-background;
    }
  }
}

.amount {
  font-size: $font-size-m;
  color: $font-color;
  font-weight: normal;

  width: 100%;
  display: flex;
  align-items: center;

  & > p {
    flex-basis: 100%;
  }

  @include smDesktopWidth {
    font-size: $font-size-l;
    flex-wrap: wrap;
  }
}

.tag {
  width: 100%;
  text-align: center;
}

.text {
  @include element-flex(row, flex-start, center);
  flex-wrap: wrap;
  gap: 6px;
  font-size: $font-size-m;
  color: $font-color;
  font-weight: normal;

  @include smDesktopWidth {
    font-size: $font-size-xl;
    font-weight: 700;
  }
}

.tableTitle {
  @include element-flex(row, flex-start, center);
  gap: 6px;
  font-size: $font-size-xl;
  font-weight: 800;
  color: $font-color;
}

.suppliersQty {
  font-size: $font-size-s;
  color: $font-color-light;
}

.title {
  font-size: $font-size-m;
  font-weight: $font-bold;
  margin-bottom: 12px;

  @include smDesktopWidth {
    font-size: $font-size-l;
  }
}

.sum {
  font-size: $font-size-m;
  font-weight: 700;

  &.sumStatus {
    grid-column: 1 / 3;
  }
}

.productsNumber {
  font-size: $font-size-s;
  color: $font-color-light;
}

.info{
  margin-left: 6px;
}

