@import '../../../styles';

.container {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  padding: 12px 0 0 0;
  gap: 12px;
  grid-column: 1 / 7;
  position: relative;

  &.net {
    grid-column: 1 / 8;
  }

  &.saved {
    position: relative;

    &:after {
      content: 'zapisano';
      font-size: 10px;
      color: $font-color-light;
      height: 100px;
      position: absolute;
      right: 18px;
      top: 18px;
    }
  }
}

.textArea {
  width: 100%;
  padding: 12px;
  border-radius: $radius-s;
  border: 1px solid $border-color-light;
  min-height: 90px;
  height: auto;
  font-size: 15px;
  font-weight: 700;
  resize: vertical;
  transition: border 0.2s, box-shadow 0.2s;

  &.hideBorder{
    border: none !important;
    &:hover,
    &:focus,
    &:active{
      border: none !important;
      box-shadow: none !important;
    }
  }

  &.singleLine{
    height: 40px;
    min-height: 40px;
    max-height: 200px;
    line-height: 24px;
    padding: 6px 8px;
  }

  &.saved {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid transparent;
    height: auto;
  }

  &.resizeDisabled {
    resize: none;
  }

  &:hover {
    border: 1px solid $input-hover-color;
  }

  &:focus {
    border: 1px solid #4849ff;
    box-shadow: 0 0 0 1px #4849ff;
  }

  &:active {
    border: 1px solid rgba(137, 169, 212, 0.48) !important;
  }

  &.disabled {
    pointer-events: none;
    background: $disabled-input-background;
  }

  &.error {
    border: 1px solid $danger-color;
  }

  &::placeholder {
    color: #9a9fa5;
  }

  &:focus.error {
    border: 1px solid $danger-color;
    box-shadow: 0 0 0 1px $danger-color;
  }
}

.saveButton {
  width: 32px;
  height: 32px;
  margin-left: auto;
}

.column {
  @include element-flex(column, flex-start, flex-end);
  width: 100%;
  gap: 6px;
}
