@import 'src/styles';

.container {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  position: relative;

  &.smaller {
    max-width: 1200px;
  }
}

.header {
  grid-template-columns: 1fr;
  gap: 12px;
  padding: 6px 6px 18px;
  z-index: 2;
  min-height: 60px;
  place-items: center start;
}

.contentWrapper {
  @include element-flex(row);
  width: 100%;

  @include smDesktopWidth {
    padding: 0 8px 0 20px;
  }

  @include tabletWidth {
    padding: 0;
  }
}

.leftColumn {
  flex-grow: 1;

  @include tabletWidth {
    margin-left: 10px;
  }

  &.horizontal{
    @include tabletWidth {
      margin: 0 10px;
    }
  }
}

.listCommentWrapper {
  @include element-flex(row, flex-start, center);
}
.tableWrapper {
  border-radius: $radius-s 0 0 $radius-s;
  padding: 12px 0 12px 12px;

  &.horizontal {
    border-radius: $radius-s;
    padding: 12px
  }
}

.optionsWrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  border-radius: $radius-s $radius-s 0 0;
  position: relative;
  min-height: 310px;
  padding: 0 12px 0 0;

  &.horizontal{
    grid-template-columns: 412px 1fr;
    min-height: unset;
    padding: 0 0 24px;

    @include lgDesktopWidth{
      grid-template-columns: 2fr 3fr;
    }

    @include desktopWidth{
      @include element-flex(column);
    }
  }
}

.optionsInfo {
  @include element-flex(column, stretch);
  width: 100%;
  padding: 18px 12px 18px 0;
  gap: 12px;
  max-width: 350px;
}

.text {
  font-size: $font-size-m;
  font-weight: $font-bold;
  line-height: 24px;
}

.label {
  font-size: $font-size-m;
  font-weight: $font-bold;
  line-height: 36px;
}

.bottomSection {
  @include element-flex(row, flex-start, center);
  height: 124px;
  background-color: $odd-row-background;
  border-radius: $radius-s 0 0 $radius-s;
  margin-top: 24px;
}

.valuationProgress {
  @include element-flex(column, center);
  flex-grow: 1;
  padding: 12px 24px;
  gap: 6px;
  width: 100%;
  background: $odd-row-background;
  border-radius: $radius-s;
  max-width: 400px;
  transform: translate(-24px, 12px);
}

.progress {
  padding: 0;
  align-items: flex-start;
  justify-content: flex-start;

  & > p {
    font-size: 14px;
    padding-bottom: 3px;
  }
}

.details {
  font-size: $font-size-s;
  color: $blue-color;
  transition: all 0.2s;
  cursor: pointer;
  margin-top: 24px;

  &:hover {
    text-decoration: underline;
    color: $blue-dark-color;
  }
}

.menu {
  position: static !important;
}

.orderPrice {
  font-size: $font-size-xxl;
  font-weight: $font-bold;
  line-height: 36px;
}

.savings {
  @include element-flex(row, flex-start, center);
  color: $font-color;
  margin-top: 3px;

  .text {
    font-size: $font-size-m;
    font-weight: normal;
  }

  .tag {
    max-width: unset !important;
    width: 100%;
    animation: fadeIn 0.2s forwards;
  }

  .strong {
    font-weight: 700;
  }
}

.icon {
  width: 24px;
  height: 20px;
  padding-right: 6px;
}

.shippingPrice {
  font-size: $font-size-s;
  color: $font-color-light;
  line-height: 17px;
  font-weight: normal;
}

.priceWrapper {
  @include element-flex(column);
  height: auto;
}

.product {
  min-height: 200px !important;
  padding-left: 12px;
  gap: 24px;
}

.rightColumn {
  @include element-flex(row, stretch);
  border-radius: 0 $radius-s $radius-s 0;
  max-width: 1000px;
  width: 100%;
  padding-right: 12px;

  @include tabletWidth {
    padding-right: 0;
    max-width: unset;
    width: 100%;
  }

  //container width with closed Sidebar
  &.onlyOne {
    max-width: 300px;

    @include tabletWidth {
      padding-right: 0;
      max-width: unset;
      width: 100%;
    }
  }

  &.onlyTwo {
    max-width: 550px;

    @include customBreakpoint(1300px) {
      max-width: 500px;
    }

    @include customBreakpoint(880px) {
      max-width: 300px;
    }

    @include tabletWidth {
      padding-right: 0;
      max-width: unset;
      width: 100%;
    }
  }

  &.onlyThree {
    max-width: 770px;

    @include customBreakpoint(1400px) {
      max-width: 700px;
    }

    @include customBreakpoint(1300px) {
      max-width: 650px;
    }

    @include customBreakpoint(1150px) {
      max-width: 550px;
    }

    @include customBreakpoint(1050px) {
      max-width: 500px;
    }

    @include tabletWidth {
      padding-right: 0;
      max-width: unset;
      width: 100%;
    }
  }

  @include customBreakpoint(1550px) {
    max-width: 850px;
  }

  @include customBreakpoint(1400px) {
    max-width: 780px;
  }

  @include customBreakpoint(1300px) {
    max-width: 650px;
  }

  @include customBreakpoint(1150px) {
    max-width: 550px;
  }

  @include customBreakpoint(1050px) {
    max-width: 500px;
  }

  @include customBreakpoint(880px) {
    max-width: 300px;
  }

  @include tabletWidth {
    padding-right: 0;
    max-width: unset;
    width: 100%;
  }
}

.rightColumnOpenSidebar {
  &.onlyOne {
    max-width: 300px;

    @include tabletWidth {
      padding-right: 0;
      max-width: unset;
      width: 100%;
    }
  }

  &.onlyTwo {
    max-width: 550px;

    @include customBreakpoint(1220px) {
      max-width: 480px;
    }

    @include customBreakpoint(1150px) {
      max-width: 300px;
    }

    @include smDesktopWidth {
      max-width: 550px;
    }

    @include customBreakpoint(940px) {
      max-width: 500px;
    }

    @include customBreakpoint(880px) {
      max-width: 350px;
    }

    @include tabletWidth {
      max-width: 300px;
    }

    @include tabletWidth {
      padding-right: 0;
      max-width: unset;
      width: 100%;
    }
  }

  &.onlyThree {
    max-width: 770px;

    @include customBreakpoint(1430px) {
      max-width: 700px;
    }

    @include customBreakpoint(1380px) {
      max-width: 650px;
    }

    @include customBreakpoint(1350px) {
      max-width: 550px;
    }

    @include customBreakpoint(1220px) {
      max-width: 480px;
    }

    @include customBreakpoint(1150px) {
      max-width: 300px;
    }

    @include smDesktopWidth {
      max-width: 550px;
    }

    @include customBreakpoint(940px) {
      max-width: 500px;
    }

    @include customBreakpoint(880px) {
      max-width: 350px;
    }

    @include tabletWidth {
      padding-right: 0;
      max-width: unset;
      width: 100%;
    }
  }

  @include customBreakpoint(1700px) {
    max-width: 950px;
  }

  @include customBreakpoint(1650px) {
    max-width: 900px;
  }

  @include customBreakpoint(1600px) {
    max-width: 750px;
  }

  @include customBreakpoint(1430px) {
    max-width: 700px;
  }

  @include customBreakpoint(1380px) {
    max-width: 650px;
  }

  @include customBreakpoint(1350px) {
    max-width: 550px;
  }

  @include customBreakpoint(1220px) {
    max-width: 480px;
  }

  @include customBreakpoint(1150px) {
    max-width: 300px;
  }

  @include smDesktopWidth {
    max-width: 550px;
  }

  @include customBreakpoint(940px) {
    max-width: 500px;
  }

  @include customBreakpoint(880px) {
    max-width: 350px;
  }

  @include tabletWidth {
    padding-right: 0;
    max-width: unset;
    width: 100%;
  }
}

@keyframes fadeIn {
  @include keyframe-fadeIn;
}

.link{
  cursor: pointer;
}
