@import 'src/styles';

.container{
  width: 100%;
  @include element-flex(column, center, flex-start);
  gap: 12px;
}

.wrapper{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  width: 100%;
}

.tag{
  text-align: center;
  max-width: 150px;
}