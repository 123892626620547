@import 'src/styles';

.container {
  width: 100%;
}

.header {
  display: inline-grid;
  grid-template-columns: 30px 200px 120px 40px 100px 100px 100px;
  gap: 24px;
  flex: 1;
  padding: 0 12px;

  @include desktopWidth {
    display: none;
  }
}

.buttonWrapper {
  @include element-flex(row, center, center);
  margin-top: 12px;
}
