@import 'src/styles';

.wrapper{
  max-width: 270px;
}

.SingleDatePicker,
.DateRangePicker {
  border-radius: $radius-s;
  transition: all 0.2s;
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-shadow: none;
  height: 40px;
  padding: 2px 8px;
  box-sizing: border-box;
  background: #fff;
  width: 100%;
  max-width: 270px;

  input{
    font-weight: 700;
    font-family: 'Lato', sans-serif;
    border-radius: $radius-s;
    transition: all 0.2s;
    cursor: pointer;
    text-align: center;
  }

  &:hover {
    border: 1px solid $input-hover-color;
  }

  &:focus-within {
    border: 1px solid #4849ff;
    box-shadow: 0 0 0 1px #4849ff;
  }

  &:focus.error {
    border: 1px solid $danger-color;
    box-shadow: 0 0 0 1px $danger-color;
  }
}
