@import '../../../styles';

$uploader-size: 100px;

.container {
  @include element-flex(row, center);
}

.wrapper {
  width: $large-uploader;
  height: $large-uploader;
  aspect-ratio: 1 / 1;
  display: grid;
  place-items: center;
  position: relative;
  z-index: 1;

  &.small {
    width: $small-uploader;
    height: $small-uploader;
  }

  &:hover,
  &:focus {
    .clearBtn {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
}

.imageWrapper {
  width: $large-uploader;
  height: $large-uploader;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: $shadow-main;

  &.product {
    border-radius: 25px;
  }

  &.small {
    width: $small-uploader;
    height: $small-uploader;
  }

  &.product.small {
    border-radius: 20px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  z-index: 1;

  &.product {
    border-radius: 25px;
  }
}

.button {
  width: 36px;
  height: 36px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: $blue-color;
  z-index: 2;
  box-shadow: $shadow-main;
}

.inputFile {
  visibility: hidden;
  height: 0;
  width: 0;
}
