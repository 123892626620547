@import 'src/styles';

.container{
  width: 800px;
}

.topHeader{
  margin-bottom: 12px;
  @include element-flex(column, center, center);
  gap: 3px;
  background: $gray-lighter-color;
  border-radius: $radius-s;
  padding: 12px 6px
}

.text{
  font-size: 15px;
  height: 22px;
}

.box{
  @include element-flex(column);
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
}

.tableHeader{
  display: grid;
  grid-template-columns: 40px 1fr 250px 150px;
  gap: 12px;
  padding: 12px 6px;
  border: none;
  position: sticky;
  background: #fff;
  top: 0;
  width: 100%;
  z-index: 2;
}

.buttonWrapper{
  @include element-flex(row, center, center);
  gap: 24px;
  margin-top: 24px;
}