@import 'vars';
@import 'responsive';
@import 'colors';

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }

  :-moz-placeholder {
    @content;
  }

  ::-moz-placeholder {
    @content;
  }

  :-ms-input-placeholder {
    @content;
  }
}

@mixin shadow-on-focus() {
  &:focus {
    box-shadow: inset 0 0 0 2px transparentize($blue-light-color, 0.25);
  }
}

@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

@mixin constraint-width {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
}

@mixin responsive-padding {
  padding: $padding-main-container;

  @include smDesktopWidth {
    padding: $spacing-2 0;
  }
}

@mixin keyframe-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@mixin disabled-styling {
  opacity: 0.8;
  cursor: not-allowed;
}

@mixin disabled-ui {
  > * {
    @include disabled-styling;
  }

  @include disabled-styling;
}

@mixin limit-lines($lines-amount) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines-amount;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

@mixin trimText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin element-flex($direction, $justify: 'flex-start', $align: 'flex-start') {
  display: flex;
  flex-direction: $direction;
  justify-content: unquote($justify);
  align-items: unquote($align);
}

@mixin disableHighlight {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  -webkit-user-select: none;
}

@mixin keyframe-fadeInAndScale {
  0% {
    opacity: 0;
    max-height: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    max-height: 200vh;
  }
}

@mixin dashedBorder($color) {
  position: relative;

  &::after {
    content: '';
    z-index: -1;
    width: calc(100% + 2px);
    height: calc(100% - 28px);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px dashed $color;
    animation: fadeIn 0.2s;
  }
}
