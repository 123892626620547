@import 'src/styles';

// colors
$background-default: white;
$background-gray: #f6f7f9;

$border-main: 1px solid $gray-light-color;

$shadow-soft: 0 3px 10px 0 rgb(8 15 52 / 4%);
$shadow-img: 0 36px 81px 0 rgb(8 33 73 / 6%), 0 15px 36px 0 rgb(20 25 43 / 1%);

// sizes
$txt-max: 741px;
$margin-page: 0 calc(50% - 564px);

// typography
$line-height-m: 24px;
$line-height-l: 48px;
$line-height-up: 1.375em;
$line-height-up-2: 1.4em;
$line-height-up-3: 1.118em;

$letter-spacing-s: 0;
$letter-spacing-m: 0.01em;
$letter-spacing-l: 0.02em;

// transitions
$transition-main: 0.4s ease-in-out;
$transition-smooth-end: 0.4s cubic-bezier(0.25, 0.4, 0.3, 1);

.defaults {
  color: $font-color;
  background-color: $background-default;

  & * {
    line-height: $line-height-m;
  }

  & h1,
  & h2 {
    font-size: 36px;
    font-weight: $font-extraBold;
    line-height: $line-height-l;
    letter-spacing: $letter-spacing-l;

    @include desktopWidth {
      font-size: 28px;
      line-height: 34px;
    }

    @include phoneWidth {
      font-size: 20px;
      line-height: 26px;
    }
  }

  & h3 {
    font-size: $font-size-xl;
    color: $blue-color;
    font-weight: $font-bold;
    letter-spacing: $letter-spacing-l;

    @include desktopWidth {
      font-size: 16px;
      line-height: 20px;
    }

    @include phoneWidth {
      font-size: 13px;
      line-height: 17px;
    }
  }

  & p {
    font-size: $font-size-m;

    @include desktopWidth {
      font-size: 14px;
      line-height: 20px;
    }

    @include phoneWidth {
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.landingWrapper {
  @include smDesktopWidth {
    overflow-x: hidden;
  }
}

.button {
  @include phoneWidth {
    font-size: 14px;
  }
}

.buttons-wrapper {
  display: flex;
  gap: $spacing-6;

  flex-wrap: wrap;

  &--center {
    justify-content: center;
  }

  @include tabletWidth {
    justify-content: center;
    gap: $spacing-2;
  }
}

.tilesContainer {
  padding: 0 40px;
}
