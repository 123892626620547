@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.container {
  @include element-flex(row, space-between, center);
  padding: 88px 44px 88px 49px;
  margin-bottom: 60px;

  background-color: $background-gray;
  border-radius: $radius-s;

  & > h2 {
    margin-right: 40px;
    max-width: 451px;
    font-weight: $font-bold;

    @include smDesktopWidth {
      margin-right: 25px;
    }

    @include tabletWidth {
      margin-bottom: 30px;
    }
  }

  @include tabletWidth {
    @include element-flex(column);
  }
}

.input {
  padding-right: 160px;
}
