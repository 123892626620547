@import '../../styles/index';

.container {
  width: 100%;

  @include smDesktopWidth {
    padding: 0 10px 48px;
  }
}

.button {
  width: max-content;

  @include smDesktopWidth {
    order: 1;
  }
}

.searchWrapper {
  @include element-flex(row, flex-end, center);
  gap: 24px;

  @include smDesktopWidth {
    @include element-flex(column, flex-start, stretch);
    width: 100%;
    margin-bottom: 16px;
  }
}
