@import 'src/styles';

.wrapper{
  @include element-flex(column);
  width: 100%;
  gap: 12px;
}

.tableTitle {
  @include element-flex(row, flex-start, center);
  font-size: $font-size-xl;
  color: $font-color;
  font-weight: 800;
  gap: 6px;
}

.headerWrapper{
  @include element-flex(row, space-between, center);
  gap: 24px;
  width: 100%;
  padding-right: 24px;
}

.readAll{
  font-size: 15px;
  @include element-flex(row, flex-end, center);
  gap: 24px;
}