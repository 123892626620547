@import 'src/styles';

$grid-template: 500px repeat(6, 1fr) 200px;

.container {
  @include element-flex(column);
  padding: 0 0 20px;
  gap: 24px;

  @include smDesktopWidth {
    padding: 20px 10px;
  }
}

.filtering {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr repeat(2, 1fr);
  gap: 24px;

  @include smDesktopWidth {
    @include element-flex(column, flex-start, stretch);
    gap: 12px;
  }
}

.mainGrid {
  width: 100%;
  display: grid;
  grid-template-columns: $grid-template;
  gap: 6px;
}

.tableWrapper {
  @include element-flex(column, flex-start, center);
  width: 100%;
  background: #fff;
  border-radius: $radius-s;
  padding: 12px;
}

.tableHeader {
  @extend .mainGrid;
  place-items: center;
  padding: 6px;
  background: $blue-lightest-color;
  border-radius: $radius-s $radius-s 0 0;
}

.product {
  width: 100%;
  @include element-flex(row, stretch, center);
  gap: 6px;
  border-radius: $radius-s;
  padding: 6px;

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  @include desktopWidth {
    grid-template-columns: 400px 1fr;
  }

  @include smDesktopWidth {
    grid-template-columns: 1fr;
    gap: 12px;
    padding: 12px 6px;
  }
}

.producer {
  @include element-flex(row, center, center);
  color: blue;
}

.productDetails {
  @include element-flex(row, flex-start, center);
  width: 450px;
  max-width: 450px;
  min-width: 450px;
  gap: 6px;

  & > p {
    font-size: $font-size-s;
  }

  @include desktopWidth {
    width: 400px;
    max-width: 400px;
    min-width: 400px;
  }

  @include smDesktopWidth {
    width: 100%;
    max-width: unset;
    min-width: unset;
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 6px;

  &.list {
    flex-wrap: wrap;
    flex-grow: 1;
  }
}

.name {
  @include element-flex(row, flex-start, center);
}

.checkbox {
  width: auto;
}

.tag {
  width: 110px;
  text-align: center;
  cursor: pointer;

  &.loading{
    animation: loading 0.7s ease alternate infinite;
  }
}

.tagButton{
  text-align: center;
  cursor: pointer;
}

.checkbox {
  width: auto;
}

.checkboxAll {
  padding: 6px;
  margin-bottom: 6px;
  background-color: $blue-lightest-color;
  border-radius: $radius-s;
}

@keyframes loading {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.9;
  }
}

.button{
  background: $yellow-color;
  border-radius: $radius-s;
  font-weight: 700;
  max-width: 110px;
  font-size: 14px;
  padding: 4px;
}