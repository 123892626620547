@import 'src/components/layout/PublicPages/PublicPages.module.scss';
@import 'src/styles';

.loader {
  @include element-flex(row, center, center);
  min-height: 200px;
}

.container {
  @include element-flex(column);
  gap: 25px;
  width: 100%;

  @include tabletWidth {
    padding-bottom: 10px;
    max-width: 400px;
  }

  &:hover .content > h3 {
    color: $blue-color !important;
  }

  &:hover .img {
    transform: scale(1.05);
  }

  &.inDashboard {
    width: calc(100% - 24px);
    margin: 0 12px;
    background-color: #fff;
    padding: 24px;
    border-radius: $radius-s;

    @include smDesktopWidth {
      padding: 18px 10px;
      width: calc(100% - 12px);
      margin: 0 6px;
    }
  }
}

.thumbnail {
  border-radius: $radius-s;
  overflow: hidden;
  width: 100%;
  height: 230px;

  @include desktopWidth {
    height: 200px;
  }

  @include smDesktopWidth {
    height: 160px;
  }
}

.img {
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  transition: $transition-smooth-end;
}

.content {
  width: 100%;
}

.title {
  color: $font-color !important;
  font-size: $font-size-xxl;
  @include limit-lines(2);
  text-decoration: none;
  cursor: pointer;

  @include desktopWidth {
    font-size: 18px !important;
  }

  @include tabletWidth {
    font-size: 16px !important;
  }
}

.top {
  @include element-flex(row, space-between);
  gap: 10px;
}

.date,
.time {
  display: flex;
  justify-content: flex-end;
  font-weight: $font-regular;

  @include smDesktopWidth {
    font-size: 0.9em;
  }

  @include phoneWidth {
    font-size: 0.9em;
  }

  & > img {
    max-width: 18px;
    margin-right: 10px;

    @include desktopWidth {
      max-width: 12px;
      margin-right: 6px;
    }
  }

  & > div {
    color: $blue-color;
  }
}

.time {
  width: 40%;

  & > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.divider {
  margin: 25px 0 32px;

  @include desktopWidth {
    margin: 14px 0 16px;
  }

  @include tabletWidth {
    margin: 12px 0 20px;
  }
}

.excerpt {
  width: 100%;

  & > p {
    @include limit-lines(5);
  }
}

//           _     _
// __      _(_) __| | ___
// \ \ /\ / | |/ _` |/ _ \
//  \ V  V /| | (_| |  __/
//   \_/\_/ |_|\__,_|\___|

.container.wide {
  @include element-flex(row);
  gap: 8%;
  color: unset;

  @include tabletWidth {
    max-width: unset;
    gap: 4%;
  }

  @include phoneWidth {
    @include element-flex(column);
    max-width: 300px;
    margin: 0 auto;
  }

  .thumbnail {
    height: 350px;
    margin: unset;

    @include desktopWidth {
      height: 300px;
    }

    @include tabletWidth {
      height: 200px;
    }

    @include phoneWidth {
      height: 160px;
    }
  }

  .content {
    width: 100%;
    align-self: center;

    @include phoneWidth {
      margin-top: 20px;
    }
  }

  .title {
    color: $font-color !important;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 16px;

    @include desktopWidth {
      font-size: 20px;
      line-height: 26px;
    }

    @include tabletWidth {
      font-size: 15px;
      line-height: 17px;
      margin-bottom: 10px;
    }
  }

  &:hover .title {
    color: $blue-color !important;
  }

  .date,
  .time {
    @include tabletWidth {
      font-size: 0.8em;
    }
  }

  .excerpt > p {
    margin-bottom: 25px;

    @include tabletWidth {
      @include limit-lines(3);
      margin-bottom: 10px;
    }
  }

  .divider {
    margin: 16px 0 20px;

    @include tabletWidth {
      margin: 8px 0 10px;
    }
  }
}
