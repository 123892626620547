@import '../../../../../../styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr 140px 170px 60px 170px;
  gap: 12px;
  padding: 12px;
  border-radius: $radius-s;

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  &.selected {
    background: rgba(0, 1, 219, 0.1);
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 6px;

  & > img {
    box-shadow: $shadow-main;
  }
}

.text {
  font-size: $font-size-s;
  @include limit-lines(2);
}
