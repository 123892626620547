@import '../../../../styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 200px 200px 40px;
  gap: 24px;
  padding: 12px;
  border-radius: $radius-s;
  min-height: 60px;
  position: relative;

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  @include smDesktopWidth {
    @include element-flex(column);
    gap: 12px;
    padding: 24px 12px;
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;

  &.menuWrapper {
    justify-content: flex-end;

    @include smDesktopWidth {
      position: absolute;
      top: 18px;
      right: 10px;
      padding: 0;
    }
  }

  @include smDesktopWidth {
    padding-right: 40px;
  }
}

.text {
  font-size: $font-size-s;
  font-weight: 400;
  word-break: break-word;
}

.name {
  @include smDesktopWidth {
    font-size: $font-size-xl;
    font-weight: 700;
    text-align: left;
    min-width: 90px;
  }
}

.menu {
  position: static !important;
}
