@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 150px 2fr 100px 70px 70px 70px;
  gap: 18px;
  padding: 6px;
  border-radius: $radius-s;
  min-height: 60px;
  position: relative;

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  @include smDesktopWidth {
    @include element-flex(column);
    padding: 24px 6px;
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;

  @include smDesktopWidth {
    justify-content: stretch;
    width: 100%;
  }
}

.text {
  font-size: $font-size-s;
  font-weight: 400;

  @include smDesktopWidth {
    font-size: $font-size-xl;
    text-align: left;
    font-weight: 700;
    min-width: 90px;
  }
}

.delete {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: $danger-color;
  margin: auto 6px auto auto;
  transition: background 0.2s;

  & > svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: darken($danger-color, 5%);
  }
}

.qtySelector {
  @include smDesktopWidth {
    width: 100%;
  }
}
