@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.section {
  background-color: $background-default;
  padding: 40px 0 70px;
  @include element-flex(row, center);

  @include desktopWidth {
    padding: 40px 40px 60px;
  }

  @include tabletWidth {
    padding: 35px 30px 55px;
  }

  @include phoneWidth {
    padding: 30px 25px 40px;
  }

  &--gray {
    background-color: $background-gray;
  }

  &--noPadBtm {
    padding-bottom: unset;
  }
}

.container {
  width: 100%;
  margin: $margin-page;

  position: relative;

  &--flex {
    display: flex;
  }
}

.transparent {
  background-color: transparent;
}
