@import 'src/styles';

.container{
  @include element-flex(column, flex-start, stretch);
  gap: 12px;
  max-width: 500px;
  width: 500px;
}

.button{
  margin-top: 24px;
}

.header{
  padding-top: 12px;
  margin-top: 3px;
  @include element-flex(column);
  gap: 6px;
  border-top: 1px solid $border-color-lighter;

  &.second{
    padding-top: 12px;
  }
}

.buttonsWrapper{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;
}