@import 'src/styles';

.container{
  width: 100%;
  @include element-flex(row, stretch, center);
  gap: 12px;
  padding: 8px 12px;
  border: 1px solid $border-color-lighter;
  border-radius: $radius-m;
  overflow: hidden;
  transform: translate(0, 0);
  background: #fff;
  min-height: 50px;
  transition: border .2s, background .2s;
  cursor: grab;

  &:hover{
    border-color: $border-color;
    background: $blue-lightest-color;
  }

  &:active{
    cursor: grabbing !important;
  }

  &.isDragging{
    background: $blue-lightest-color;
  }

  &.replacement{
    background: rgba(0, 1, 219, 0.07);
    border-color: $border-color;

    &:hover {
      background: rgba(0, 1, 219, 0.1);
    }
  }
}

.nameWrapper{
  flex-grow: 1;
  @include element-flex(row, flex-start, center);
  gap: 6px;
}

.price{
  font-size: $font-size-m;
  font-weight: $font-bold;
}

.tag{
  width: fit-content;
  font-size: 15px;
  max-width: max(150px, 50%)
}