@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 65px 1fr 280px 120px 120px 120px 100px 40px;
  gap: 12px;
  padding: 12px;
  border-radius: $radius-m;
  min-height: 60px;
  background-color: #ffffff;
  user-select: text;

  &.inOrder{
    grid-template-columns: 65px 150px 150px 1fr 100px auto;
  }

  &.small{
    display: grid;
    grid-template-columns: 80px repeat(3, 140px);
    padding: 18px 55px 18px 24px;
    position: relative;

    .wrapper{
      height: auto;
      max-width: 140px;
      white-space: break-spaces !important;
      @include limit-lines(2);
    }

    .mobileRow{
      grid-row: 1 / 3;
    }

    .menu{
      position: absolute;
      right: 24px;
      top: 18px;
    }
  }

  @include smDesktopWidth {
    @include element-flex(column, flex-start, stretch);
    position: relative;
    padding: 24px 12px 12px;
  }
}

.tag{
  width: 100%;
  text-align: center;
}

.company{
  max-width: 280px;
  @include trimText;
}

.invoiceNumber{
  max-width: 120px;
  @include trimText;
}

.comment{
  max-width: 382px;
  @include trimText;
}

.filename{
  max-width: 232px;
  @include trimText;
}

.wrapper {
  @include element-flex(column, center, flex-start);
  gap: 0;
  height: 100%;

  &.mobileRow{
    align-items: stretch;

    @include smDesktopWidth{
      display: grid;
      grid-template-columns: 80px 110px 1fr;
      align-items: center;
      gap: 12px;
      width: 100%;
      padding-right: 40px;
    }
  }

  &.row{
    @include element-flex(row, flex-end, center);
    gap: 6px;
  }
}

.label{
  font-size: $font-size-s;
  font-weight: 400;
  color: $font-color-light;
}

.text {
  font-size: 15px;
  font-weight: 400;

  &.orderNumber{
    color: $blue-color;
  }

  &.error{
    color: $danger-color;
  }

  &.trim{
    @include trimText;
    max-width: 150px;
  }

  @include smDesktopWidth {
    font-size: $font-size-m;
    text-align: left;
    min-width: 90px;
  }
}

.menu{
  position: static;
  margin: auto 0;

  @include smDesktopWidth{
    position: absolute;
    right: -12px;
    top: 27px;
  }
}

.contentWrapper{
  display: grid;
  grid-template-columns: 65px 1fr 280px 252px 232px 40px;
  gap: 12px;
  padding: 0 44px 12px 12px;

  @include smDesktopWidth{
    @include element-flex(column, flex-start, stretch);
  }
}
