@import 'src/styles';

.container{
  @include element-flex(column, center, center);
  width: 100%;
  padding: 12px 8px;
  height: 100%;
  border-radius: $radius-s;
  border: 1px solid $border-color;
  display: grid;
  gap: 12px;
  grid-template-columns: 100%;
  grid-template-rows: 24px 1fr;
  position: relative;
  transition: background-color 0.3s;
  background: $gray-color;

  &:hover {
    background: $gray-color;
  }
}


.empty{
  color: $font-color-light
}

.tag{
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: 700;
  height: 24px;
  z-index: 1;
}