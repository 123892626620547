@import 'src/styles';

.container{
  width: 100%;
  min-height: 75px;
  display: grid;
  justify-items: stretch;
  align-items: center;
  background: $gray-lightest-color;
  border-radius: $radius-m;

  &.withFile{
    padding: 12px;
  }
}

.dropzone{
  border: 2px dashed #a4a4a4;
  border-radius: $radius-m;
  padding: 24px;
  transition: border .2s ease;
  min-height: 75px;

  &:hover{
    border: 2px dashed $font-color-light;
  }

  &:focus,
  &:active{
    border: 2px dashed $blue-light-color;
  }

  &.error{
    border: 2px dashed $danger-color;
  }
}

.label{
  color: $font-color-light;
  font-size: 15px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.3px;
}