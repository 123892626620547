@import 'src/styles';

.container {
  width: 100%;
}

.headerWrapper {
  @include element-flex(row, flex-end, center);
  gap: 12px;
}
