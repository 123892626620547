@import 'src/styles';

.container {
  @include element-flex(column, flex-start, center);
  gap: 24px;
  width: 100%;
}

.wrapper {
  @include element-flex(row, stretch, center);
  width: 100%;
  gap: 24px;
  @include tabletWidth {
    flex-wrap: wrap;
  }
}

.titles {
  @include element-flex(column);
  gap: 6px;
  width: 100%;
}

.header {
  @include element-flex(column);
  width: 100%;
  gap: 12px;
  padding: 24px;
  border-radius: $radius-s;
  z-index: 2;
  background: #fff;
  margin-bottom: 6px;
}

.input {
  margin-bottom: 12px;
  width: 100%;
}

.select {
  width: 100%;
  z-index: 2;
}

.button {
  min-width: 150px;
}
