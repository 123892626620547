$blue-lightest-color: #f0f4ff;
$blue-lighter-color: #dee5ff;
$blue-light-color: #4849ff;
$blue-color: rgb(0, 1, 219);
$blue-dark-color: #000195;

$blue-tag-color: #9c9cff;

$gray-lightest-color: rgba(235, 236, 239, 0.4);
$gray-lighter-color: rgba(235, 236, 239, 0.8);
$gray-light-color: rgba(235, 236, 239);

$yellow-color: #f9d370;
$yellow-light-color: #fff2db;

$green-color: #6af1c0;
$green-light-color: #dbffed;

$danger-color: #f56969;
$purple-color: #6a35ff;
$gray-color: #eceef1;

$border-color: rgba(137, 169, 212, 0.72);
$border-color-light: rgba(137, 169, 212, 0.48);
$border-color-lighter: rgba(137, 169, 212, 0.24);

$font-color: #0a1b2d;
$font-color-light: #515c6a;

$main-background: #f2f5f9;

$input-hover-color: rgba(75, 75, 75, 0.6);

$odd-row-background: #f6f7f8;

$disabled-input-background: hsl(0, 0%, 95%);
