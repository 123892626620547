@import './src/styles';

.burgerMenu {
  display: none;
  height: 42px;
  width: 42px;
  border-radius: $radius-s;
  background-color: $blue-color;

  &:hover {
    cursor: pointer;
  }

  @include smDesktopWidth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
}

.burgerBar {
  width: 22px;
  height: 2px;
  background-color: white;

  transition: cubic-bezier(0.17, 0.67, 0.26, 0.98) 0.5s;
}

.burgerBarClicked {
  &:nth-of-type(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  &:nth-of-type(2) {
    opacity: 0;
  }
  &:nth-of-type(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
}
