@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @include smDesktopWidth {
    @include element-flex(column, flex-start, stretch);
    gap: 24px;
  }
}
