@import 'src/styles';

.box{
  width: 100%;
  display: grid;
  gap: 12px;
  grid-template-columns: 400px 180px 180px 120px 120px 1fr;
  border-radius: $radius-s;
  padding: 12px 50px 12px 12px;
  position: relative;

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  &.withRefPrice{
    grid-template-columns: 400px 120px 180px 180px 120px 120px 1fr;
  }

  @include lgDesktopWidth{
    padding: 18px 12px;
    grid-template-columns: 180px 180px 120px 120px 1fr;
    row-gap: 18px;

    .container{
      grid-column: 1 / 7;
    }
  }

  @include tabletWidth{
    display: grid;
    grid-template-columns: 1fr 1fr;

    .container{
      grid-column: 1 / 3;
    }
  }

  @include phoneWidth{
    display: grid;
    grid-template-columns: 1fr;

    .container{
      grid-column: 1;
    }
  }

  &.withAll{
    grid-template-columns: 400px 1fr;

    &.withRefPrice{
      grid-template-columns: 400px 120px 1fr;
    }

    @include lgDesktopWidth{
      grid-template-columns: 1fr;
      row-gap: 18px;

      .container{
        grid-column: 1;
      }
    }

    @include tabletWidth{
      @include element-flex(column);
    }
  }
}

.container {
  width: 400px;
  min-width: 400px;
  gap: 6px;
  min-height: unset;
  padding: 0 6px;
  @include element-flex(column, center, flex-start);

  @include lgDesktopWidth{
    width: 100%;
    height: auto;
    grid-row: 1;
    @include element-flex(row, stretch, center);
    padding-right: 36px;
  }

  @include tabletWidth{
    width: 100%;
    @include element-flex(column);
  }

  &.autoSize{
    width: 100%;
    min-width: unset;
  }
}

.savings {
  @include element-flex(row, flex-start, center);
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;

  @include lgDesktopWidth{
    flex-grow: 1;
  }
}

.text {
  font-size: $font-size-s;
  font-weight: 400;
  @include limit-lines(3);

  & > p{
    color: $blue-color;
  }
}

.priceWrapper {
  @include element-flex(row, flex-start, center);
  gap: 1ch;
  max-width: 180px;
  min-width: 180px;
  width: 180px;

  &.ref{
    @include element-flex(column, center, center);
    max-width: unset;
    min-width: unset;
    width: 100%;
    height: 100%;
  }
}

.price {
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: 600;
}

.prevPrice {
  text-decoration: line-through;
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: 600;
  color: #6a798b;
}

.available {
  font-size: $font-size-s;
  font-weight: $font-regular;
  line-height: 24px;
  color: #6a798b;
}

.offerBox {
  @include element-flex(column);
  gap: 16px;
  padding: 12px 0;
  width: 100%;
  z-index: 2;
}

.row {
  width: 100%;
  @include element-flex(row, flex-start, center);
  gap: 12px;
  min-height: 27px;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 6px;
  row-gap: 12px;

  @include lgDesktopWidth{
    gap: 12px;
    width: fit-content;
    min-width: fit-content;
  }

  @include smDesktopWidth {
    @include element-flex(column, flex-start, flex-end);
    gap: 0;

    .separator {
      display: none;
    }
  }

  @include tabletWidth{
    @include element-flex(row, flex-start, center);
    gap: 12px;
  }
}

.icon {
  width: 24px;
  height: 20px;
  padding-right: 6px;
}

.details {
  @extend .text;
  @include element-flex(row, flex-start, center);
  gap: 6px;
}

.detailsValue{
  @include lgDesktopWidth{
    @include trimText;
    max-width: 120px
  };
}

.tag {
  max-width: unset !important;
  width: fit-content;
  animation: fadeIn 0.2s forwards;
}

@keyframes fadeIn {
  @include keyframe-fadeIn;
}


.prices{
  width: 100%;
  @include element-flex(row, flex-start, center);
  gap: 12px;
  flex-wrap: wrap;
  min-height: 90px;

  @include lgDesktopWidth{
    grid-column: 1 / 3;
  }
}

.emptyStateWrapper{
  @include element-flex(row, center, center);
  width: 180px;
}

.tag{
  width: 100%;
  text-align: center;
}

.menu{
  top: 12px;
  right: 8px;
}