@import 'src/styles';

.info{
  @include element-flex(column);

  & > p{
    color: $blue-lightest-color;
    font-size: $font-size-m;
  }
}

.link{
  color: $blue-lightest-color;
  text-decoration: underline;
  font-size: $font-size-m;

  &:hover{
    color: darken($blue-lightest-color, 5%);
  }
}