// priceType: BRUTTO
$gross-grid-template-columns: 1fr 140px 90px 100px 160px 40px;

// priceType: NETTO
$net-grid-template-columns: 1fr 140px 85px 90px 100px 160px 40px;

// both
$custom-breakpoint-grid-template-columns: 2fr repeat(4, 1fr) 40px;
$smDesktopWidth-grid-template-columns: 4fr repeat(2, 2fr) 100px 40px;
$desktopWidth-grid-template-columns: repeat(2, 1fr);

//colors
$odd-element-background: #eef0f2;
$even-element-background: #f9f9fa;
