@import 'src/styles';

.container {
  width: 100%;
  padding: 3px;
  border-radius: $radius-s;

  &:nth-of-type(odd) {
    background: $odd-row-background !important;
  }
}
