@import 'src/styles';

.container {
  width: 100%;
  @include element-flex(column, flex-start, center);
  min-height: 100vh;
  padding-top: calc(50vh - 275px);

  @include tabletWidth {
    padding: 20vh 10px 0;
  }
}

.header {
  @include element-flex(row, space-between, center);
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  padding: 0 calc(50% - 620px);

  @include desktopWidth {
    padding: 0 20px;
  }
}

.logo {
  max-height: 50px;
  width: 210px;

  &:last-of-type {
    width: 300px;
  }

  @include tabletWidth {
    max-height: 40px;

    width: 120px;

    &:last-of-type {
      width: 190px;
    }
  }

  @include phoneWidth {
    max-height: 35px;
  }
}

.titleWrapper {
  @include element-flex(column);
  width: 100%;
  max-width: 560px;
  padding: 0 0 24px;
  gap: 6px;
  font-size: $font-size-m;

  @include smDesktopWidth {
    text-align: center;
    align-items: center;
  }
}

.title {
  font-size: $font-size-xxl;
  font-weight: $font-extraBold;
}
