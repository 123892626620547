@import 'src/styles/helpers';
@import 'src/styles';

.card {
  position: relative;
  padding: 24px;
  background: $n1;
  border-radius: $radius-m;
  @include smDesktopWidth {
    padding: 18px 10px;
  }

  &.yellow{
    border: 2px solid $yellow-color;
    background: $yellow-light-color;
    padding: 12px 24px;
  }

  &.red{
    border: 2px solid $danger-color;
    background: #ffd0c8;
    padding: 12px 24px;
  }
}

.head {
  display: flex;
  align-items: center;
  min-height: 40px;
  margin-bottom: 32px;
}

.title {
  margin-right: auto;
}

.closeBtn {
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
}
