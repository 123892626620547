@import 'src/styles';

.container{
  width: 100%;
  @include element-flex(column, flex-start, stretch);
  gap: 12px;
}

.button{
  margin-top: 12px;
}

.row{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}