@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.section-header {
  margin-bottom: 55px;

  & > h2 {
    font-weight: $font-semiBold !important;
  }

  @include desktopWidth {
    margin-bottom: 35px;
    gap: 20px;
  }
}

.list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 25px;
  row-gap: 30px;

  @include smDesktopWidth {
    grid-template-columns: repeat(2, 1fr);
  }

  @include customWidth(520px) {
    @include element-flex(column, center, center);
  }
}

.placeholder {
  height: 300px;
  background-color: $gray-lightest-color;
  border-radius: $radius-s;
  display: flex;
  justify-content: center;
  align-items: center;
}
