@import 'src/styles';

.badge{
  position: absolute;
  right: 0;
  top: 0;

  &.left{
    left: -15px;
    right: unset;
  }
}