@import 'src/styles';

.icon{
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
}