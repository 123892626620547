@import 'src/styles';

.treeContainer {
  display: flex;
  flex-direction: column;
  justify-content: right;
  width: 100%;
  gap: 16px;
  padding: 20px 20px;
  background-color: #f2f5f9;
}

.label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  word-break: break-word;
  background: $blue-lightest-color;
  border-radius: 6px;
  padding: 18px 72px 18px 24px;
  min-height: 30px;
  margin-right: 0;
  position: relative;

  &.hidden{
    background: $gray-lightest-color;
  }

  &.notMaster{
    background: #fff;
    border: 1px solid $border-color-lighter;
    box-shadow: 0 0 12px 12px transparentize($blue-lightest-color, 0.8) inset;
    font-weight: 400;
  }

  @include smDesktopWidth{
    padding: 18px 24px;
  }
}

.labelButtons {
  display: flex;
  flex-direction: row;
}

.counter{
  color: $font-color-light;
  font-weight: 400;
  padding-left: 6px;
  min-width: fit-content;
  cursor: default;
}

.buttonsContainer {
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.saveButton {
  background-color: rgb(75, 181, 67);
}

.saveButton:hover {
  background: rgba(8, 83, 2, 0.6);
}

.tag{
  margin-left: 6px;
  max-height: 24px;
  line-height: 14px;
}

.menu{
  top: 14px;
}