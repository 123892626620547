@import '../../../../../../styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 200px 180px 50px;
  gap: 12px;
  padding: 6px;
  border-radius: $radius-s;
  min-height: 60px;
  position: relative;

  &:nth-of-type(even) {
    background: $odd-row-background;
  }

  @include smDesktopWidth {
    @include element-flex(column);
    padding: 36px 12px;
  }
}

.wrapper {
  @include element-flex(row, stretch, center);
  gap: 12px;

  &.price {
    justify-content: flex-end;
    padding-right: 12px;
  }

  @include smDesktopWidth {
    display: grid;
    grid-template-columns: 100px 1fr;
    width: 100%;

    &:first-child {
      padding-bottom: 12px;
    }
  }
}

.text {
  font-size: $font-size-s;
  font-weight: 400;

  @include smDesktopWidth {
    font-size: $font-size-m;
    text-align: left;
    width: 100%;
  }
}

.save {
  position: relative;
  transform: translateY(-50%);
  top: 50%;

  @include smDesktopWidth {
    top: 12px;
    right: 12px;
    transform: none;
    position: absolute;
  }
}

.column {
  @include element-flex(column, flex-start, stretch);
  width: 100%;
}

.center {
  width: 100%;

  @include smDesktopWidth {
    justify-content: center;
    display: flex;
  }
}

.mobileLabel {
  white-space: nowrap;
}

.qty {
  @include smDesktopWidth {
    width: 100% !important;
  }
}

.inline_column {
  display: flex;
  justify-content: space-between;
}

.nameWrapper {
  @include smDesktopWidth {
    @include element-flex(row, flex-start, center);
    width: 100%;
    gap: 12px;
  }
}

.state {
  padding-left: 6px;
}

.increase {
  background: transparentize($green-color, 0.5);
}

.decrease {
  background: transparentize($danger-color, 0.5);
}

.quantity {
  font-size: $font-size-m;
  @include element-flex(row);
  gap: 3px;
}
