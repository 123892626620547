@import 'src/styles';

.container {
  @include element-flex(row, space-between, center);
  width: 100%;
  min-height: 84px;
  background-color: #fcfcfc;
  border-radius: 6px;
  padding: 12px 24px;
  display: flex;
  box-shadow: $shadow-main;
  gap: 6px;
  flex-wrap: nowrap;
  color: $font-color;
  text-decoration: none !important;

  path {
    fill: $blue-color;
  }

  @include customBreakpoint(1420px) {
    @include element-flex(column, center, center);
  }

  @include smDesktopWidth {
    @include element-flex(row, space-between, center);
    padding: 20px;
  }
}

.icon {
  @include element-flex(row, flex-start, center);
  gap: 1ch;

  & > p {
    font-size: $font-size-m;
    font-weight: $font-bold;
  }
}

.wrapper {
  display: flex;
  column-gap: 14px;
}

.title {
  font-size: 14px;
}

.button {
  width: 110px;
}
