@import '../../../styles';

.container {
  @include element-flex(column);
  width: 600px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @include tabletWidth {
    width: 100%;
    @include element-flex(column);
  }
}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 100px repeat(4, 70px);
}

.product {
  @extend .header;
  padding: 6px 12px;
}

.element {
  @include element-flex(column);

  &.name {
    grid-column: 1 /3;
  }
}

.label {
  font-size: $font-size-s;
  color: $font-color-light;
  font-weight: $font-bold;
}

.value {
  font-size: $font-size-l;
  font-weight: normal;
  @include limit-lines(3);
  overflow-wrap: break-word;
  max-width: 600px;

  @include smDesktopWidth {
    width: 90vw;
  }
}

.link {
  @include element-flex(row, flex-start, center);
  gap: 3px;
  font-size: $font-size-s;
  font-weight: normal;
  cursor: pointer;
  margin: 4px 0 0 -2px;
}

.icon {
  width: 16px !important;
  height: 16px !important;
  margin-right: 0.5ch;
}
