@import 'src/styles';

.container {
  width: 100%;
}

.titleWrapper {
  @include element-flex(row, flex-start, center);
  gap: 1ch;
  margin-bottom: 24px;
}
