@import 'src/styles';

.container {
  @include element-flex(column, flex-start, flex-start);
  width: 100%;
  gap: 3px;
  padding: 12px 12px 18px;
  margin-bottom: 18px;
  border-bottom: $gray-light-color 1px solid;

  &.inProfile {
    padding: 12px 0;
    margin: 0;
    border: none;

    & > p {
      font-size: $font-size-xxl;
      font-weight: 700;
    }
  }
}

.username {
  overflow-wrap: anywhere;
}

.tag {
  margin-bottom: 6px;
}
