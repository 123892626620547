@import 'src/styles';

.container {
  @include element-flex(row, space-between, center);
  width: 100%;
  margin: 0 0 50px;

  &.supplierForm {
    max-width: 1400px;
    margin: 50px auto;
  }

  @include smDesktopWidth {
    margin: 50px 10px;
    width: calc(100% - 20px);
    @include element-flex(column);
    gap: 24px;
  }
}

.wrapper {
  @include element-flex(column);
  gap: 6px;
}
