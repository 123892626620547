@import '../../../styles';

.quantity {
  @include element-flex(row, stretch, center);
  width: 100px;
  position: relative;
  border-radius: $radius-s;
  padding: 0 6px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background: #fff;
  height: 40px;
  transition: all 0.2s;

  &:hover {
    border: 1px solid $input-hover-color;
  }

  &:focus-within {
    border: 1px solid #4849ff;
    box-shadow: 0 0 0 1px #4849ff;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    @include appearance(none);
  }

  &.horizontal {
    width: 80px;
    margin: 0 34px;
    justify-content: flex-end;

    .input {
      text-align: center;
      width: 100%;
      flex: 1;
    }

    .button {
      display: none;
    }
  }

  &.suffixWithArrow {
    padding-right: 26px;
  }

  &.error {
    border: 1px solid $danger-color;
  }

  &:focus-within.error {
    border: 1px solid $danger-color;
    box-shadow: 0 0 0 1px $danger-color;
  }
}

.suffixText {
  line-height: normal;
  font-size: 15px;
  font-weight: 700;
  padding: 0 2px 0 2px;
  letter-spacing: -0.3px;

  &.disabled {
    color: $font-color-light;
  }
}

.input {
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  flex: 1;
  transition: all 0.2s;
  padding: 0 24px 0 0;
  border-radius: 5px;
  text-align: right;

  &.hideValueWhenZero {
    color: transparent;

    &:focus {
      color: $font-color;
    }
  }

  &.suffix {
    padding: 0;
    border-radius: 0;
    text-align: right;
  }

  &.disabled .suffixText {
    color: $font-color-light;
  }

  &.horizontal {
    padding: 6px;
  }
}

input[type='number'],
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

.disabled {
  background: $disabled-input-background;
  cursor: not-allowed;
}

.button {
  width: 18px;
  height: 20px;
  background: transparent;
  position: absolute;
  right: 8px;
  display: grid;
  place-items: center;

  &:hover {
    &:before {
      border-top: 7px solid gray;
      border-bottom: 7px solid gray;
    }
  }

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid $font-color;
    border-bottom: 7px solid $font-color;
    transition: border 0.2s, opacity 0.2s;
  }

  &.plus {
    top: 0;
    padding: 10px 0 2px;
    tab-index: 1;

    &:before {
      border-top: none;
    }
  }

  &.minus {
    bottom: -1px;
    padding: 2px 0 10px;
    tab-index: 1;

    &:focus {
      backdrop-filter: brightness(1.1);
    }

    &:before {
      border-bottom: none;
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;

      &:hover {
        &:before {
          border-top: 7px solid $font-color;
        }
      }
    }
  }
}

.ball {
  width: 24px;
  height: 24px;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s;
  background: linear-gradient(145deg, #f0f0f0, #cacaca);
  box-shadow: 3px 3px 5px #bababa, -3px -3px 5px #ffffff;

  &:focus {
    border: 2px solid #4849ff;
    transform: translateY(-50%) scale(1.1);
  }

  &.minus {
    left: -32px;

    &:hover {
      background: linear-gradient(145deg, #cacaca, #f0f0f0);
      box-shadow: 3px 3px 5px #bababa, -3px -3px 5px #ffffff;
    }

    &:after {
      content: '';
      width: 12px;
      height: 2px;
      background: $font-color;
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
      tab-index: -1 !important;

      &:hover {
        background: $gray-light-color;
      }
    }
  }

  &.plus {
    right: -32px;
    background: linear-gradient(145deg, #0001ea, #0001c5);
    box-shadow: 3px 3px 5px #bababa, -3px -3px 5px #ffffff;

    &:hover {
      background: linear-gradient(145deg, #0001c5, #0001ea);
      box-shadow: 3px 3px 5px #bababa, -3px -3px 5px #ffffff;
    }

    &:before,
    &:after {
      content: '';
      width: 12px;
      height: 2px;
      background: #ffffff;
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }

    &:before {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}
