@import 'src/styles';

.content {
  min-height: 100vh;
  width: 100%;
  padding: 50px calc(50% - 564px);
  @media screen and (max-width: 1240px) {
    padding: 50px 12px;
  }
}

.title {
  text-align: center;
  margin-bottom: 40px;
  @media screen and (max-width: 1240px) {
    margin-bottom: 24px;
  }
}

.wrapper {
  background-color: #fff;
  padding: 40px;
  text-align: justify;
  border-radius: $radius-s;
  box-shadow: $shadow-main;
  @media screen and (max-width: 1240px) {
    padding: 24px 12px;
  }
}

.text {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 27px;
}

.header {
  margin-bottom: 24px;
  font-size: 16px;
}

.column_header {
  margin-bottom: 24px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.list {
  list-style: decimal;
  margin-left: 15px;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 24px;
  li {
    line-height: 27px;
    margin: 6px 0;
  }
}

.second_list {
  counter-reset: orderedList;
  list-style: none;
  margin: 12px 0;
  & li::before {
    content: counter(orderedList) ') ';
    counter-increment: orderedList;
  }
  li {
    line-height: 27px;
    
  }
}

.second_list_abc {
  counter-reset: list;
  margin: 12px;
  & li::before {
    counter-increment: list;
    content: counter(list, lower-alpha) ') ';
    position: absolute;
    left: -1.4em;
  }
  li {
    line-height: 27px;
    list-style: none;
    position: relative;
   
  }
}

.extra_margin {
  margin-top: 12px;
}
