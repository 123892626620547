@import 'src/styles';

.suppliers {
  height: auto;
  position: relative;

  &.largeList {
    height: 420px;
    transition: height 0.3s cubic-bezier(0.71, 0.13, 0.46, 1.05);
  }

  &.withOverflow {
    overflow: hidden;
  }
}

.info {
  position: static;
}

.qtyTitle {
  @include element-flex(row, flex-start, center);
  gap: 0.5ch;
}

.showMore {
  width: 100%;
  height: 120px;
  display: grid;
  place-items: center;
  text-align: center;
  font-size: $font-size-s;
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  background: linear-gradient(to bottom, transparent, #f2f5f9 50%);
  color: $blue-color;
  z-index: 6;

  &:hover {
    text-decoration: underline;
  }
}

.supplierName {
  font-size: $font-size-xl;
  font-weight: 800;
  padding: 6px 6px 18px;
  border-bottom: 1px solid $border-color-lighter;

  & > span {
    font-size: $font-size-s;
    opacity: 0.8;
  }
}

.header {
  grid-template-columns: 20px 1fr 130px 60px 90px 90px 110px;
  gap: 12px;
  padding: 18px 12px;

  @include lgDesktopWidth {
    display: none;
  }
}
