@import 'src/styles';

.selectSmall {
  width: 100%;
  z-index: 2;
}

.container {
  @include element-flex(column, center, center);
  gap: 12px;
  width: 100%;
}

.button {
  margin: 24px auto 0;
  min-width: 150px;
}
