@import 'src/styles';

.container {
  width: 100%;
  padding: 36px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: $radius-s;
  box-shadow: $shadow-main;
  margin-bottom: 50px;

  @include smDesktopWidth {
    padding: 18px 10px;
    width: calc(100% - 20px);
    margin: 0 10px;
  }
}
