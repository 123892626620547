@import '../../../../styles';

.container {
  width: calc(100vw - 100px);
  max-width: 1000px;
  height: auto;
}

.header {
  display: grid;
  grid-template-columns: 1fr 200px 180px 50px;
  gap: 12px;
  position: sticky;
  top: 0;
  padding-left: 6px;
  margin-right: 6px;
  z-index: 1;
  background: #ffffff;
}

.columnTitle {
  @include element-flex(row, flex-start, center);
  gap: 12px;
}

.headerWrapper {
  padding: 12px 12px 0;
  display: grid;
  grid-template-columns: 132px 1fr;
  place-items: end;
  gap: 24px;
  width: 100%;
}
