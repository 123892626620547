@import '../../../../styles';

.container {
  width: 100%;
  gap: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include smDesktopWidth {
    @include element-flex(column, flex-start, stretch);
  }
}

.title {
  font-size: $font-size-m;
  font-weight: $font-bold;
}

.value {
  border-radius: $radius-s;
  background: #ffffff;
  padding: 24px;
  width: 100%;
  @include element-flex(column);
  gap: 6px;

  .title {
    margin: 0;
  }
}

.amount {
  font-size: $font-size-xl;
  font-weight: $font-bold;
  margin-top: 6px;
}

.partOfAmount {
  font-weight: normal;
  font-size: $font-size-m;
  color: $font-color-light;
}

.supplier {
  padding: 24px;
  border-radius: $radius-s;
  border: 1px solid $border-color-light;
  width: 100%;
  @include element-flex(column);
  gap: 24px;
  box-shadow: $shadow-main;

  @include smDesktopWidth {
    padding: 18px 10px;
  }
}

.supplierName {
  font-size: $font-size-l;
  & > span {
    font-weight: normal;
    font-size: $font-size-m;
  }
}

.card{
  gap: 6px;
}