@import '../../../styles';

.container {
  @include element-flex(column, center, center);
  width: 100%;
  height: 52px;
  padding: 0 12px;
  gap: 3px;

  &.fullWidth {
    padding: 0;
  }

  &.alignToLeft {
    align-items: flex-start;
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  width: 100%;
  height: 8px;
  border-radius: 99px;
  background: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.valueBar {
  height: 8px;
  border-radius: 99px;
  background: $blue-color;
  transition: width 0.5s;

  &.green {
    background: $green-color;
  }
}

.text {
  line-height: 20px;
  height: 20px;
  font-size: $font-size-xs;
}
