@import 'src/components/layout/PublicPages/PublicPages.module.scss';

.wrapper {
  width: 100%;
  margin-top: auto;
  @include element-flex(row);
  flex-wrap: wrap;
  gap: 10px;

  & > div {
    display: flex;
    gap: 18px;
  }
}

.center {
  justify-content: center;
}
