@import '../../../../styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 24px;
  padding: 6px;
  border-radius: $radius-s;
  min-height: 60px;
  position: relative;

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

  @include smDesktopWidth {
    @include element-flex(column);
    gap: 12px;
    padding: 24px 12px;
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;
}

.text {
  font-size: $font-size-s;
  font-weight: 400;

  @include smDesktopWidth {
    font-size: $font-size-m;
    text-align: left;
  }
}

.name {
  @include smDesktopWidth {
    font-size: $font-size-xl;
    font-weight: 700;
    text-align: left;
    min-width: 90px;
  }
}
