@import src/styles/helpers
@import src/styles

.dropdown
  &.active
    .head
      svg
        &:last-child
          transform: rotate(180deg)

    .body
      display: block

  &.wide
    +d
      .head
        font-size: 14px

        svg
          &:first-child
            +d
              margin-right: 12px

          &:last-child
            +d
              display: inline-block
      .add
        display: flex

  &.wide.active
    .body
      +d
        display: block

.top
  position: relative

  &.active
    .head
      background: $n3
      box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.05), inset 0 1px 1px #FFFFFF
      color: $n7
      +dark
        background: $n6
        box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.4), inset 0 1px 1px rgba(255, 255, 255, 0.11)
        color: $n1

.head
  display: flex
  align-items: center
  width: 100%
  height: 34px
  padding: 8px 26px
  box-sizing: border-box
  border-radius: 12px
  font-size: 14px
  font-weight: 700
  line-height: 18px
  color: #6A798B
  margin-bottom: 6px
  white-space: nowrap
  &.disabled
    cursor: default
  +base1-s
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  transition: background .2s, box-shadow .2s, color .2s
  +d
    font-size: 0
  +m
    font-size: 14px

  svg
    flex-shrink: 0
    fill: $n4
    transition: transform .2s, fill .2s

    &:first-child
      margin-right: 12px
      +d
        margin-right: 0
      +m
        margin-right: 12px

    &:last-child
      margin-left: auto
      +d
        display: none
      +m
        display: inline-block

.add
  position: absolute
  top: 50%
  display: flex
  justify-content: center
  align-items: center
  right: 48px
  width: 24px
  height: 24px
  border-radius: 50%
  border: 2px solid $n3
  transform: translateY(-50%)
  font-size: 0
  transition: border-color .2s
  +d
    display: none
  +m
    display: flex
  +dark
    border-color: $n5

  svg
    fill: $n4
    transition: fill .2s

  &:hover
    border-color: $n7

    svg
      fill: $n7
      path
        fill: $n7

    +dark
      border-color: $shades1
      svg
        fill: $shades1

  &.active
    border-color: $shades1

    svg
      fill: $shades1

.body
  position: relative
  display: none

  &.horizontalOnMobile
    @include smDesktopWidth
      @include element-flex(row, flex-start, center)
      display: flex !important
      gap: 12px

.link
  position: relative
  display: flex
  align-items: center
  min-height: 40px
  padding: 8px 25px
  border-radius: 999px
  font-weight: 400
  line-height: normal
  color: $n4
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  transition: background .2s, box-shadow .2s, color .2s
  letter-spacing: 0
  text-decoration: none !important

  &.isClose
    padding: 0 25px
    svg
      margin: 0

  &.wide
    @include smDesktopWidth
      width: fit-content
      white-space: nowrap

  svg
    margin-right: 12px
    width: 24px
    height: 24px
    object-fit: contain
    transition: fill .2s
    path
      transition: fill .2s

    +dark
      fill: $n1

  &:hover
    color: $n7
    background: rgba(162, 172, 185, 0.08)
    svg
      path
        fill: $n7
    +dark
      color: $n1

  &.active
    background: rgba(162, 172, 185, 0.12)
    color: $n7
    font-weight: 700
    letter-spacing: -0.17px

    +dark
      background: $n6
      box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.4), inset 0 1px 1px rgba(255, 255, 255, 0.11)
      color: $n1

    svg
      fill: #0001DB

      path
        fill: #0001DB

.counter
  flex-shrink: 0
  min-width: 24px
  margin-left: auto
  border-radius: 6px
  text-align: center
  line-height: 24px
  color: $n7


.badge
  position: absolute
  right: 12px
  top: 50%
  transform: translateY(-50%)