@import 'src/styles';

.container {
  width: 100%;
  @include element-flex(column);
  gap: 3px;

  &.blurred .text{
    filter: blur(4px) !important;
  }
}

.textWrapper {
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 12px;

  @include desktopWidth {
    grid-template-columns: 100px 1fr;
  }
}

.label {
  width: 100%;
  text-align: right;
  line-height: 22px;
  color: $font-color-light;
}

.text {
  font-size: $font-size-m;
  font-weight: normal;
  line-height: 22px;
  @include trimText;
}
