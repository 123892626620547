@import 'src/styles';

.container {
  @include element-flex(column);
  gap: 6px;
  background: #fff;
  padding: 24px;
  border-radius: $radius-s;
  width: 100%;
  flex-wrap: wrap;
}

.tags{
  @include element-flex(row, flex-start, center);
  gap: 12px;
}