@import 'src/styles';

.header {
  grid-template-columns: 1fr 180px 120px 120px 90px 50px;
  gap: 24px;
  padding: 12px 6px;

  &.formList{
    grid-template-columns: 1fr 100px 150px 130px 130px 90px 50px;
  }

  @include desktopWidth {
    grid-template-columns:1fr 150px 100px 100px 70px 50px;
  }

  @include smDesktopWidth {
    display: none;
  }
}
