@import 'src/styles';

.container {
  @include element-flex(row, space-between, stretch);
  width: 100%;
  background: #ffffff;
  border-radius: $radius-m;
  padding: 18px 24px;
  min-height: 116px;
  position: relative;

  &.small {
    @include element-flex(column);
    gap: 12px;

    .data {
      min-height: 35px;
    }
  }

  &:hover {
    .name {
      color: $blue-color;
      text-decoration: underline;

      & > span {
        color: $blue-color;
      }
    }
  }

  @include smDesktopWidth {
    padding: 18px;
  }
}

.menuWrapper {
  position: absolute;
  right: 24px;
  top: 18px;
  @include element-flex(row, flex-end);
  gap: 12px;

  @include tabletWidth {
    right: 12px;
    top: 12px;
  }
}

.menu {
  position: static !important;
}

.data {
  min-height: 76px;
  @include element-flex(column, stretch);
  gap: 1px;

  @include smDesktopWidth {
    gap: 3px;
  }

  @include tabletWidth {
    padding-right: 35px;
  }
}

.name {
  font-size: 15px;
  line-height: 24px;
  color: $font-color;
  text-decoration: none;
  font-weight: 700;
  @include limit-lines(2);
  word-break: break-all;
  width: fit-content;
  flex-grow: 1;
  letter-spacing: -0.1px;

  &.blur{
    filter: blur(4px);
  }

  & > span {
    color: $font-color-light;
  }

  @include tabletWidth {
    line-height: 22px;
  }
}

.text {
  @include element-flex(row, flex-start, center);
  gap: 6px;
  font-size: $font-size-s;
  font-weight: $font-regular;
  color: $font-color-light;

  @include smDesktopWidth{
    flex-wrap: wrap;
  }
}

.noOwner {
  color: $danger-color;
}

.owner {
  max-width: 300px;
  @include trimText;
  text-overflow: ellipsis;

  span {
    cursor: default;
  }

  &:before {
    content: '|';
    padding-right: 6px;
  }
}

.tags {
  @include element-flex(row, flex-end, center);
  gap: 12px;

  @include smDesktopWidth {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.wrapper {
  @include element-flex(column, flex-end, flex-end);
  min-height: 76px;
  height: auto;

  &.withMenu{
    padding-top: 36px;
  }
}

.status {
  margin-top: 6px;
}

.linkWrapper{
  @include element-flex(row);
  gap: 0.5ch;
  flex-wrap: wrap;
}
