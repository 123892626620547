@import 'src/styles';

.container{
  @include element-flex(column);
  gap: 12px;
  width: 100%;
  max-height: 75vh;
  overflow: auto;
}

.header{
  display: grid;
  grid-template-columns: 300px repeat(2, 100px);
  gap: 12px;
  position: sticky;
  top: 0;
  background: #fff;
}