@import 'src/styles';

.tag{
  width: 100%;
  text-align: center;
}

.wrapper {
  font-size: $font-size-s;
  font-weight: 400;
  line-height: 18px;
  position: relative;
  transition: background 0.2s;
  padding: 4px;
  margin: 0 0 0 -4px;
  border-radius: $radius-s;
  cursor: pointer;

  p {
    @include trimText;
  }

  &.active,
  &:focus,
  &:hover {
    background: $blue-lightest-color;
  }

  &.yellow {
    background: $yellow-light-color;
  }

  &.active {
    z-index: 6;
  }
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 6px;
  z-index: 2;
}

.menu {
  @include element-flex(column, flex-start, center);
  gap: 12px;
  position: absolute;
  right: 0;
  top: calc(100% + 15px);
  padding: 18px 12px 12px;
  background: #fff;
  border-radius: $radius-s;
  box-shadow: 0 20px 64px -12px rgba(0, 0, 0, 0.08), 0 -10px 14px -4px rgba(0, 0, 0, 0.05), 0 28px 48px -8px rgba(0, 0, 0, 0.1);
  display: none;

  @include phoneWidth {
    left: 0;
    right: 20px;
    width: 150px;
  }

  &.active {
    display: flex;
    animation: fadeIn 0.1s ease-out forwards;
  }

  &.displayOver {
    position: fixed !important;
    transform: translateX(-100%) !important;
    right: unset;
  }

  &:before {
    content: '';
    position: absolute;
    right: 20px;
    bottom: 100%;
    width: 20px;
    height: 10px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E")
      no-repeat 50% 50% / 100% auto;

    @include phoneWidth {
      right: unset;
      left: 10px;
    }
  }
}

@keyframes fadeIn {
  @include keyframe-fadeIn();
}

.save {
  width: 100%;
}
