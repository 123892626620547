@import 'src/styles';

.container{
  width: 100%;
}

.wrapper{
  @include element-flex(column);
  gap: 24px;
  width: 100%;
}

.empty{
  margin: 0 auto;
}