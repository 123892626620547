@import 'src/styles';

.container {
  width: 100%;
  @include element-flex(column);
  gap: 24px;
  background: #fff;
  padding: 24px;
  border-radius: $radius-m;
  box-shadow: $shadow-main;
}

.row {
  width: 100%;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.buttonWrapper {
  grid-column: 1 / 4;
  width: 100%;
  margin-top: 24px;
  @include element-flex(row, center);
}

.column{
  @include element-flex(column);
  width: 100%;
  gap: 12px;
}

.sum{
  width: 100%;
}