@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 24px 1fr 1fr;
  gap: 24px;
  padding: 6px 12px;
  border-radius: $radius-s;
  min-height: 60px;

  &:nth-of-type(even) {
    background: $odd-row-background;
  }

  @include smDesktopWidth {
    @include element-flex(column, center);
    gap: 6px;
    position: relative;
    height: auto;
    min-height: 80px;
    padding-right: 40px;
  }
}

.checkbox {
  @include smDesktopWidth {
    position: absolute;
    right: 12px;
    top: 12px;
    width: fit-content;
  }
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px;
  overflow: hidden;
}

.text {
  @include trimText;
  font-size: $font-size-s;
  font-weight: 400;

  @include smDesktopWidth {
    font-size: $font-size-m;
  }
}
