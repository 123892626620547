@import 'src/styles';

.container{
  width: 100%;
  padding: 6px;
  border-radius: $radius-l;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  background: $gray-color;
  position: relative;
}

.option{
  width: 100%;
  padding: 12px 6px;
  border-radius: $radius-m;
  z-index: 2;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
}

.indicator{
  position: absolute;
  width: calc(50% - 12px);
  height: calc(100% - 12px);
  border-radius: $radius-m;
  top: 6px;
  left: 6px;
  background: #fff;
  z-index: 1;
  transition: all .2s ease;
  box-shadow: $shadow-main;

  &.right{
    transform: translateX(100%) translateX(12px);
  }
}