@import 'src/styles';

.container {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 15px 18px;
  width: 400px;
  border-radius: $radius-m;
  cursor: default;
  backdrop-filter: blur(30px) !important;
  -webkit-backdrop-filter: blur(30px) !important;

  @include smDesktopWidth {
    width: calc(100vw - 40px);
    padding: 12px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 15px;
  font-weight: $font-semiBold;
  color: #fff;
  cursor: text;
}

.icon {
  width: 17px;
  cursor: pointer;
  padding: 2px;
}

.message {
  padding-top: 4px;
  font-size: 14px;
  color: #fff;
  cursor: text;
}
