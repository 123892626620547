@import 'src/styles';

.container {
  width: 100%;
}

.center {
  @include element-flex(row, center, center);
  width: 100%;
  min-height: 200px;
}
