@import 'src/styles';

.container{
  @include element-flex(column, center, center);
  width: 100%;
  padding: 12px 8px;
  height: 100%;
  gap: 6px;
  min-height: 90px;
  border-radius: $radius-s;
  border: 1px solid $border-color-light;
  position: relative;
  transition: background-color 0.3s;
  background: $gray-lightest-color;
  max-height: 90px;
  margin: auto 0;

  &:hover {
    background: $gray-light-color;
  }
}

.label{
  width: 100%;
  text-align: center;
  color: $font-color-light;
  line-height: 15px;
}

.icon{
  width: 24px;
  height: 24px;
}

.wrapper{
  @include element-flex(column, flex-start, center);
  gap: 24px;
  max-width: 600px;

  @include smDesktopWidth{
    max-width: 90%;
  }
}

.box{
  display: grid;
  grid-template-columns: 1fr 120px;
  gap: 24px;
  min-width: 600px;

  @include smDesktopWidth{
    min-width: 100%;
    @include element-flex(column);
  }
}

.switchWrapper{
  width: 100%;
  margin-bottom: 24px;
}

.productWrapper{
  width: 100%;
  margin-bottom: 12px;
}
