@import 'src/styles';

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;

  @include desktopWidth() {
    @include element-flex(column, flex-start, stretch);
  }

  @include smDesktopWidth {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include tabletWidth {
    @include element-flex(column, flex-start, stretch);
  }
}

.column {
  width: 100%;
  @include element-flex(column, flex-start, stretch);
  gap: 12px;
  background: #fff;
  padding: 24px;
  border-radius: $radius-s;
  box-shadow: $shadow-main;

  @include shadow-on-focus;

  @include smDesktopWidth {
    padding: 18px 10px;
  }
}

.wrapper {
  width: 700px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.addNew {
  @include element-flex(row, center, center);
  gap: 12px;
  width: 100%;
  height: 100%;
  padding-right: 18px;
  border-radius: $radius-s;
  font-size: $font-size-l;
  min-height: 100px;
  background: $blue-lightest-color;

  &:hover {
    text-decoration: underline;
  }
}

.chooseAddress {
  text-align: center;
  width: 100%;
  font-size: $font-size-m;
  font-weight: normal;
}
