@import 'src/styles';

.box {
  @include element-flex(column);
  width: 100%;
  padding: 0 24px 24px;
  border-radius: $radius-m;
  box-shadow: $shadow-main;
  overflow: hidden;
  background-color: #fff;
  pointer-events: none;
  transition: background-color .2s;

  @include smDesktopWidth {
    width: calc(100% - 20px);
    margin: 0 10px;
    padding: 18px 10px;
  }

  &.section {
    box-shadow: none;
    padding: 6px;
    background-color: lighten($gray-lightest-color, 2%);
    border: 1px solid lighten($gray-lightest-color, 1%);
    border-radius: $radius-l;

    &.withActions:hover{
      background-color: darken($gray-lightest-color, 1%);
      border: 1px solid darken($gray-lightest-color, 1%);
    }
  }

  &.openOnArrowClick{
    pointer-events: all;
  }

  &.noPadding{
    padding: 0;
  }

  &.yellow{
    border: 2px solid $yellow-color;
    background: $yellow-light-color;

    &.withActions:hover{
      background-color: darken($yellow-light-color, 3%);
      border: 2px solid darken($yellow-color, 1%);
    }
  }

  @include shadow-on-focus;
}

.title {
  padding: 24px 0 0;
  width: 100%;
  text-align: left;
  font-size: $font-size-m;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: $font-bold;
  border-radius: $radius-m;
  pointer-events: auto;

  &.sectionTitle {
    padding: 0 12px;
  }

  &.noPadding{
    padding: 0 12px 0 0;
  }

  &.openOnArrowClick{
    pointer-events: all;
  }
}

.activeTitle {
  @extend .title;
  cursor: pointer;

  &.sectionTitle {
    padding: 0 12px;
    transition: background .2s;
  }

  &.openOnArrowClick{
    pointer-events: all;
  }

  @include smDesktopWidth {
    padding: 0;
  }
}

.titleWrapper{
  @include element-flex(column);
  width: 100%;
}


.content {
  overflow: hidden;
  width: 100%;
  max-height: 5000px;
  background-color: #fff;
  border-radius: $radius-m;
  padding: 0;
  pointer-events: auto;
  transition: max-height .2s ease-out;

  &.withGap::before {
      content: '';
      height: 24px;
      width: 100%;
      display: flex;
  }
}

.padding{
  padding: 6px;
  pointer-events: auto;
  border-radius: $radius-m;

  &.asCard{
    padding: 0;
  }
}

.arrow {
  width: 20px;
  min-width: 20px;
  height: 20px;
  transition: transform 0.2s ease-out;


  &.down {
    transform: rotateX(180deg);
    fill: $blue-color;
  }

  &.arrowWrapped {
    transform: rotateX(180deg);
    fill: $blue-color;
  }

  &.arrowWrappedDown {
    transform: rotateX(360deg);
    fill: black;
  }
}



.text {
  padding-bottom: 12px;
  font-size: $font-size-m;
}



