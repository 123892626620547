@import 'src/styles';

.idWrapper {
  width: auto;
  padding-right: 1ch;
  font-size: inherit;

  &.noPadding{
    padding: 0;
  }

  & > span {
    color: $blue-color;
  }
}
