@import '../../styles';

.container {
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 24px;
  margin: 22px auto 0;

  @include desktopWidth {
    @include element-flex(column, center);
    padding: 0;
    margin: 0 auto;
    gap: 12px;
  }
}
