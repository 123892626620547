@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr) 160px;
  gap: 12px;
  padding: 6px 12px;
  border-radius: $radius-s;
  min-height: 60px;
  position: relative;
  transition: background-color 0.2s;
}

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 6px;

  &.detailsWrapper {
    @include element-flex(column);
  }
}
