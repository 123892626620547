@import 'src/styles';
@import '../../../variables';

.container {
  gap: 12px;
  width: 100%;
  display: grid;
  padding: 6px;
  min-height: 60px;
  position: relative;
  border-radius: $radius-s;
  transition: background 0.2s;
  background: transparent;
  grid-template-columns: $gross-grid-template-columns;
  border: 1px solid transparent;

  &.containerNet {
    grid-template-columns: $net-grid-template-columns;

    .wrapper.priceInputWrapper {
      @include customBreakpoint(1400px) {
        grid-row: 1;
        grid-column: 2;
      }

      @include tabletWidth() {
        grid-row: 3;
        grid-column: 1;
      }
    }
  }

  @include customBreakpoint(1400px) {
    grid-template-columns: $custom-breakpoint-grid-template-columns !important;
    column-gap: 24px;
  }

  @include customBreakpoint(1100px) {
    grid-template-columns: $smDesktopWidth-grid-template-columns !important;
  }

  @include tabletWidth {
    grid-template-columns: $desktopWidth-grid-template-columns !important;
    gap: 24px;
  }
}

.offerContainer {
  width: 100%;
  display: grid;
  gap: 12px;
  padding: 6px;
  border-radius: $radius-s;
  min-height: 80px;
  position: relative;
  background: #fff;
  border: 1px solid $border-color-light;
  box-shadow: $shadow-main;

  grid-column: 1 / 7;
  grid-template-columns: $gross-grid-template-columns;

  &.net {
    grid-column: 1 / 8;
    grid-template-columns: $net-grid-template-columns;

    .wrapper.priceInputWrapper {
      @include customBreakpoint(1400px) {
        grid-row: 1;
        grid-column: 2;
      }

      @include tabletWidth {
        grid-row: 3;
        grid-column: 1;
      }
    }
  }

  @include customBreakpoint(1400px) {
    grid-template-columns: $custom-breakpoint-grid-template-columns !important;
    grid-column: 1 / 7 !important;
    column-gap: 24px;
    padding: 12px 6px;
  }

  @include customBreakpoint(1100px) {
    grid-template-columns: $smDesktopWidth-grid-template-columns !important;
    grid-column: 1 / 6 !important;
  }

  @include tabletWidth {
    grid-template-columns: $desktopWidth-grid-template-columns !important;
    grid-column: 1 / 3 !important;
    gap: 24px;
  }
}

.replacementContainer {
  @extend .offerContainer;

  &::before {
    width: calc(100% + 12px) !important;
    height: calc(100% + 12px);
  }
}

.commentContainer {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  padding: 0;
  gap: 12px;
  grid-column: 1 / 7;
  position: relative;

  &.net {
    grid-column: 1 / 8;
  }

  &.saved {
    position: relative;

    &:after {
      content: 'zapisano';
      font-size: 10px;
      color: $font-color-light;
      height: 100px;
      position: absolute;
      right: 18px;
      top: 18px;
    }
  }
}

.textArea {
  width: 100%;
  padding: 18px 12px;
  border-radius: $radius-s;
  border: 1px solid $border-color-light;
  min-height: 80px;
  height: auto;
  font-size: $font-size-s;
  resize: vertical;
  transition: all 0.2s;

  &.saved {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid transparent;
    resize: none;
    height: auto;
  }

  &:focus {
    border: 1px solid #4849ff;
    box-shadow: 0 0 0 1px #4849ff;
  }

  &:active {
    border: 1px solid rgba(137, 169, 212, 0.48) !important;
  }

  &.disabled {
    pointer-events: none;
  }
}

.saveButton {
  width: 32px;
  height: 32px;
  margin-left: auto;
}

.column {
  @include element-flex(column, flex-start, flex-end);
  width: 100%;
  gap: 6px;
}
