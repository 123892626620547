@import '../../../styles/index';

.container {
  @include element-flex(column);
  width: 100%;
  gap: 12px;
}

.title {
  font-size: $font-size-xxl;
  font-family: $main-font;
  font-weight: $font-bold;
}

.text {
  font-family: $main-font;
  font-size: $font-size-m;
  line-height: normal;
  font-weight: normal;
}
