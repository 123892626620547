@import 'src/styles';

.container {
  @include element-flex(column, flex-start, stretch);
  margin: 0;
  width: 100%;
}

.allResults {
  width: 100%;
  @include element-flex(row, center, center);
  text-align: center;
  padding: 24px 12px 12px;
  color: $font-color-light;
}

.wrapper {
  @include smDesktopWidth {
    padding-bottom: 48px;
  }
}
