@import 'src/styles';

.container{
  width: 100%;
  display: grid;
  grid-template-columns: 300px repeat(2, 100px);
  gap: 12px;
  padding: 6px 12px;

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }
}