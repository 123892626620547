@import 'src/styles';

.container {
  @include element-flex(column, center, center);
  width: 100%;
  min-height: calc(100vh - ($nav-height * 2) - 56px);
}

.main {
  @include element-flex(column, center, center);
}

.message {
  font-size: $font-size-xxs;
  color: $font-color-light;
  margin: 20px 0;
  padding: 0 calc(50% - 564px);

  @include desktopWidth {
    padding: 0 24px;
  }

  @include tabletWidth {
    text-align: center;
  }
}
