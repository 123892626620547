@import '../../styles';

.container {
  @include element-flex(column);
  gap: 36px;
  width: 100%;
  padding: 0 calc(50% - 700px) 50px;

  @include desktopWidth {
    padding: 0 10px 20px;
  }

  @include tabletWidth {
    padding: 0 10px 72px;
  }
}

.pageHeader {
  &.footer {
    margin: 0 !important;
  }

  @include smDesktopWidth {
    padding: 24px 0 0;
  }
}

.footerHeader {
  width: 100%;
}

.headerWrapper {
  @extend .container;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.row {
  @include element-flex(row);
  gap: 24px;
}

.rowWrapper {
  @include element-flex(row, space-between, center);
  width: 100%;

  @include tabletWidth {
    @include element-flex(column);
    gap: 24px;
  }
}

.header {
  grid-template-columns: 1fr 130px 90px repeat(2, 120px) 100px 160px 40px;
  gap: 12px;
  padding: 18px 12px;
}

.createdDate {
  flex: 1;
  font-size: $font-size-xl;
  font-weight: 400;
}

.wrapper {
  @include element-flex(column);
  width: 100%;
  border-radius: $radius-s;
  background: #ffffff;
  padding: 24px;
}

.inlineSection {
  display: flex;
  justify-content: space-around;
}

.section {
  padding: 24px;
  border-radius: $radius-s;
  background: #ffffff;
  margin-top: 24px;
}

.textArea {
  width: 100%;
  padding: 12px;
  border-radius: $radius-s;
  border: 1px solid $border-color-light;
  min-height: 120px;
  font-size: $font-size-s;
  resize: vertical;
  transition: all 0.2s;

  &:focus {
    border: 1px solid #4849ff;
    box-shadow: 0 0 0 1px #4849ff;
  }
}

.title {
  font-size: $font-size-l;
  font-weight: bold;
  margin-bottom: 12px;
}

.extrasHeader {
  grid-template-columns: 25% 10% 25% 10% 1fr;
}

.select {
  width: 150px;

  @include tabletWidth {
    width: calc(100vw - 20px);
  }
}

.headerContent {
  @include element-flex(row, flex-end, flex-end);
  gap: 24px;

  @include tabletWidth {
    @include element-flex(row, flex-start, flex-end);
    width: 100%;
    flex-wrap: wrap;
  }
}

.priceType {
  @include element-flex(row, flex-start, flex-start);
  gap: 6px;
  z-index: 2;
  max-height: 40px;
  transform: translateY(-22px);

  @include smDesktopWidth {
    @include element-flex(column);
    max-height: unset;
    transform: none;
  }
}

.supplierInfo {
  text-align: center;
  padding: 10px;
  color: $font-color-light;
  opacity: 0.7;
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
}

.button {
  width: 150px;

  @include smDesktopWidth {
    width: auto;
  }
}

.info {
  @include element-flex(row, space-between, flex-start);
  flex-wrap: wrap;
  width: 100%;
  gap: 6px;

  //@include desktopWidth{
  //  @include element-flex(column-reverse, flex-start, flex-end);
  //  gap: 24px;
  //}

  @include smDesktopWidth {
    @include element-flex(column-reverse, flex-start, flex-start);
    gap: 24px;
    margin: 24px 0 0;
  }
}

.valuationInfo {
  @include element-flex(column);
  gap: 4px;
  font-size: $font-size-s;

  &.reverse {
    align-items: flex-end;
    gap: 12px;

    @include tabletWidth {
      align-items: flex-start;
    }
  }

  //@include desktopWidth{
  //  align-items: flex-end;
  //}

  @include tabletWidth {
    width: 100%;
    align-items: flex-start;
  }
}

.sum {
  margin-top: 12px;
  font-size: $font-size-xl;
  letter-spacing: 0;
  font-weight: 700;
}

.buttonWrapper {
  width: 100%;
  @include element-flex(row, flex-end, center);
  gap: 24px;

  @include tabletWidth {
    @include element-flex(row, flex-start, flex-end);
    width: 100%;
    flex-wrap: wrap;
  }
}
