@import 'src/styles';

.container{
  width: 100%;
  min-height: 392px;
  height: 100%;
  max-height: 440px;
}

.wrapper{
  @include element-flex(column, flex-start, stretch);
  gap: 6px;
  height: 100%;
  overflow: auto;
}

.card{
  height: 100%;
  width: 100%;
}

.emptyCard{
  height: 440px;
  width: 100%;
  @include element-flex(column, center, center);
}
