@import '../../../../styles';

.header {
  grid-template-columns: 1fr 200px 200px 40px;
  gap: 24px;

  @include smDesktopWidth {
    display: none;
  }
}
