@import '../../../styles';

.container {
  width: 100%;
  min-width: 180px;
  height: 175px;
  padding: 18px 12px;
  border-radius: $radius-s;
  border: 1px solid $border-color-lighter;
  display: grid;
  gap: 12px;
  grid-template-columns: 100%;
  grid-template-rows: 24px 1fr;
  position: relative;
  transition: background-color 0.3s;
  z-index: 1;

  &.large {
    height: 200px;
  }

  &.small{
    height: 150px;
    min-width: 180px;
  }

  &:hover {
    background: rgba(0, 14, 66, 0.015);
  }
}

.header {
  @include element-flex(row, space-between, center);
  width: 100%;
}

.optionName {
  font-weight: $font-bold;
  font-size: $font-size-s;
  line-height: 24px;
}

.wrapper {
  @include element-flex(column);
  width: 100%;
  height: 100%;
}

.loaderWrapper {
  @include element-flex(row, center, center);
  height: 100%;
  width: 24px;

  &:first-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.inputWrapper {
  border-radius: 50%;
  border: 1px solid $gray-color;
  display: grid;
  place-items: center;
  z-index: 2;

  &.tick {
    border: none;
    position: relative;
    cursor: pointer;
  }
}

.box {
  width: 24px;
  height: 24px;
  border: 6px solid transparent;
  border-radius: 50%;
  @include appearance(none);
  cursor: pointer;

  &__error {
    box-shadow: $shadow-input-error;
    border-color: $danger-color;
  }

  &:checked {
    border: 6px solid #ffffff;
    background: $blue-color;
  }

  &.tick {
    border: 1px solid $font-color-light;

    &:checked {
      border: 1px solid $green-color;
      background: url('../../../assets/icons/tick.svg') no-repeat center $green-color;
    }
  }
}

.background {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: $radius-s;
  background: $blue-color;
  opacity: 0.05;
  z-index: 1;

  &.disabled {
    z-index: 10;
    opacity: 1;
    background: $gray-color;
    cursor: not-allowed;
  }
}

.hideBorder {
  border-color: transparent;
}

.borderSelected {
  border: 1px solid $blue-light-color;
}
