@use './components/SingleProduct/SingleProduct.module' as item;
@import 'src/styles';

.box {
  width: 100%;
  @include element-flex(column);
}

.header {
  @include element-flex(column);
  gap: 6px;
  padding: 6px;
  position: relative;
  width: 100%;
}

.arrow {
  position: absolute;
  right: 0;
  top: 50%;
  width: 48px;
  height: 60px;
  padding: 12px;
  transform: translateY(-50%);

  &.down {
    transform: translateY(-50%) rotateX(180deg);
    fill: $blue-color;
  }
}

.valuationsHeader {
  display: grid;
  grid-template-columns: item.$grid-template-columns;
  width: 100%;
  padding: 3px 6px 6px;
  background: #fff;
  gap: 12px;
  border-radius: $radius-s;

  & > p {
    font-weight: 700;
  }
}

.elementsWrapper {
  @include element-flex(column);
  background: #fff;
  padding: 6px;
  width: 100%;
  border-radius: $radius-s;
}

.description {
  font-size: 15px;
  // padding-bottom: 6px;
}

.title {
  padding-top: 6px;
}

.titleWrapper {
  width: 100%;
  padding: 6px 12px 6px 0;
  @include element-flex(row, space-between, center);
  gap: 24px;
}

.column {
  @include element-flex(column);
}

.tableHeader {
  display: grid;
  grid-template-columns: 2fr 150px 3fr 150px;
  gap: 12px;
  padding: 6px;
  border: none;
}

.text {
  margin: -12px 0 12px;
}

.notAssignedOffersWrapper {
  width: 100%;
}

.notAssignedOffersWrapperTitle {
  @extend .description;
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  margin-top: 15px !important;
  border-radius: 10px;
  overflow: hidden;
  background-color: $yellow-light-color;
}
