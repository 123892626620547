@import 'src/styles';

.container {
  @include element-flex(column, flex-start, center);
  gap: 24px;
}

.input {
  width: auto;
  margin: 0 auto;
  z-index: 2;
}
