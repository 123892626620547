@import 'src/styles';

.contentWrapper {
  @include element-flex(column);
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

.priceWrapper {
  @include element-flex(row, flex-end, baseline);
  gap: 1ch;
  height: 100%;
}

.underline{
  text-decoration: underline;
  cursor: pointer;
}

.price {
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: 700;
  position: relative;
  height: 24px;
  cursor: pointer;

  &.empty::before {
    content: '';
    width: 32px;
    height: 2px;
    background: $font-color;
    border-radius: 99px;
    position: absolute;
    left: 0;
    top: 11px;
  }

  &.empty::after {
    content: 'zł';
    width: fit-content;
    height: fit-content;
    color: $font-color;
    position: absolute;
    left: 35px;
    top: 0;
  }
}

.offerBox {
  @include element-flex(column);
  gap: 16px;
  margin: 0;
  width: 100%;
}

.optionCheckbox{
  min-height: 90px;
  height: auto;
  padding: 12px 8px;
}

.replacement {
  background: rgba(0, 1, 219, 0.07);
  border-color: $border-color;

  &:hover {
    background: rgba(0, 1, 219, 0.1);
  }
}

.settings {
  @include element-flex(row, space-between, center);
  gap: 12px;
  width: 100%;
}

.info{
  position: absolute;
  top: 12px;
  right: 40px;
}

.tooltip{
  @include element-flex(column);
  gap: 6px;
}

.down{
  fill: #5bd75b;
  width: 14px;
  height: 14px;
  margin-top: -6px;
  transform: rotateZ(180deg);

  *{
    fill: #5bd75b;
  }
}

.up{
  fill: $danger-color;
  width: 14px;
  height: 14px;
  margin-top: -6px;

  *{
    fill: $danger-color;
  }
}