@import '/src/styles';

.container {
  width: 100%;
  height: $nav-height;
  padding: 0 calc(50% - 564px);
  @include element-flex(row, flex-start, center);
  border-bottom: 1px solid $border-color-lighter;
  background: #fff;
  position: relative;
  z-index: 100;

  &.medicover{
    padding: 0 calc(50% - 600px);
    @include element-flex(row, space-between, center);
    width: 100%;
  }

  &.ue {
    @include smDesktopWidth {
      flex-wrap: wrap;
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  @include desktopWidth {
    padding: 0 24px;
  }
}

.logo {
  max-width: 130px;

  &.ue {
    max-width: 320px;
  }
}

.medicoverLogo {
  max-height: 50px;
  width: 210px;

  &:last-of-type {
    width: 300px;
  }

  @include tabletWidth {
    max-height: 40px;

    width: 120px;

    &:last-of-type {
      width: 190px;
    }
  }

  @include phoneWidth {
    max-height: 35px;
  }
}