@import 'src/styles';

.container {
  @include element-flex(column);
  gap: 24px;
  width: 100%;
  max-width: 600px;
}

.text {
  font-size: $font-size-s;
  font-weight: 400;
  word-break: break-word;

  &.trim {
    @include limit-lines(3);
    cursor: pointer;
    line-height: 16px;
  }
}

.comment {
  width: 100%;
  margin-bottom: 24px;
  font-size: $font-size-m;
  line-height: 26px;
  overflow: auto;
  max-height: 75vh;
}
