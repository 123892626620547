@import '../../../../styles/index';

.container {
  @include element-flex(column, flex-start, stretch);
  width: 100%;
  //padding: 24px;
  background: #ffffff;
  border-radius: $radius-s;
  overflow: auto;
  max-height: 85vh;
  box-shadow: $shadow-main;
}

.columnHeader {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px;
}

.wrapper {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}
