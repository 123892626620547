@import '../../../../styles/helpers';
@import 'src/styles';

.user {
  position: relative;
  @include m {
    position: static;
  }
  &.active {
    .head {
      svg {
        fill: $n7;
        @include dark {
          fill: $n1;
        }
      }
    }
    .body {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}
.head {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  svg {
    fill: $n4;
    transition: fill 0.2s;
    &:hover {
      svg {
        fill: $n7;
        @include dark {
          fill: $n1;
        }
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
.body {
  position: absolute;
  top: calc(100% + 18px);
  right: -20px;
  width: 300px;
  padding: 18px 12px;
  border-radius: $radius-l;
  border: 1px solid transparent;
  box-shadow: 0 40px 64px -12px rgba(0, 0, 0, 0.08), 0 0 14px -4px rgba(0, 0, 0, 0.05), 0 32px 48px -8px rgba(0, 0, 0, 0.1);
  background: $n;
  visibility: hidden;
  opacity: 0;
  transform: translateY(3px);
  transition: all 0.2s;
  @include m {
    top: 100%;
    right: 16px;
    left: 16px;
    width: auto;
    padding: 12px 16px 20px;
  }
  &:before {
    content: '';
    position: absolute;
    right: 29px;
    bottom: 100%;
    width: 20px;
    height: 10px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E")
      no-repeat 50% 50% / 100% auto;
    @include m {
      right: 12px;
    }
  }
}

.menu {
  &:not(:last-child) {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid $n3;
    @include m {
      margin-bottom: 8px;
      padding-bottom: 8px;
    }
    @include dark {
      border-color: $n6;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 12px;
  border-radius: 12px;
  @include base1-s;
  color: $n4;
  transition: all 0.2s;

  svg {
    margin-right: 12px;
    fill: $n4;
    transition: fill 0.2s;
  }
  &:hover,
  &.active {
    color: $n7;
    svg {
      fill: $n7;
    }
    @include dark {
      color: $n1;
      svg {
        fill: $n1;
      }
    }
  }
  &.active {
    background: $n2;
    @include dark {
      background: transparent;
    }
  }
  &.color {
    color: $p4;
    svg {
      fill: $p4;
    }
    @include dark {
      color: $p4;
      svg {
        fill: $p4;
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
