@import 'src/styles';

.container{
  width: calc(100% - 140px);
  @include element-flex(column, center);
  margin-left: 140px;
  position: relative;
  background: $gray-lightest-color;
  padding: 8px 50px 8px 12px;
  border-radius: $radius-s;
  min-height: 85px;

  .amount{
    position: absolute;
    left: -140px;
    top: 50%;
    transform: translateY(-50%);
    text-align: right;
    width: 120px;
  }

  &.withIndicator:after{
    content: '';
    height: calc(100% + 24px);
    width: 2px;
    background: $blue-color;
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateX(-50%);
  }

  &:before{
    content: '';
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: $blue-color;
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.title{
  margin-bottom: 3px;
}

.menu{
  top: 8px;
  right: 12px;
}