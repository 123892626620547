@import '../../styles';

.container {
  @include element-flex(column);
  gap: 24px;
  width: 100%;
}

.contentWrapper {
  @include element-flex(row);
  width: 100%;
}

.leftColumn {
  flex-grow: 1;

  @include tabletWidth {
    margin-left: 10px;
  }
}

.tag {
  max-width: unset !important;
  @include printView {
    display: none;
  }
}

.rightColumn {
  @include element-flex(row, stretch);
  overflow: auto;
  border-radius: 0 $radius-s $radius-s 0;
  max-width: 636px;
}

.tableWrapper {
  border-radius: $radius-s 0 0 $radius-s;
  padding: 12px 0 12px 12px;
}

.optionsWrapper {
  display: grid;
  grid-template-columns: 1fr 30% 20%;
  gap: 12px;
  padding: 12px;
  border-radius: $radius-s $radius-s 0 0;
  position: relative;
  min-height: 187px;
}

.optionsInfo {
  @include element-flex(column);
  grid-column: 1 / 4;
  width: 100%;
  padding: 18px 12px;
  gap: 12px;
}

.text {
  font-size: $font-size-s;
  font-weight: $font-bold;
  line-height: 24px;
}

.label {
  font-size: $font-size-m;
  font-weight: $font-bold;
  line-height: 36px;
}

.finalAmount {
  width: 100%;
  text-align: right;
  padding: 12px 6px;
}

.orderDetails {
  @include element-flex(column, flex-start, flex-start);
  gap: 6px;
  margin-bottom: 12px;
  font-size: $font-size-s;

  @include smDesktopWidth {
    padding: 0 10px;
  }
}

.fullWidth {
  max-width: unset !important;
}
