@import 'src/styles';

.orderHeader {
  @include element-flex(row, flex-start, center);
  gap: 6px;
  width: 100%;
  font-size: $font-size-xl;
  font-weight: 800;
  padding: 6px 12px 18px;
  border-bottom: 1px solid $border-color-lighter;
  height: 53px;

  &.withoutBorder{
    border: none;
    padding: 6px 12px 12px;
  }

  @include desktopWidth {
    flex-wrap: wrap;
    height: auto;
  }

  @include smDesktopWidth {
    @include element-flex(column);
    height: auto;
    gap: 6px;
  }

  @include tabletWidth {
    padding: 6px 10px 18px;
  }
}

.tag {
  max-width: unset !important;
}

.supplierName {
  @include element-flex(row, flex-start, center);
  gap: 6px;

  @include smDesktopWidth {
    flex-wrap: wrap;
  }
}

.name {
  font-size: $font-size-xl;
  font-weight: 800;
  color: $font-color;

  @include smDesktopWidth {
    width: 100%;
  }
}

.productsNumber {
  font-size: $font-size-s;
  color: $font-color-light;
  cursor: pointer;
}

.topStatus {
  margin-left: auto;
  @include element-flex(row, flex-end, center);
  gap: 24px;

  @include smDesktopWidth {
    @include element-flex(column-reverse);
    margin-left: 0;
    gap: 6px;
  }
}
