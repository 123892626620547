@import 'src/styles';

.buttonsWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: $spacing-5;
  margin-top: $spacing-7;
}

.modal {
  max-width: 500px;
}
