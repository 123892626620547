@import 'src/styles';

.container {
  @include element-flex(column, flex-start, stretch);
  gap: 12px;
  width: calc(100% + 48px);
  margin: 0 0 30px -24px;
  z-index: 10;
  min-height: 80px;
  position: sticky;
  top: 75px;
  padding: 18px 24px;
  border-radius: $radius-m;
  background: #ffffff;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: $shadow-main;

  @include smDesktopWidth {
    width: 100%;
    border-radius: 0;
    margin: 0 0 12px 0;
    top: 68px;
    padding: 18px;
  }

  &_adminView {
    top: 115px;

    @include smDesktopWidth {
      top: 108px;
    }
  }
}

.header {
  @include element-flex(row, space-between, center);
  width: 100%;

  @include smDesktopWidth {
    border-radius: 0;
    margin: 0;
    padding: 0;
    height: 40px;
  }
}

.buttonWrapper {
  @include element-flex(row, flex-end, center);
  gap: 24px;
  width: auto;
}

.content {
  @include element-flex(column, flex-start, flex-end);
  gap: 12px;
  width: max-content;
}
