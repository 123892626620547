@import '../../styles';

.container {
  width: 100%;
}

.header {
  @include element-flex(row, space-between, center);
  width: 100%;
  margin: 0 0 48px;
  position: sticky;
  top: 68px;
  z-index: 10;
  padding: 18px 24px;
  border-radius: $radius-s;
  background: #ffffff;
  box-shadow: $shadow-main;
}

.buttonWrapper {
  @include element-flex(row, flex-end, center);
  gap: 24px;
}

.content {
  @include element-flex(column);
  width: 100%;
  gap: 16px;
}

.switch {
  @include element-flex(row, center, center);
  gap: 6px;
  white-space: nowrap;
}
