@import '../../../styles';

.visibilityButton {
  @include element-flex(row, center, center);
  width: 24px;
  height: 20px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  position: absolute;
  right: 12px;
  top: 21px;
  transform: translateY(-50%);
  box-sizing: border-box;

  & > img {
    cursor: pointer;
  }

  &.withLabel {
    top: 42px;
  }
}
