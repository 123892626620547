@import 'src/styles';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 140px 120px 80px 80px 100px 130px 90px;
  gap: 12px;
  padding: 6px;
  border-radius: $radius-s;
  min-height: 60px;
  position: relative;
  transition: background-color 0.2s;

  &.willUnmount {
    height: 60px;
    min-height: unset;
    animation: hide 0.4s ease-in forwards;
    overflow: hidden;

    @include lgDesktopWidth {
      height: 209px;
    }

    @include tabletWidth {
      height: 366px;
    }

    @include phoneWidth {
      height: 410px;
    }
  }

  &.wasUnmount {
    height: 0;
    display: none;
  }

  &:nth-of-type(even) {
    background: $odd-row-background;
  }

  @include lgDesktopWidth {
    grid-template-columns: 2fr repeat(4, 1fr);
    row-gap: 24px;
    padding: 18px;
  }

  @include smDesktopWidth {
    grid-template-columns: 2fr repeat(3, 110px);
  }

  @include tabletWidth {
    grid-template-columns: 3fr 2fr;
    padding: 18px 10px;
  }

  @include phoneWidth {
    @include element-flex(column);
  }
}

.wrapper {
  @include element-flex(row, stretch, center);
  gap: 12px;

  &.price {
    justify-content: flex-end;
    padding-right: 12px;

    @include lgDesktopWidth {
      padding: 0;
      justify-content: flex-start;
    }
  }

  &.menu {
    justify-content: flex-end;

    @include lgDesktopWidth {
      justify-content: flex-start;
      grid-column: 6;
      grid-row: 1;
    }

    @include tabletWidth {
      grid-column: unset;
      position: absolute;
      right: 10px;
      top: 0;
    }

    @include phoneWidth {
      top: 18px;
    }
  }

  @include lgDesktopWidth {
    @include element-flex(column);
    gap: 6px;
    & > p {
      line-height: 40px;
    }
  }

  &.produceDetails {
    & > p {
      line-height: 22px;
    }

    @include lgDesktopWidth {
      @include element-flex(row, stretch, center);
      grid-column: 1 / 6;
      grid-row: 1;
      padding-right: 50px;
    }

    @include tabletWidth {
      grid-column: 1 / 3 !important;
    }
  }
}

.text {
  font-size: $font-size-s;
  font-weight: 400;

  @include smDesktopWidth {
    font-size: $font-size-m;
    text-align: left;
    width: 100%;
  }
}

.save {
  position: relative;
  transform: translateY(-50%);
  top: 50%;

  @include smDesktopWidth {
    top: 12px;
    right: 12px;
    transform: none;
    position: absolute;
  }
}

.column {
  @include element-flex(column, flex-start, stretch);
  width: 100%;
}

.center {
  width: 100%;

  @include smDesktopWidth {
    justify-content: center;
    display: flex;
  }
}

.mobileLabel {
  line-height: normal !important;
}

.qty {
  @include smDesktopWidth {
    width: 100% !important;
  }
}

.picker {
  max-width: 136px;
  border-radius: $radius-s;

  &.error input{
    background: transparentize($danger-color, 0.3) !important;
  }

  &.warning input {
    background: transparentize($yellow-color, 0.3) !important;
  }
}

.inline_column {
  display: flex;
  justify-content: space-between;
}

.nameWrapper {
  @include smDesktopWidth {
    @include element-flex(row, flex-start, center);
    width: 100%;
    gap: 12px;
  }
}

.menu {
  position: static;
}

.state {
  padding-left: 6px;
}

@keyframes hide {
  0% {
    height: 60px;
  }
  100% {
    height: 0;
    display: none !important;
    visibility: hidden;
    padding: 0 6px;
  }
}
