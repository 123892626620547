@import './src/styles';

.buttonWrapper {
  position: relative;

  @include element-flex(row, flex-end, center);
  gap: 24px;

  @include smDesktopWidth {
    display: none;
  }
}

.phoneInside {
  display: none;
  margin-right: 30px;

  @include lgDesktopWidth {
    display: block;
  }

  @include smDesktopWidth {
    margin-left: auto;
  }
}

.phoneOutside {
  display: block;
  position: absolute;
  right: -150px;

  @include lgDesktopWidth {
    display: none;
  }
}
