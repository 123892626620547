@import '../../../styles';

.container {
  width: 100%;

  &.empty {
    position: relative;
    z-index: 0;
  }
}

.empty {
  @include element-flex(column, center, center);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: $radius-s;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1;
  pointer-events: none;
}
