@import 'src/styles';

.content {
  display: flex;
  gap: 24px;
  width: 100%;
  @include lgDesktopWidth {
    flex-direction: column;
  }
}
