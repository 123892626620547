@import 'src/styles';

.container {
  @include element-flex(row, stretch, flex-start);
  width: 100%;
  gap: 24px;

  @include desktopWidth {
    @include element-flex(column, flex-start, stretch);
  }
}

.wrapper {
  @include element-flex(column, stretch, stretch);
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 24px;
  gap: 12px;
  border-radius: $radius-s;
  box-shadow: $shadow-main;

  & > .row {
    height: 50px;
  }

  @include tabletWidth {
    padding: 18px 10px;
  }
}

.extraCost{
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.contentWrapper{
  @include element-flex(column, stretch, stretch);
  gap: 24px;
}

.title {
  text-align: left;
  width: 100%;
}

.row {
  width: 100%;
  padding: 12px 12px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 12px;
  flex: 1;
  border-top: 1px solid $border-color-lighter;
  position: relative;

  &:last-of-type {
    border-bottom: 1px solid $border-color-lighter;
  }
}

.modalFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: $spacing-4;
}

.label {
  @include element-flex(row, flex-start, center);
  font-weight: $font-bold;
}

.value {
  @include element-flex(row, flex-start, center);
}

.actions {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
