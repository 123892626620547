@import '../../../../styles/index';

.container {
  @include element-flex(column);
  gap: 12px;
  width: 100%;
}

.details {
  @include element-flex(column);
  padding: 6px 12px;
  border-left: 1px solid $font-color-light;
}

.text {
  font-size: $font-size-m;
  font-weight: normal;
}

.wrapper {
  @include element-flex(row, flex-start, center);
  width: 100%;
  gap: 24px;

  &.single {
    @include element-flex(column, flex-start, stretch);
  }

  @include smDesktopWidth {
    @include element-flex(column, flex-start, stretch);
  }
}

.addNew {
  @include element-flex(row, center, center);
  gap: 12px;
  width: 100%;
  height: 100%;
  padding-right: 18px;
  border-radius: $radius-s;
  font-size: $font-size-l;
  min-height: 100px;
  background: $blue-lightest-color;

  &:hover {
    text-decoration: underline;
  }
}
