@import 'src/styles';

.questions {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.box {
  background-color: $gray-lightest-color;
  padding: 12px 12px 0;
  border-radius: $radius-s;
  overflow: hidden;
}

.question {
  width: 100%;
  cursor: pointer;
  text-align: left;
  font-size: $font-size-m;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-weight: $font-bold;
  gap: 6px;
}

.active {
  color: $blue-color;
}

.answer {
  transition: height 0.2s ease-out;
}

.arrow {
  width: 20px;
  min-width: 20px;
  height: 20px;
  transition: transform 0.2s ease-out;

  &.down {
    transform: rotateX(180deg);
    fill: $blue-color;
  }
}



.text {
  padding-bottom: 12px;
  font-size: $font-size-m;
}

.list:last-child {
  padding-bottom: 12px;
}

.list_item {
  font-size: $font-size-m;
  font-weight: normal;
  list-style: inside;
}
