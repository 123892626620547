@import '../../../styles';

.container {
  width: 100%;
  position: relative;

  &.pickerError > .SingleDatePicker {
    border: 1px solid $danger-color !important;
  }

  &.pickerError:focus-within > .SingleDatePicker {
    border: 1px solid $danger-color;
    box-shadow: 0 0 0 1px $danger-color;
  }
}

.wrapper {
  display: flex;
  align-items: flex-start;
}

.label {
  display: block;
  font-size: $font-size-s;
  color: $font-color;
  font-weight: normal;
  line-height: 20px;
}

.visuallyHidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.required {
  color: $danger-color;
  font-size: 17px;
  line-height: 20px;
  margin-left: -2px;
}

.descriptionField {
  padding-bottom: 6px;
  font-size: $font-size-s;
  color: #ccc;
}

.errorMessage {
  color: $danger-color;
  font-size: $font-size-xs;
  animation: fadeIn 0.4s;
  width: 100%;
  margin-top: 4px;

  & > div {
    line-height: 12px;
    letter-spacing: -0.3px;
  }
}

@keyframes fadeIn {
  @include keyframe-fadeIn;
}
