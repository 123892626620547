@import 'src/styles';

.container {
  @include element-flex(row, flex-start, center);
  gap: 6px;
  width: 100%;
}

.price {
  width: 100% !important;
  @include smDesktopWidth {
    width: 100% !important;
  }
}
