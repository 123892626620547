@import 'src/styles';

.container {
  width: 600px;
  max-width: 30vw;
  max-height: calc(100vh - 100px);
  position: fixed;
  top: 42px;
  z-index: 10;
  display: grid;
  grid-template-columns: 100%;
  padding: 35px 18px 25px;
  transition: transform 0.5s cubic-bezier(0.46, 1.19, 0.46, 1.04), box-shadow 0.5s ease;
  background: rgba(242, 245, 249, 0.7);
  box-shadow: 0 8px 22px 0 rgba(31, 38, 135, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 0 0 $radius-s $radius-s;
  border: 1px solid rgba(255, 255, 255, 0.18);
  left: 160px;

  &.sidebarOpen {
    left: 320px;
  }

  &.hidden {
    transition: transform 0.3s ease, box-shadow 0.3s ease !important;
    transform: translateY(calc(-100% + 20px));
    box-shadow: none;
  }

  @include smDesktopWidth {
    left: 10px !important;
    right: 10px !important;
    width: calc(100vw - 20px) !important;
    max-width: unset;
    padding: 40px 10px;
  }
}

.toggleBtn {
  position: absolute;
  width: 48px;
  padding: 12px 6px 6px;
  height: 60px;
  left: 100px;
  bottom: -57px;
  background: #fff;
  border-radius: $radius-s;
  box-shadow: 0 8px 25px 0 rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-top: none;

  @include smDesktopWidth {
    bottom: -52px;
    height: 55px;
    left: unset;
    right: 5px;
  }
}

.icon {
  width: 30px;
  height: 30px;
  fill: $blue-color;

  & > path,
  & > g > path {
    fill: $blue-color;
  }
}

.wrapper {
  @include element-flex(column);
  gap: 12px;
}

.switch {
  @include element-flex(row, flex-end, center);
  gap: 6px;
}