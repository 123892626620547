@import 'src/styles';

.container {
  @include element-flex(column-reverse, flex-start, center);
  gap: 12px;
  width: 100%;
}

.button {
  font-size: $font-size-s;
  padding: 3px 2px;
  margin-left: 2px;
  border-radius: $radius-s;
  transition: background 0.2s;
  background: rgba(72, 73, 255, 0.15);
  margin-top: 3px;
  font-weight: 700;

  &:hover {
    background: rgba(72, 73, 255, 0.3);
  }

  &.disabled {
    background: transparent;
    cursor: not-allowed;
    color: $font-color-light;

    &:hover {
      background: transparent;
    }
  }
}
