@import 'src/styles';

.container{
  @include element-flex(column, space-between, center);
  width: 100%;
  padding: 12px 8px;
  height: 100%;
  gap: 3px;
  min-height: 90px;
  border-radius: $radius-s;
  border: 1px solid $border-color-light;
  position: relative;
  transition: background-color 0.3s;
  background: $gray-lightest-color;
  max-height: 90px;
  margin-top: auto;
  color: $blue-color;
  font-weight: $font-bold;

  &:hover {
    background: $gray-light-color;
  }
}

.box{
  @include element-flex(row, center, center);
  gap: 3px;
}

.icon{
  width: 26px;
  height: 26px;
}

.counter{
  font-size: $font-size-l;
}

.label{
  width: 100%;
  text-align: center;
  color: $font-color-light;
  line-height: 15px;
}