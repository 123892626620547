@import 'src/styles';

.container {
  width: 100%;
  min-height: 40px;
  min-width: 500px;
  max-width: 700px;
  transition: background 0.2s;
  position: relative;
  border-radius: $radius-s $radius-s 0 0;
  scroll-margin-top: 100px;

  &.fullWidth{
    max-width: unset;
    min-width: unset;
  }

  @include smDesktopWidth {
    max-width: unset;
    min-width: unset;
  }

  @include printView{
    display: none;
  }

  &.active {
    @include smDesktopWidth {
      position: fixed;
      left: 0;
      top: 68px;
      z-index: 99 !important;
    }
  }
}

.input {
  width: 100% !important;
}

.header {
  width: 100%;
  padding: 0;
  position: relative;
  z-index: 10;

  &.active {
    @include smDesktopWidth {
      padding: 24px 12px 0;
      display: grid;
      grid-template-columns: 1fr 48px;
      gap: 6px;
    }
  }
}

.mobileButton {
  z-index: 99;
  background: $gray-color;
  border-radius: $radius-s;
  width: 100%;
  height: 100%;
  position: relative;

  &.visible {
    background: $blue-color;
  }

  &.active {
    &:after {
      content: '';
      width: 6px;
      height: 6px;
      position: absolute;
      right: 8px;
      top: 6px;
      background: $blue-color;
      border-radius: 50%;
    }
  }
}

.content {
  width: calc(100% + 24px);
  margin-left: -12px;
  padding: 0;
  height: 0;
  opacity: 0;
  display: none;
  transition: opacity 0.2s;
  z-index: 2;

  &.active {
    border-radius: $radius-s;
    @include element-flex(column, flex-start, stretch);
    padding: 64px 12px 12px;
    z-index: 9;
    top: -12px;
    background: #fff;
    height: auto;
    position: absolute;
    opacity: 1 !important;
    gap: 6px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 8px 22px 0 rgba(31, 38, 135, 0.2);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.18);

    @include smDesktopWidth {
      padding: 76px 12px 12px;
      background: #fff;
      box-shadow: none;
      width: 100%;
      margin-left: 0;
      top: 0;
      border-radius: 0;
    }
  }
}

@keyframes fadeIn {
  @include keyframe-fadeIn;
}
