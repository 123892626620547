@import 'src/styles';

.container{
  @include element-flex(column, stretch, center);
  width: 700px;
  max-width: 700px;
  height: 80vh;

  @include smDesktopWidth{
    max-width: unset;
    width: 100%;
  }
}

.loader{
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 2;
}

.empty{
  margin: auto 0;
}

.wrapper{
  @include element-flex(column, flex-start, stretch);
  max-height: 70vh;
  width: 700px;
  flex: 1;
  margin: 6px 0;
  overflow: auto;
  gap: 3px;
  position: relative;

  &.loading{
    opacity: 0.7;
  }

  @include smDesktopWidth{
    width: 100%;
  }
}

.checkbox{
  display: grid;
  grid-template-columns: 1fr auto;
  place-items: center;
  gap: 12px;
  width: 100%;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  padding: 6px 12px 6px 6px;
}

.header{
  @include element-flex(row, stretch, flex-end);
  width: 100%;
  gap: 12px;
  padding: 6px 6px 12px;
  border-radius: $radius-s;
  z-index: 2;
  background: $blue-lightest-color;
}

.pagination{
  padding: 12px 20px !important;
  height: auto;
  gap: 0;
}