@import 'src/styles';

.container {
  @include element-flex(row, space-between, center);
  width: 100%;
  padding: 6px;
  border-radius: $radius-s;

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }
}

.name {
  font-size: $font-size-xl;
}
