@import 'src/styles';

.container {
  @include element-flex(column, center, center);
  gap: 12px;
  width: 100%;
  height: 80vh;

  & > h2 {
    font-size: 48px;
    text-align: center;
  }

  & > p {
    font-size: 36px;
    margin-bottom: 60px;
    text-align: center;
  }
}
