@import 'src/styles';

.container {
  @include element-flex(column);
  width: 100%;
  max-width: 500px;

  &.fullWidth {
    max-width: 100%;
  }
}

.isLoading{
  animation: loading 1s ease alternate infinite;
}

.wrapper{
  width: 100%;
  position: relative;
}

.loader{
  margin-right: 12px;
}

.typeButton{
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: $radius-s;
  transition: background .2s;

  &:hover{
    background: $gray-lightest-color;
  }

  &.active{
    background: $blue-lighter-color !important;
  }

  &.disabled{
    opacity: 0.75;
    cursor: not-allowed;
    background: transparent !important;
  }
}

.header{
  width: 100%;
  @include element-flex(row, stretch, center);
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: -6px;
  margin-top: 6px;

  &.headerMargin{
    margin-bottom: 24px;
  }
}

.title{
  font-size: 17px;
  font-weight: 700;
}

.options{
  @include element-flex(row, flex-end, center);
  gap: 12px;
  margin-left: auto;
  flex-grow: 1;
}


.chart {
  width: 100%;
  min-height: fit-content !important;
}

.chartTypeLabel {
  margin-right: $spacing-4;
}

.chartTypeOptions {
  @include element-flex(row, flex-end, center);
  gap: $spacing-1;
  width: 100%;
}

.labelButtons {
  width: calc(100% - 4rem - 7rem);
  display: grid;
  justify-content: center;
  align-items: center;
  gap: $spacing-9;
  text-align: center;
  font-weight: $font-regular;
  margin-left: 7rem;
  margin-right: 3.6rem;

  @include tabletWidth {
    width: calc(100% - 7% - 4%);
    margin-left: 7%;
    margin-right: 4%;
    gap: $spacing-6;
  }
}

.legend {
  @include element-flex(column, flex-start, flex-start);
  gap: $spacing-4;
  width: 100%;
  margin-top: $spacing-6;
}

.legendTitle {
  font-size: $font-size-l;
  font-weight: $font-medium;
}

.legendSeries {
  @include element-flex(row, flex-start, center);
  gap: $spacing-4;
}

.seriesLabel {
  @include element-flex(row, flex-start, center);
  gap: $spacing-2;
  font-weight: $font-regular;
  color: gray;
}

.seriesColor {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: $radius-s;
}

.empty{
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}


@keyframes loading {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.9;
  }
}