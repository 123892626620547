@import 'src/styles';

.container {
  @include element-flex(row, stretch, stretch);
  gap: 36px;
  width: 100%;
}

.card {
  @include element-flex(column);
  gap: 12px;
  padding: 24px;
  width: 100%;
  background: #fff;
  flex: 1;
}

.comment {
  word-break: break-word;
  font-size: $font-size-m;
  font-weight: normal;
  line-height: 22px;
}

.title {
  @include element-flex(row, flex-end, baseline);
  font-size: $font-size-l;
  font-weight: $font-bold;
  gap: 6px;
}

.info{
  @include element-flex(column);
}

.link{
  color: $blue-lightest-color;
  text-decoration: underline;
  font-size: $font-size-m;

  &:hover{
    color: darken($blue-lightest-color, 5%);
  }
}
