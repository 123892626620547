@import 'src/styles';

.container{
  display: grid;
  grid-template-columns: 1fr 60px 110px 30px;
  width: 100%;
  padding: 6px;
  border-radius: $radius-s;
  min-height: 70px;
  gap: 12px;

  &.collectPart{
    grid-template-columns: 1fr 110px 200px 35px;
  }

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }

}

.wrapper{
  gap: 6px;
  @include element-flex(row, flex-start, center);

  &.first{
    flex: 1;
  }
}

.qtySelector{
  width: auto;
  max-width: 120px;
}

.status{
  width: 100%;
  text-align: center;
}