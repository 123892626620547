@import 'src/styles';
@import 'src/styles/helpers';

.container{
  width: 100%;
  @include element-flex(column, flex-start, stretch);
  gap: 24px;
}

.filtering{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr) 130px;
  gap: 24px;
}

.table{
  padding: 0;
  background: $n1;
}

.subtitle{
  font-size: 17px;
  font-weight: 700;
}

.header{
  width: 100%;
  @include element-flex(row, space-between, flex-start);
  gap: 12px;
}

.options{
  @include element-flex(row, flex-end, center);
  gap: 12px;
}

.loaderWrapper{
  display: grid;
  place-items: center;
  width: 100%;
  height: 500px;
}
