@import '../../../styles';

.indicator {
  width: 35px;
  height: 125%;
  position: absolute;
  left: -35px;
  bottom: 80px;
  border: 6px solid $gray-color;
  border-top: none;
  border-right: none;
  border-radius: 0 0 0 10px;

  &.first {
    min-height: unset;
    height: 122px;
    top: -37px;
  }
}

.containerIndicator {
  @extend .indicator;
  height: 100%;
  border-bottom: 0;
  border-color: $font-color-light;
  border-radius: 0;
  left: 16px;
}

.mainWrapper {
  @include element-flex(column, flex-start, stretch);
  gap: 36px;
  width: 100%;
  min-width: fit-content;
  position: relative;
}

.details {
  flex: 1;
  @include element-flex(column);
  gap: 6px;
  padding: 12px 48px 12px 12px;
  border: 1px solid transparent;
  position: relative;
  border-radius: $radius-s;
  background: $blue-lightest-color;
  transition: background 0.2s;

  &.default {
    background: $blue-lighter-color;
  }

  &.companyManagement {
    z-index: 1;
  }

  &.click:hover {
    background: darken($blue-lightest-color, 3%);
    cursor: pointer;
  }
}

.textWrapper {
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 12px;

  @include desktopWidth {
    grid-template-columns: 100px 1fr;
  }
}

.label {
  width: 100%;
  text-align: right;
  line-height: 22px;
}

.text {
  font-size: $font-size-m;
  font-weight: normal;
  line-height: 22px;
  max-width: 460px;
  @include trimText;
}

.tag {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.menu {
  position: absolute;
  right: 6px !important;
  top: 6px !important;
}

.edit {
  @include element-flex(column, center, center);
  position: absolute;
  right: 6px;
  top: 6px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 6px;
  background: #ffffff;
  transition: background 0.2s;
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-shadow: $shadow-main;
}

.delete {
  @extend .edit;
  top: 48px;
}

.container {
  @include element-flex(column);
  width: 100%;
  gap: 12px;
  max-width: 550px;

  @include smDesktopWidth {
    background-color: transparent;
    padding: 24px 12px;
    max-height: 70vh;
    overflow: auto;
  }
}

.row {
  @include element-flex(row, stretch, flex-start);
  gap: 24px;
  width: 100%;

  @include smDesktopWidth {
    @include element-flex(column, flex-start, center);
  }
}

.phoneInput {
  width: 100%;
  padding: 16.5px 14px;
}

.wrapper {
  width: 100%;
  @include element-flex(row, stretch, center);
  gap: 24px;

  &.selectWrapper {
    @include element-flex(column);
    gap: 12px;
  }

  &.street {
    display: grid;
    grid-template-columns: 300px repeat(2, 100px);
  }

  &.zipCode {
    display: grid;
    grid-template-columns: 150px 1fr;
  }

  &.parentWrapper {
    @include element-flex(column);
    gap: 12px;
    background: $blue-lightest-color;
    border-radius: $radius-s;
    padding: 12px;
  }

  @include smDesktopWidth {
    display: flex !important;
    flex-direction: column !important;
    justify-content: stretch !important;
  }
}

.select {
  z-index: 2;
}

.checkbox {
  padding: 12px 0;
}

.button {
  margin: 24px auto 0;
  width: 200px;
}

.form {
  width: 100%;
  gap: 12px;
  @include element-flex(column);

  @include smDesktopWidth {
    gap: 24px;
  }
}

.userDetails {
  @include element-flex(column, center);
}

.topSection {
  @extend .wrapper;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 48px;
}
