@import 'src/styles';

.container {
  @include element-flex(column, flex-start, stretch);
  width: 100%;
  gap: 24px;

  @include smDesktopWidth {
    padding: 0 10px !important;
  }
}

.wrapper {
  width: 100%;
  padding: 24px;
  @include element-flex(column, center, center);
  gap: 24px;
  border-radius: $radius-m;
  box-shadow: $shadow-main;
  background: #fff;
}

.createdDate {
  font-size: inherit;
}

.dateWrapper {
  @include element-flex(column);
  gap: 6px;
  font-size: $font-size-s;
}

.supplierInfo{
  text-align: center;
  padding: 10px;
  color: $font-color-light;
  opacity: 0.7;
  max-width: 1250px;
  width: 100%;
  margin: 24px auto 0;
}
