@import '../../../styles';

.tableHeader {
  max-width: 700px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 2fr) 1fr 150px;
  gap: 12px;
  padding: 12px;

  @include tabletWidth {
    display: none;
  }
}

.wrapper {
  padding: 12px;
  width: 700px;

  @include smDesktopWidth {
    min-width: unset;
    width: auto;
    padding: 18px 10px;
  }

  @include smDesktopWidth {
    padding: 6px;
  }
}

.giftsSummary {
  @extend .tableHeader;

  border-radius: 0 0 $radius-s $radius-s;
  border-top: 1px solid rgba(137, 169, 212, 0.24);
  grid-column: 1 / 5;

  @include tabletWidth {
    @include element-flex(row);
    padding: 18px 10px;
  }
}

.sum {
  font-size: $font-size-l;
  font-weight: 700;
  grid-column: 4;
}

.container {
  @extend .tableHeader;

  @include tabletWidth {
    @include element-flex(column);
    padding: 18px 10px;
  }

  &:nth-of-type(odd) {
    background: $odd-row-background;
  }
}

.thumbnailWrapper {
  @include element-flex(row, flex-start, center);
  width: 100%;
  gap: 6px;
}

.amount {
  width: 100%;
}

.text {
  font-size: $font-size-s;
  @include limit-lines(3);
  word-break: break-all;
  max-width: fit-content;
}
