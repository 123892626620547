@import 'src/styles';

.container{
  width: 100%;
  @include element-flex(row, stretch, center);

  &.floating{
    width: 95dvw;
    max-width: 1174px;
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    animation: fadeIn .2s ease-out;
    padding-right: 50px;
  }
}

.childrenWrapper{
  @include element-flex(row, flex-end, center);
  gap: 24px;
}

.title{
  font-size: $font-size-xl;
}

.texts{
  @include element-flex(column);
  flex: 1;
  gap: 3px;

  p {
    font-size: 15px;
  }
}

.exit{
  color: $font-color-light;
  margin-top: 6px;
}

@keyframes fadeIn {
  @include keyframe-fadeIn;
}