@import 'src/styles';

.container{
  @include element-flex(column, flex-start, stretch);
  gap: 12px;
  width: 550px;

  @include smDesktopWidth{
    width: 100%;
  }
}

.sum{
  width: 100%;
}

.row{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @include smDesktopWidth{
    @include element-flex(column);
  }
}

.tripleRow{
  @extend .row;
  grid-template-columns: repeat(3, 1fr);
}

.button{
  margin-top: 24px;
}

.fileCard{
  width: 100%;
  min-height: 75px;
  display: grid;
  justify-items: stretch;
  align-items: center;
  background: $gray-lightest-color;
  border-radius: $radius-m;
  padding: 12px;
}

.a{
  font-size: 12px
}